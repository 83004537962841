import React from 'react';
import FormDialog from 'src/components/dialogs/FormDialog';
import expectedErrors from 'src/graphql/financing/expectedErrors';
import { CREATE_FINANCING } from 'src/graphql/financing/mutations';
import useFormatDateTime from 'src/hooks/useFormatDateTime';
import useLocales from 'src/hooks/useLocales';
import { useSafeMutation } from 'src/services/apollo-client/wrappers';
import GTMService from 'src/services/gtm';
import { GTM_EVENTS } from 'src/services/gtm/events';

import getContractFormConfiguration from './steps/ContractForm/getContractFormConfiguration';
import getLoanFormConfiguration from './steps/LoanForm/getLoanFormConfiguration';
import getModuleFormConfiguration from './steps/ModuleForm/getModuleFormConfiguration';
import getRefundFormConfiguration from './steps/RefundForm/getRefundFormConfiguration';
import getValidateFormConfiguration from './steps/ValidateForm/getValidateFormConfiguration';

const getFinancingStepsConfigurations = ({ hubspotData, translate }) => [
  getModuleFormConfiguration({ translate }),
  getLoanFormConfiguration({ hubspotData, translate }),
  getRefundFormConfiguration({ hubspotData, translate }),
  getContractFormConfiguration({ translate }),
  getValidateFormConfiguration({ translate }),
];

const FinancingFormDialog = ({ companyId, hubspotData, onClose }) => {
  const { translate } = useLocales();
  const { fDateToUtc } = useFormatDateTime();
  const [createFinancing, { error }] = useSafeMutation(CREATE_FINANCING, {
    onCompleted: ({ createFinancing: financing }) => {
      // push GTM event only if it the first time
      if (financing.financingNumber === 1) {
        GTMService.emit(GTM_EVENTS.DEAL_CLOSED_WON, {
          commissionAmount: financing.totalAmountInterests,
          financingAmount: financing.totalAmountPaidOut,
        });
      }
    },
    refetchQueries: [
      'listFinancing',
      'getDealsByCompanyId',
      'getCompany',
      'listDirectDebits',
      'getFinancingStatistics',
      'getLastFinancingByCompanyId',
      'listFinancingProgression',
    ],
  });

  const FINANCING_STEPS_CONFIGURATIONS = getFinancingStepsConfigurations({
    hubspotData,
    translate,
  });

  const onSubmit = async (data) => {
    const { contractSignedAt, firstDirectDebitDate, paidOutAt, teg, turnover, ...rest } = data;
    await createFinancing({
      variables: {
        input: {
          companyId,
          hubspotDealId: hubspotData.hubspotDealId,
          ...rest,
          contractSignedAt,
          firstDirectDebitDate: fDateToUtc(firstDirectDebitDate),
          metadata: { turnover },
          paidOutAt: fDateToUtc(paidOutAt),
          teg: teg || null,
        },
      },
    });
  };

  return (
    <FormDialog
      edit={false}
      error={error}
      expectedErrors={expectedErrors.FINANCING_IDENTIFIERS_ALREADY_EXISTS}
      onClose={onClose}
      onSubmit={onSubmit}
      open
      stepsConfigurations={FINANCING_STEPS_CONFIGURATIONS}
    />
  );
};

export default FinancingFormDialog;
