import { gql } from '@apollo/client';

const marketplaceOverPaymentClaimFragment = gql`
  fragment MarketplaceOverPaymentClaim on MarketplaceOverPaymentClaim {
    id
    marketplacePaymentId
    financingId
    externalPaymentId
    name
    status
    amount
    createdAt
    updatedAt
  }
`;

export default marketplaceOverPaymentClaimFragment;
