import { gql } from '@apollo/client';

const GET_HUBSPOT_TOKEN = gql`
  query getHubspotToken {
    getHubspotToken
  }
`;

// eslint-disable-next-line import/prefer-default-export
export { GET_HUBSPOT_TOKEN };
