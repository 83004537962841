import { useSnackbar } from 'notistack';
import React from 'react';
import ReactDOM from 'react-dom';
import NotistackProvider from 'src/components/NotistackProvider';
import RtlLayout from 'src/components/RtlLayout';
import ThemeColorPresets from 'src/components/ThemeColorPresets';
import ThemeLocalization from 'src/components/ThemeLocalization';
import { SettingsProvider } from 'src/contexts/SettingsContext';
import ThemeProvider from 'src/theme';

export default {
  enqueue(message, options) {
    const Display = () => {
      const { enqueueSnackbar } = useSnackbar();
      enqueueSnackbar(message, options);
      return null;
    };

    const mountPoint = document.getElementById('snackbar-helper');

    ReactDOM.render(
      <SettingsProvider>
        <ThemeProvider>
          <ThemeColorPresets>
            <ThemeLocalization>
              <RtlLayout>
                <NotistackProvider>
                  <Display />
                </NotistackProvider>
              </RtlLayout>
            </ThemeLocalization>
          </ThemeColorPresets>
        </ThemeProvider>
      </SettingsProvider>,
      mountPoint
    );
  },
};
