// routes
import { PATH_DASHBOARD } from './routes/paths';

const PATH_AFTER_LOGIN = PATH_DASHBOARD.routing;

const HEADER = {
  DASHBOARD_DESKTOP_HEIGHT: 60,
  DASHBOARD_DESKTOP_OFFSET_HEIGHT: 92 - 32,
  MAIN_DESKTOP_HEIGHT: 88,
  MOBILE_HEIGHT: 64,
};

const NAVBAR = {
  BASE_WIDTH: 260,
  DASHBOARD_COLLAPSE_WIDTH: 88,

  DASHBOARD_ITEM_HORIZONTAL_HEIGHT: 32,

  //
  DASHBOARD_ITEM_ROOT_HEIGHT: 48,
  DASHBOARD_ITEM_SUB_HEIGHT: 40,
  DASHBOARD_WIDTH: 280,
};

const ICON = {
  NAVBAR_ITEM: 22,
  NAVBAR_ITEM_HORIZONTAL: 20,
};

const defaultSettings = {
  themeColorPresets: 'default',
  themeDirection: 'ltr',
  themeLayout: 'horizontal',
  themeMode: 'light',
  themeStretch: false,
};

const ROWS_PER_PAGE = 20;

export { PATH_AFTER_LOGIN, HEADER, NAVBAR, ICON, ROWS_PER_PAGE, defaultSettings };
