import React from 'react';
import { Navigate } from 'react-router';
import Page from 'src/components/Page';
import useAuth from 'src/hooks/useAuth';
import useLocales from 'src/hooks/useLocales';
import { PATH_AUTH } from 'src/routes/paths';
import CreateOrVerifyAccount from 'src/sections/auth-v2/register-form/CreateOrVerifyAccount';

const VerifyAccountPage = () => {
  const { translate } = useLocales();
  const { user } = useAuth();

  if (user?.emailVerified) return <Navigate replace to={PATH_AUTH.onboarding} />;

  return (
    <Page title={translate('pages.verifyEmailPendingTitle')}>
      <CreateOrVerifyAccount />
    </Page>
  );
};

export default VerifyAccountPage;
