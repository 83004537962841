import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const useQueryParams = () => {
  const navigate = useNavigate();
  const query = new URLSearchParams(useLocation().search);
  const queryParamsObject = Object.fromEntries(query.entries());

  const changeParameterValue = useCallback(
    (parameter, value, ...args) => {
      if (value !== null) {
        queryParamsObject[parameter] = value;
      } else {
        delete queryParamsObject?.[parameter];
      }

      navigate(`?${new URLSearchParams(queryParamsObject).toString()}`, ...args);
    },
    [navigate, queryParamsObject]
  );

  return { ...queryParamsObject, changeParameterValue };
};

export default useQueryParams;
