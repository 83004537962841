import { List, ListSubheader } from '@mui/material';
import React from 'react';
import useLocales from 'src/hooks/useLocales';

import GenericNotificationItem from './GenericNotificationItem';

const NotificationsListWithdrawalTodo = ({ onClose, withdrawals }) => {
  const { translate } = useLocales();
  if (!withdrawals.length) return null;

  return (
    <List
      disablePadding
      subheader={
        <ListSubheader disableSticky sx={{ px: 2.5, py: 1, typography: 'overline' }}>
          {translate('layouts.dashboardHeaderNotificationsNotificationsWithdrawalsTodoTitle')}
        </ListSubheader>
      }
    >
      <GenericNotificationItem onClose={onClose} withdrawalsCounter={withdrawals.length} />
    </List>
  );
};

export default NotificationsListWithdrawalTodo;
