import { Badge, IconButton, Link, Stack, Tooltip, Typography, useTheme } from '@mui/material';
import React, { useState } from 'react';
import { useParams } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import Iconify from 'src/components/Iconify';
import KYB_STATUSES from 'src/constants/kybStatuses';
import PRODUCT_TYPES from 'src/constants/productTypes';
import useFormatDateTime from 'src/hooks/useFormatDateTime';
import useLocales from 'src/hooks/useLocales';
import { PATH_DASHBOARD } from 'src/routes/paths';
import CompanyDetailsDrawer from 'src/sections/@dashboard/administration/companies/drawer/CompanyDetailsDrawer';
import stopPropagation from 'src/utils/stopPropagation';

import createAvatar from '../utils/createAvatar';
import Avatar from './Avatar';

const computeOnBoardingIconProps = ({ isOnBoardingDone, theme }) => {
  switch (isOnBoardingDone) {
    case true:
      return {
        color: theme.palette.success.main,
        icon: 'mdi:check-circle-outline',
      };
    case false:
    default:
      return {
        color: theme.palette.warning.main,
        icon: 'mdi:clock-outline',
      };
  }
};

const CompanyAvatar = ({
  company,
  contractNumber,
  isDrawerContext = false,
  sx,
  variant,
  withAvatar = true,
  withBusinessArea,
  withBusinessName,
  withIndicators,
  withKYBStatusIndicator,
  withLink,
  withMainProduct,
  withOnBoardingIndicator,
  withUserFullName,
  ...other
}) => {
  const { translate } = useLocales();
  const {
    businessArea,
    businessName,
    companyId: companyIdToCompare,
    faviconUrl,
    hasDefaultedAt,
    id,
    isInDefault,
    isOnBoardingDone,
    isUnderSurveillance,
    kybStatus,
    mainProduct,
    startSurveillanceAt,
  } = company;
  const { color, name } = createAvatar(businessName);
  const theme = useTheme();
  const { fDate } = useFormatDateTime();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const companyId = companyIdToCompare || id;
  const hasCompanyLink = businessName && withLink;
  const { id: companyIdUrl } = useParams();

  const hasKybStatus = Boolean(kybStatus);
  const kybInReview = kybStatus === KYB_STATUSES.REVIEW;
  const kybStatusIndicatorVisible = withKYBStatusIndicator && hasKybStatus && kybInReview;

  const isCurrentCompanyPage = companyIdUrl === companyId;

  const handleToggleDrawer = () => {
    setIsDrawerOpen((previousIsDrawerOpen) => !previousIsDrawerOpen);
  };
  const handleClose = () => {
    setIsDrawerOpen(false);
  };
  const defaultSize = 28;
  const scaleRatio = sx?.width / defaultSize;
  return (
    <Stack alignItems="center" direction="row" spacing={2}>
      {hasCompanyLink && (
        <CompanyDetailsDrawer
          companyId={companyId}
          onClose={stopPropagation(handleClose)}
          open={isDrawerOpen}
        />
      )}
      {withAvatar && (
        <Tooltip title={kybStatusIndicatorVisible ? 'Le KYB est prêt pour la review' : ''}>
          <Badge color="error" invisible={!kybStatusIndicatorVisible} variant="dot">
            <Avatar
              alt={businessName}
              color={faviconUrl ? 'default' : color}
              onClick={isDrawerContext ? undefined : stopPropagation(handleToggleDrawer)}
              src={faviconUrl}
              sx={{
                ...(hasCompanyLink && {
                  '&:hover': {
                    border: '2px solid',
                    borderColor: !isDrawerContext ? 'primary.main' : 'transparent',
                    cursor: !isDrawerContext ? 'pointer' : 'default',
                  },
                }),
                height: 28,
                width: 28,
                ...sx,
              }}
              {...other}
            >
              <Typography sx={{ transform: `scale(${scaleRatio})` }} variant="inherit">
                {name}
              </Typography>
            </Avatar>
          </Badge>
        </Tooltip>
      )}
      <Stack>
        {withBusinessName && (
          <Typography variant={variant}>
            {hasCompanyLink && !isCurrentCompanyPage ? (
              // eslint-disable-next-line jsx-a11y/anchor-is-valid
              <Link
                component={RouterLink}
                sx={{ fontWeight: 'bold' }}
                to={PATH_DASHBOARD.companies.details(company.id)}
              >
                {businessName}
              </Link>
            ) : (
              businessName
            )}
          </Typography>
        )}
        <Stack alignItems="center" direction="row" spacing={1}>
          {withMainProduct && (
            <Typography color="text.secondary" variant="body2">
              {translate(`commons.${PRODUCT_TYPES[mainProduct]}`)}
            </Typography>
          )}
          {withMainProduct && withBusinessArea && (
            <Typography color="text.secondary" variant="body2">
              -
            </Typography>
          )}
          {withBusinessArea && (
            <Typography color="text.secondary" variant="body2">
              {businessArea.name}
            </Typography>
          )}
        </Stack>
        {withUserFullName && (
          <Typography color="text.secondary" variant="body2">
            {company.users[0].displayName}
          </Typography>
        )}
        {contractNumber && (
          <Typography color="text.secondary" variant="body2">
            {contractNumber}
          </Typography>
        )}
      </Stack>
      <Stack alignItems="center" direction="row">
        {withOnBoardingIndicator && (
          <div>
            <Tooltip
              placement="top"
              title={
                isOnBoardingDone
                  ? translate('components.companyAvatarIsOnboardingDoneTooltipTitle')
                  : translate('components.companyAvatarIsOnboardingNotDoneTooltipTitle')
              }
            >
              <IconButton>
                <Iconify {...computeOnBoardingIconProps({ isOnBoardingDone, theme })} />
              </IconButton>
            </Tooltip>
          </div>
        )}
        {withIndicators && (
          <Stack direction="row" spacing={1}>
            {isInDefault && (
              <div>
                <Tooltip
                  placement="top"
                  title={translate('components.companyAvatarDefaultTooltipTitle', {
                    date: fDate(hasDefaultedAt),
                  })}
                >
                  <IconButton
                    size="small"
                    sx={{
                      '&:hover': {
                        backgroundColor: 'transparent',
                      },
                    }}
                  >
                    <Iconify color="error.main" icon="ri:spam-2-fill" width={24} />
                  </IconButton>
                </Tooltip>
              </div>
            )}
            {isUnderSurveillance && (
              <div>
                <Tooltip
                  placement="top"
                  title={translate('components.companyAvatarMonitoringTooltipTitle', {
                    date: fDate(startSurveillanceAt),
                  })}
                >
                  <IconButton
                    size="small"
                    sx={{
                      '&:hover': {
                        backgroundColor: 'transparent',
                      },
                    }}
                  >
                    <Iconify color="success.main" icon="mdi:bell-ring" width={24} />
                  </IconButton>
                </Tooltip>
              </div>
            )}
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};

CompanyAvatar.defaultProps = {
  variant: 'body2',
  withBusinessName: false,
};

export default CompanyAvatar;
