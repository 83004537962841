import FILES_TYPE from 'src/constants/filesType';
import KYB_STATUSES from 'src/constants/kybStatuses';

const isKYBDone = (company, strict = true) => {
  const files = company?.files;
  const kybStatus = company?.kybStatus;

  if (!files) return false;

  const KYBFileTypes = [
    FILES_TYPE.K_BIS,
    FILES_TYPE.IDENTITY_DOCUMENT,
    FILES_TYPE.BANK_DETAILS,
    FILES_TYPE.INSURANCE_CERTIFICATE,
    FILES_TYPE.DEBT_DECLARATION,
  ];

  const isKYBCompleted = KYBFileTypes.every((fileType) =>
    Boolean(files.find(({ fileType: type }) => fileType === type))
  );

  const isKYBValidated = [KYB_STATUSES.VALIDATED].includes(kybStatus);

  if (strict) return isKYBCompleted && isKYBValidated;

  return isKYBCompleted;
};

export default isKYBDone;
