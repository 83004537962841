import { Button, Link, Stack, Typography, alpha } from '@mui/material';
import React from 'react';
import Iconify from 'src/components/Iconify';
import { CREATE_USER_TOKEN } from 'src/graphql/users/mutations';
import { useSafeMutation } from 'src/services/apollo-client/wrappers';
import formatPhoneNumber from 'src/utils/formatPhoneNumber';
import generateMailTo from 'src/utils/generateMailTo';

const computeVerificationIconProps = ({ emailVerified, theme }) => ({
  color: emailVerified ? theme.palette.success.main : theme.palette.error.main,
  icon: emailVerified ? 'eva:checkmark-circle-2-fill' : 'eva:close-circle-fill',
});

const UserPosition = ({ position, translate }) => (
  <Stack direction="row" justifyContent="space-between" spacing={1}>
    <Typography color="text.secondary" variant="body2">
      {translate('sections.companyInformationMainContactUserPosition')}
    </Typography>
    <Typography variant="body2">{position}</Typography>
  </Stack>
);

const UserDisplayName = ({ displayName, translate }) => (
  <Stack direction="row" justifyContent="space-between" spacing={1}>
    <Typography color="text.secondary" variant="body2">
      {translate('sections.companyInformationMainContact')}
    </Typography>
    <Typography sx={{ fontWeight: 'bold' }} variant="body2">
      {displayName}
    </Typography>
  </Stack>
);

const PhoneNumberLink = ({ phoneNumber, translate }) => (
  <Stack direction="row" justifyContent="space-between" spacing={1}>
    <Typography color="text.secondary" variant="body2">
      {translate('sections.companyInformationMainContactPhoneNumber')}
    </Typography>
    <Stack direction="row">
      <Link color="inherit" href={`tel:${phoneNumber}`} variant="body2">
        {formatPhoneNumber(phoneNumber)}
      </Link>
    </Stack>
  </Stack>
);

const EmailLinkWithVerification = ({ color, email, icon, translate }) => (
  <Stack direction="row" justifyContent="space-between" spacing={1}>
    <Typography color="text.secondary" variant="body2">
      {translate('sections.companyInformationMainContactEmail')}
    </Typography>
    <Stack direction="row" spacing={1}>
      <Link color="inherit" href={generateMailTo({ email })} variant="body2">
        {email}
      </Link>
      <Iconify color={color} icon={icon} />
    </Stack>
  </Stack>
);

const CompanyUserContact = ({ theme, translate, user }) => {
  const verificationIconProps = computeVerificationIconProps({
    emailVerified: user.emailVerified,
    theme,
  });

  const [createUserToken] = useSafeMutation(CREATE_USER_TOKEN, {
    displaySnackbar: false,
    onCompleted: ({ createUserToken: res }) => {
      if (res.token) {
        window.localStorage.setItem('logged-as-token', res.token);
        window.location.reload();
      }
    },
    variables: { userId: user.id },
  });

  return (
    <Stack spacing={2}>
      <Stack justifyContent="space-between">
        <Typography sx={{ fontWeight: 'bold' }} variant="body1">
          {translate('sections.dashboardAdministrationCompaniesV2DetailsCompanyUserContactTitle')}
        </Typography>
        <Typography
          color="text.secondary"
          sx={{ fontStyle: 'italic', fontWeight: 'bold' }}
          variant="body2"
        >
          {translate(
            'sections.dashboardAdministrationCompaniesV2DetailsCompanyUserContactSubTitle'
          )}
        </Typography>
      </Stack>
      <Stack spacing={2}>
        <UserDisplayName displayName={user.displayName} translate={translate} />
        <UserPosition position={user.position} translate={translate} />
        <EmailLinkWithVerification
          email={user.email}
          {...verificationIconProps}
          translate={translate}
        />
        <PhoneNumberLink phoneNumber={user.phone} translate={translate} />
      </Stack>
      <Button
        color="inherit"
        fullWidth
        onClick={createUserToken}
        startIcon={<Iconify icon="eva:people-fill" />}
        sx={{ backgroundColor: alpha(theme.palette.text.disabled, 0.1) }}
        variant="inherit"
      >
        {translate(
          'sections.dashboardAdministrationCompaniesV2DetailsCompanyActionsConnectLikeAction'
        )}
      </Button>
    </Stack>
  );
};

export default CompanyUserContact;
