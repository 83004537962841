import { Stack, Typography } from '@mui/material';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import PAY_BACK_PERIOD_UNITS from 'src/constants/payBackPeriodUnits';
import REFUND_TYPES from 'src/constants/refundTypes';
import useLocales from 'src/hooks/useLocales';
import { fPercent } from 'src/utils/formatNumber';
import roundDecimals from 'src/utils/roundDecimals';

const ValidateForm = ({ handleForceDisableSubmit, isLastStep }) => {
  const { translate } = useLocales();
  const methods = useFormContext();

  const { watch } = methods;
  const values = watch();

  const {
    durationMonths,
    durationWeeks,
    firstDirectDebitDate,
    paidOutAt,
    payBackPeriodUnit,
    periodAmountPayback,
    refundType,
    totalAmountDue,
    totalAmountPaidOut,
  } = values;

  const calculatedTotalAmountDueForFixedFinancing = () => {
    const contractDuration =
      payBackPeriodUnit === PAY_BACK_PERIOD_UNITS.MONTHLY.code ? durationMonths : durationWeeks;

    const getCalculatedTotalAmountDueDependingOnPayBackPeriodUnit = () => {
      if (payBackPeriodUnit === PAY_BACK_PERIOD_UNITS.MONTHLY.code) {
        return periodAmountPayback * contractDuration;
      }
      const deferredWeeks = moment(firstDirectDebitDate).diff(paidOutAt, 'weeks');
      const contractDurationWithoutDeferredWeeks = contractDuration - (deferredWeeks - 1);

      return periodAmountPayback * contractDurationWithoutDeferredWeeks;
    };

    const calculatedTotalAmountDue = getCalculatedTotalAmountDueDependingOnPayBackPeriodUnit();

    return calculatedTotalAmountDue;
  };

  const calculatedTotalAmountDue =
    refundType === REFUND_TYPES.FIXED.code
      ? calculatedTotalAmountDueForFixedFinancing()
      : totalAmountDue;

  const commissionRate = roundDecimals(
    ((calculatedTotalAmountDue - totalAmountPaidOut) / totalAmountPaidOut) * 100,
    1
  );

  const isCommissionNegative = commissionRate < 0;

  useEffect(() => {
    if (isLastStep) handleForceDisableSubmit(isCommissionNegative);
    else handleForceDisableSubmit(false);
  }, [handleForceDisableSubmit, isCommissionNegative, isLastStep]);

  return (
    <Stack spacing={1}>
      <Typography align="center" variant="body">
        {translate('sections.dashboardAdministrationFinancingCreateValidateFormTitle')}
      </Typography>
      <Typography
        align="center"
        color={isCommissionNegative ? 'error' : 'primary'}
        variant="subtitle1"
      >
        {fPercent(commissionRate)}
      </Typography>
    </Stack>
  );
};

export default ValidateForm;
