import { Skeleton, Stack, Typography } from '@mui/material';
import React from 'react';
import Image from 'src/components/Image';
import { GET_TOTAL_CUSTOMERS } from 'src/graphql/companies/queries';
import useLocales from 'src/hooks/useLocales';
import { useSafeQuery } from 'src/services/apollo-client/wrappers';

import ScreenContainer from '../ScreenContainer';

const Customers = () => {
  const { data, loading } = useSafeQuery(GET_TOTAL_CUSTOMERS, {
    fetchPolicy: 'cache-and-network',
  });

  const { translate } = useLocales();

  return (
    <ScreenContainer image="/static/onboarding/side-panels/background_1.png">
      <Stack spacing={1}>
        <Image
          src="/icons/glass/book.png"
          sx={{
            width: 100,
          }}
        />
        {!loading ? (
          <>
            <Typography color="primary.main" variant="h1">
              {data.getTotalCustomers}
            </Typography>
            <Typography color="grey.600" variant="h4">
              {translate('sections.onboardingSidePanelTotalCompanies')}
            </Typography>
          </>
        ) : (
          <Skeleton height={50} variant="text" width={100} />
        )}
      </Stack>
    </ScreenContainer>
  );
};
export default Customers;
