import styled from '@emotion/styled';
import { Box, Container, Stack } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import Stepper from 'src/components/Stepper';
import useResponsive from 'src/hooks/useResponsive';
import SideInformationLayout from 'src/layouts/side-information';
import LoggedAsAppBar from 'src/sections/shared/logged-as-app-bar/LoggedAsAppBar';
import HubspotChat from 'src/services/hubspot/HubspotChat';

import Logo from '../Logo';
import RecaptchaStyles from '../recaptcha/RecaptchaStyles';
import Scrollbar from '../Scrollbar';

const StyledHeader = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4),
  position: 'absolute',
  top: 0,
  zIndex: 9,
}));

const StyledLeftPanel = styled(Stack)(() => ({
  flex: 1,
}));

const StyledContainer = styled(Container)(({ theme }) => ({
  paddingBottom: theme.spacing(4),
  paddingTop: theme.spacing(20),
  position: 'relative',
}));

const OnboardingPage = ({
  activeStep,
  children,
  containerWidth,
  meta,
  sideLayout,
  steps,
  title,
}) => {
  const isMobile = useResponsive('down', 'md');

  return (
    <>
      <Helmet>
        <title>{`${title} | Unlimitd`}</title>
        {meta}
      </Helmet>
      <RecaptchaStyles visible={false} />

      <LoggedAsAppBar />

      <Stack direction="row" sx={{ position: 'relative' }}>
        {!isMobile && (
          <StyledHeader>
            <Logo />
          </StyledHeader>
        )}

        <Scrollbar>
          <StyledLeftPanel>
            {steps && (
              <Stack
                sx={{
                  alignItems: 'center',
                  justifyContent: 'center',
                  position: 'absolute',
                  pt: 4,
                  top: 0,
                  width: '100%',
                }}
              >
                <Container maxWidth="sm">
                  <Stepper activeStep={activeStep} steps={steps} />
                </Container>
              </Stack>
            )}
            <StyledContainer maxWidth={containerWidth}>{children}</StyledContainer>
          </StyledLeftPanel>
        </Scrollbar>
        <HubspotChat />
        {!isMobile && sideLayout && <SideInformationLayout screen={sideLayout} />}
      </Stack>
    </>
  );
};
OnboardingPage.defaultProps = {
  containerWidth: 'sm',
  title: '',
};

OnboardingPage.propTypes = {
  children: PropTypes.node.isRequired,
  containerWidth: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', false]),
  meta: PropTypes.node,
  title: PropTypes.string,
};

export default OnboardingPage;
