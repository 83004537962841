const formatOptions = (options, labelKey, valueKey) =>
  Object.keys(options).map((o) => ({
    label: options[o][labelKey],
    value: options[o][valueKey],
  }));
const excludeSelectedOptions = (options, optionsToExclude) => {
  const filteredPeriods = {};
  Object.entries(options).forEach(([key, value]) => {
    if (!optionsToExclude.includes(key)) {
      filteredPeriods[key] = value;
    }
  });
  return filteredPeriods;
};
export { formatOptions, excludeSelectedOptions };
