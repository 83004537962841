const Paper = (theme) => ({
  MuiPaper: {
    defaultProps: {
      elevation: 0,
    },

    styleOverrides: {
      root: {
        backgroundImage: 'none',
      },
    },

    variants: [
      {
        props: { variant: 'outlined' },
        style: { borderColor: theme.palette.grey[400] },
      },
    ],
  },
});

export default Paper;
