import { Box, Link, Tooltip, Typography } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import MyAvatar from 'src/components/MyAvatar';
import WithRoles from 'src/components/WithRoles';
import USER_ROLES from 'src/constants/userRoles';
import useAuth from 'src/hooks/useAuth';
import useLocales from 'src/hooks/useLocales';
import { PATH_DASHBOARD } from 'src/routes/paths';

const RootStyle = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  display: 'flex',
  padding: theme.spacing(2, 2.5),
  transition: theme.transitions.create('opacity', {
    duration: theme.transitions.duration.shorter,
  }),
}));

const NavbarAccount = ({ isCollapse }) => {
  const { user } = useAuth();
  const { translate } = useLocales();

  return (
    <RootStyle
      sx={{
        ...(isCollapse && {
          bgcolor: 'transparent',
        }),
      }}
    >
      <WithRoles excludeRoleHierarchy roles={USER_ROLES.COMPANY}>
        <Tooltip
          placement="top"
          title={translate('layouts.dashboardNavbarNavbarAccountGoToProfileAction')}
        >
          <Link component={RouterLink} to={PATH_DASHBOARD.users.profile()} underline="none">
            <MyAvatar />
          </Link>
        </Tooltip>
      </WithRoles>

      <WithRoles roles={USER_ROLES.ADMIN}>
        <MyAvatar />
      </WithRoles>

      <Box
        sx={{
          ml: 2,
          transition: (theme) =>
            theme.transitions.create('width', {
              duration: theme.transitions.duration.shorter,
            }),
          ...(isCollapse && {
            ml: 0,
            width: 0,
          }),
          overflow: 'hidden',
        }}
      >
        <Typography noWrap variant="subtitle2">
          {user?.displayName}
        </Typography>
        <Typography color="text.secondary" noWrap variant="body2">
          {user?.email}
        </Typography>
      </Box>
    </RootStyle>
  );
};

export default NavbarAccount;
