import { gql } from '@apollo/client';

const bankConnectionFragment = gql`
  fragment BankConnection on BankConnection {
    id
    bankName
    bankLogo
    bankId
    status
    statusCodeInfo
    error {
      code
      message
      action
    }
    urlToManage
  }
`;

export default bankConnectionFragment;
