import { gql } from '@apollo/client';

const directDebitFragment = gql`
  fragment DirectDebit on DirectDebit {
    id
    reference
    status
    amount
    metadata {
      turnover
      hasLog
      currentInterestDue
      taeg
    }
    paymentIntentId
    isAuto
    shouldProcessedAt
    commissionPaid
    interestPaid
    capitalPaid
    processedAt
    executedAt
    rejectedAt
    createdAt
    updatedAt
  }
`;

export default directDebitFragment;
