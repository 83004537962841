import i18n from 'src/locales/i18n';

const expectedErrors = {
  IS_SELF_EMPLOYED: {
    code: 'IS_SELF_EMPLOYED',
    message: i18n.t('commons.isSelfEmployed'),
  },
  SIREN_ALREADY_EXISTS: {
    code: 'SIREN_ALREADY_EXISTS',
    message: i18n.t('commons.sirenAlreadyExists'),
  },
};

export default expectedErrors;
