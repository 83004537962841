import { sign, verify } from 'jsonwebtoken';
import jwtDecode from 'jwt-decode';

import axios from './axios';

const TOKEN_TYPES = {
  CHALLENGE: 'CHALLENGE',
  MANDATE_CREATION: 'MANDATE_CREATION',
  OVERPAYMENT_CLAIM: 'OVERPAYMENT_CLAIM',
  RESET_PASSWORD: 'RESET_PASSWORD',
  USER: 'USER',
  VERIFICATION_EMAIL: 'VERIFICATION_EMAIL',
};

const isValidToken = (accessToken) => {
  if (!accessToken) {
    return false;
  }

  const decoded = jwtDecode(accessToken);
  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

const getTokenType = (token) => {
  if (!token) return null;
  const decodedToken = jwtDecode(token);
  return decodedToken?.type;
};

const getTokenFromLocalStorage = () => window.localStorage.getItem('token');

//  const handleTokenExpired = (exp) => {
//   let expiredTimer;

//   window.clearTimeout(expiredTimer);
//   const currentTime = Date.now();
//   const timeLeft = exp * 1000 - currentTime;
//   console.log(timeLeft);
//   expiredTimer = window.setTimeout(() => {
//     console.log('expired');
//     // You can do what ever you want here, like show a notification
//   }, timeLeft);
// };

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem('token', accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    // This function below will handle when token is expired
    // const { exp } = jwtDecode(accessToken);
    // handleTokenExpired(exp);
  } else {
    localStorage.removeItem('token');
    delete axios.defaults.headers.common.Authorization;
  }
};

export {
  isValidToken,
  setSession,
  verify,
  sign,
  getTokenType,
  TOKEN_TYPES,
  getTokenFromLocalStorage,
};
