import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import { useDropzone } from 'react-dropzone';

import BlockContent from './BlockContent';
import MultiFilePreview from './MultiFilePreview';
import RejectionFiles from './RejectionFiles';

const DropZoneStyle = styled('div')(({ theme }) => ({
  '&:hover': { borderColor: theme.palette.secondary.main, cursor: 'pointer', opacity: 0.72 },
  backgroundColor: theme.palette.background.paper,
  border: `1px solid ${theme.palette.grey[500_32]}`,
  borderRadius: theme.shape.borderRadius,
  outline: 'none',
  padding: theme.spacing(3, 1),
}));

const UploadMultiFile = ({
  accept,
  error = false,
  files,
  helperText,
  onRemove,
  onRemoveAll,
  showPreview = false,
  sx,
  withoutIllustration,
  ...other
}) => {
  const { fileRejections, getInputProps, getRootProps, isDragActive, isDragReject } = useDropzone({
    ...other,
    accept,
    maxSize: 15_000_000,
    multiple: true,
  });

  return (
    <Box sx={{ width: '100%', ...sx }}>
      <DropZoneStyle
        {...getRootProps()}
        sx={{
          ...(isDragActive && { opacity: 0.72 }),
          ...((isDragReject || error) && {
            bgcolor: 'error.lighter',
            borderColor: 'error.light',
            color: 'error.main',
          }),
        }}
      >
        <input {...getInputProps()} />

        <BlockContent accept={accept} withoutIllustration={withoutIllustration} />
      </DropZoneStyle>

      {fileRejections.length > 0 && <RejectionFiles fileRejections={fileRejections} />}

      <MultiFilePreview
        files={files}
        onRemove={onRemove}
        onRemoveAll={onRemoveAll}
        showPreview={showPreview}
      />

      {helperText && helperText}
    </Box>
  );
};

export default UploadMultiFile;
