import { TableCell } from '@mui/material';
import React from 'react';
import useLocales from 'src/hooks/useLocales';

const getTableHeads = ({ translate }) => [
  {
    align: 'center',
    id: 'name',
    label: translate('sections.sharedFinancingFinancingCloseWonDealsTableHeadNameLabel'),
  },
  {
    align: 'center',
    id: 'refundType',
    label: translate('sections.sharedFinancingFinancingCloseWonDealsTableHeadRefundTypeLabel'),
  },
  {
    align: 'center',
    id: 'amount',
    label: translate('sections.sharedFinancingFinancingCloseWonDealsTableHeadAmountLabel'),
  },
];

const HubspotDealsTableHeadRow = () => {
  const { translate } = useLocales();
  const TABLE_HEADS = getTableHeads({ translate });

  return TABLE_HEADS.map((headCell) => (
    <TableCell key={headCell.label} align={headCell.align} maxWidth={8}>
      {headCell.label}
    </TableCell>
  ));
};

export default HubspotDealsTableHeadRow;
