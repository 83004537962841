import React from 'react';
import { Navigate } from 'react-router-dom';
import useQueryParams from 'src/hooks/useQueryParams';
import { PATH_PAGE } from 'src/routes/paths';
import { getTokenType, isValidToken } from 'src/utils/jwt';

const TokenGuard = ({ children, requiredTokenType }) => {
  const { token } = useQueryParams();
  const tokenType = getTokenType(token);

  if (tokenType !== requiredTokenType || !isValidToken(token)) {
    return <Navigate replace to={PATH_PAGE.page404} />;
  }

  return <>{children}</>;
};

export default TokenGuard;
