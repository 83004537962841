import { Divider, Stack } from '@mui/material';
import React from 'react';

import CompanyUserContact from '../CompanyUserContact';
import HubspotDeals from '../hubspot-deals/HubspotDeals';
import MinimalistFinancingList from '../minimalist-financing-list/MinimalistFinancingList';

const CompanyDrawerOverViewTabPanel = ({ company, theme, translate }) => (
  <Stack
    sx={{
      position: 'sticky',
      top: 0,
      zIndex: 9,
    }}
  >
    <Stack p={3} spacing={2}>
      {company.users.map((user) => (
        <CompanyUserContact
          key={user.id}
          company={company}
          theme={theme}
          translate={translate}
          user={user}
        />
      ))}

      <Divider variant="middle" />
    </Stack>
    <Stack spacing={2}>
      <MinimalistFinancingList company={company} translate={translate} />
      <Divider variant="middle" />
      <HubspotDeals company={company} translate={translate} />
    </Stack>
  </Stack>
);

export default CompanyDrawerOverViewTabPanel;
