import { gql } from '@apollo/client';

const codatConnectionFragment = gql`
  fragment CodatConnection on CodatConnection {
    id
    integrationId
    integrationKey
    sourceId
    platformName
    marketplaceConnector {
      isActive
      id
    }
    linkUrl
    status
    created
    sourceType
    lastConnectionSync
    connectionSyncStatus
  }
`;

export default codatConnectionFragment;
