/* eslint-disable sort-keys-fix/sort-keys-fix */
const PRODUCT_TYPES = {
  SAAS: 'SAAS',
  RECURRING_REVENUE: 'RECURRING_REVENUE',
  ECOMMERCE: 'ECOMMERCE',
  MARKETPLACE: 'MARKETPLACE',
  AGENCY: 'AGENCY',
  OTHER: 'OTHER',
};

export default PRODUCT_TYPES;
