import { Box, Paper, Typography } from '@mui/material';
import { alpha } from '@mui/material/styles';
import _ from 'lodash';
import React from 'react';
import useLocales from 'src/hooks/useLocales';

import { fData } from '../../utils/formatNumber';

const getTranslatedFileRejectionError = ({ errorCode, translate }) =>
  translate(`commons.fileError${_.camelCase(errorCode)}`);

const RejectionFiles = ({ fileRejections }) => {
  const { translate } = useLocales();
  return (
    <Paper
      sx={{
        bgcolor: (theme) => alpha(theme.palette.error.main, 0.08),
        borderColor: 'error.light',
        mt: 3,
        px: 2,
        py: 1,
      }}
      variant="outlined"
    >
      {fileRejections.map(({ errors, file }) => {
        const { path, size } = file;

        return (
          <Box key={path} sx={{ my: 1 }}>
            <Typography noWrap variant="subtitle2">
              {path} - {fData(size)}
            </Typography>

            {errors.map((error) => (
              <Typography key={error.code} component="p" variant="caption">
                {getTranslatedFileRejectionError({ errorCode: error.code, translate })}
              </Typography>
            ))}
          </Box>
        );
      })}
    </Paper>
  );
};

export default RejectionFiles;
