const FILES_TYPE = {
  BANK_DETAILS: 'BANK_DETAILS',
  CASHFLOW_STATEMENT: 'CASHFLOW_STATEMENT',
  DEBT_DECLARATION: 'DEBT_DECLARATION',
  IDENTITY_DOCUMENT: 'IDENTITY_DOCUMENT',
  INSURANCE_CERTIFICATE: 'INSURANCE_CERTIFICATE',
  K_BIS: 'K_BIS',
  OTHER: 'OTHER',
  TAX_RETURN: 'TAX_RETURN',
};

export default FILES_TYPE;
