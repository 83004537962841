import i18n from 'src/locales/i18n';

const REFUND_TYPES = {
  ALL: {
    code: 'ALL',
    label: i18n.t('commons.allRefundType'),
  },
  FIXED: {
    code: 'FIXED',
    label: i18n.t('commons.fixedRefundType'),
  },
  VARIABLE: {
    code: 'VARIABLE',
    label: i18n.t('commons.variableRefundType'),
  },
};

// eslint-disable-next-line no-unused-vars
const { ALL, ...REFUND_TYPE_WITHOUT_ALL_VALUE } = REFUND_TYPES;

export { REFUND_TYPE_WITHOUT_ALL_VALUE };
export default REFUND_TYPES;
