// from https://gist.github.com/TooTallNate/4750953
const endianness = () => {
  const b = new ArrayBuffer(4);
  const a = new Uint32Array(b);
  const c = new Uint8Array(b);
  a[0] = 0xdeadbeef;
  if (c[0] === 0xef) return 'LE';
  if (c[0] === 0xde) return 'BE';
  throw new Error('unknown endianness');
};

// inspired from https://stackoverflow.com/a/7946195
const unsignedBEUIntToHexValue = (beInt) => {
  const s = beInt.toString(16); // translate to hexadecimal notation
  const sZ = s.replace(/^(.(..)*)$/, '0$1'); // add a leading zero if needed
  if (endianness() === 'BE') return sZ; // if the host byte is Big Endian keep as is
  // the host byte is Little Endian reverse
  const a = sZ.match(/../g); // split number in groups of two
  a.reverse(); // reverse to Little Endian
  return a.join('');
};

const getCookie = (cname) => {
  const cookies = document.cookie.split('; ');
  const cookiePrefix = `${cname}=`;
  for (const cookie of cookies) {
    if (cookie.startsWith(cookiePrefix))
      return decodeURIComponent(cookie.slice(cookiePrefix.length));
  }
  return null;
};

// the hole method is inspired from the python version
// here https://stackoverflow.com/a/19037624
const decodeCookie = (cookie) => {
  try {
    const binaryCookie = Buffer.from(cookie, 'base64');

    const unsignedInt = [];
    for (let i = 0; i < 4; i += 1) {
      unsignedInt[i] = binaryCookie.readUInt32BE(i * 4);
    }

    const hexArray = unsignedInt.map((uInt) => unsignedBEUIntToHexValue(uInt));

    return hexArray.join('').toUpperCase();
  } catch (error) {
    return null;
  }
};

function getCookieValue(cname) {
  const name = `${cname}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i += 1) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.split('=')[1];
    }
  }
  return false;
}

function setCookie(cookieName, cookieValue, expirationDays) {
  const d = new Date();
  d.setTime(d.getTime() + expirationDays * 24 * 60 * 60 * 1000);
  const expires = `expires=${d.toUTCString()}`;
  document.cookie = `${cookieName}=${cookieValue};${expires};path=/`;
}

export { getCookie, decodeCookie, getCookieValue, setCookie };
