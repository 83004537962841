import { Dialog as MuiDialog, styled } from '@mui/material';
import React from 'react';

const StyledDialog = ({ children, ...props }) => {
  const Dialog = styled(MuiDialog)(({ isMobile }) => ({
    '& .MuiDialog-container': {
      alignItems: 'flex-start',
    },
    '& .MuiPaper-root': isMobile && {
      margin: 0,
    },
  }));

  return <Dialog {...props}>{children}</Dialog>;
};

export default StyledDialog;
