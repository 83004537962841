const CssBaseline = (theme) => ({
  MuiCssBaseline: {
    styleOverrides: {
      '#root': {
        height: '100%',
        width: '100%',
      },
      '*': {
        boxSizing: 'border-box',
        margin: 0,
        padding: 0,
      },
      body: {
        background: theme.palette.grey[0],
        height: '100%',
        width: '100%',
      },
      html: {
        WebkitOverflowScrolling: 'touch',
        height: '100%',
        width: '100%',
      },
      img: {
        display: 'block',
        maxWidth: '100%',
      },
      input: {
        '&[type=number]': {
          '&::-webkit-inner-spin-button': {
            WebkitAppearance: 'none',
            margin: 0,
          },
          '&::-webkit-outer-spin-button': {
            WebkitAppearance: 'none',
            margin: 0,
          },
          MozAppearance: 'textfield',
        },
      },
    },
  },
});

export default CssBaseline;
