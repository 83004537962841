import { gql } from '@apollo/client';

import fileFragment from './file';

const bankRatesChangeFragment = gql`
  fragment BankRatesChange on BankRatesChange {
    id
    name
    isActive
    emittedAt
    file {
      ...File
    }
  }
  ${fileFragment}
`;

export default bankRatesChangeFragment;
