import React from 'react';
import { Helmet } from 'react-helmet-async';

const createCss = (visible) => `
  /* cspell-checker: disable-next-line */
  .grecaptcha-badge { 
      visibility: ${visible ? 'visible' : 'hidden'};
  }
`;

const RecaptchaStyles = ({ visible }) => (
  <Helmet>
    <style>{createCss(visible)}</style>
  </Helmet>
);

export default RecaptchaStyles;
