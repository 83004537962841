import 'moment/locale/fr';

import { format, formatDistanceToNow, formatDistanceToNowStrict, getTime } from 'date-fns';
import * as locales from 'date-fns/locale';
import _ from 'lodash';
import moment from 'moment-timezone';
import MOMENT_FORMATS from 'src/constants/momentFormats';
import useLocales from 'src/hooks/useLocales';
import i18n from 'src/locales/i18n';

const useFormatDateTime = () => {
  const { currentLang } = useLocales();
  const COMMON_FORMAT_OPTIONS = {
    locale: locales[currentLang.value],
  };

  const fDate = (date) =>
    format(date ? new Date(date) : new Date(), 'dd MMMM yyyy', COMMON_FORMAT_OPTIONS);

  const fDateTime = (date) => format(new Date(date), 'dd MMM yyyy HH:mm', COMMON_FORMAT_OPTIONS);

  const fTimestamp = (date) => getTime(new Date(date));

  const fTimestampToFormattedDate = (timestamp) => fDate(new Date(timestamp * 1000));

  const fTimestampToFormattedDateTime = (timestamp) => fDateTime(new Date(timestamp * 1000));

  const fDateTimeSuffix = (date) =>
    format(new Date(date), 'dd/MM/yyyy hh:mm p', COMMON_FORMAT_OPTIONS);

  const fToNow = (date, addSuffix = true) =>
    formatDistanceToNow(new Date(date), {
      ...COMMON_FORMAT_OPTIONS,
      addSuffix,
    });

  const fToNowStrict = (date, addSuffix = true) =>
    formatDistanceToNowStrict(new Date(date), {
      ...COMMON_FORMAT_OPTIONS,
      addSuffix,
    });

  const fDateToHumanReadable = (_date, addSuffix) => {
    const date = moment(_date);
    if (date.isSame(moment(), 'day')) return i18n.t('commons.today');
    if (date.isSame(moment().subtract(1, 'days'), 'day')) return i18n.t('commons.yesterday');
    if (date.isSame(moment().add(1, 'days'), 'day')) return i18n.t('commons.tomorrow');

    let strictDate = null;
    if (date.isBefore(moment())) strictDate = moment(date).add(1, 'day');
    if (date.isAfter(moment())) strictDate = moment(date).subtract(1, 'day');

    return _.capitalize(fToNowStrict(strictDate, addSuffix));
  };

  const fRelativeDate = (date) => moment(date).locale('fr').fromNow();

  const fDateToUtc = (date) => moment(date).add(moment(date).utcOffset(), 'minutes').utc();

  const fLocaleDate = (date) => moment.tz(date, 'Europe/Paris').format(MOMENT_FORMATS.FR_DATE);
  const fLocaleDateTime = (date) =>
    moment.tz(date, 'Europe/Paris').format(MOMENT_FORMATS.FR_DATE_TIME);

  return {
    fDate,
    fDateTime,
    fDateTimeSuffix,
    fDateToHumanReadable,
    fDateToUtc,
    fLocaleDate,
    fLocaleDateTime,
    fRelativeDate,
    fTimestamp,
    fTimestampToFormattedDate,
    fTimestampToFormattedDateTime,
    fToNow,
  };
};

export default useFormatDateTime;
