import { gql } from '@apollo/client';

const withdrawalFragment = gql`
  fragment Withdrawal on Withdrawal {
    id
    rollFinancingId
    rollFinancingReference
    reference
    amount
    scheduledAt
    processedAt
    executedAt
    rejectedAt
    consultationLink
    respondForLastTimeAt
    status
    failedReason
    externalPaymentId
    source
    amortizedAmount
    amortizedRejectionFees
    amortizedCapital
    amortizedInterest
    amortizedCommission
    amortizedLateInterest
    createdAt
    updatedAt
  }
`;

export default withdrawalFragment;
