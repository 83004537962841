import { Box, Divider, IconButton, MenuItem, Typography } from '@mui/material';
import { alpha, useTheme } from '@mui/material/styles';
import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import MenuPopover from 'src/components/MenuPopover';
import MyAvatar from 'src/components/MyAvatar';
import WithRoles from 'src/components/WithRoles';
import PROFILE_TABS from 'src/constants/profileTab';
import USER_ROLES from 'src/constants/userRoles';
import useAuth from 'src/hooks/useAuth';
import useLocales from 'src/hooks/useLocales';
import usePath from 'src/hooks/usePath';
import { PATH_AUTH, PATH_DASHBOARD } from 'src/routes/paths';

const AccountPopover = () => {
  const { translate } = useLocales();
  const { company, isLoggedAsUser, logout, user } = useAuth();
  const theme = useTheme();
  const { isRequestedPath: isProfilePath } = usePath(
    PATH_DASHBOARD.users.profile(PROFILE_TABS.ACCOUNT)
  );
  const { isRequestedPath: isOnboardingPath } = usePath(PATH_AUTH.onboarding);
  const [open, setOpen] = useState(false);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <IconButton
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              bgcolor: alpha(theme.palette.grey[900], 0.8),
              borderRadius: '50%',
              content: "''",
              height: '100%',
              position: 'absolute',
              width: '100%',
              zIndex: 1,
            },
          }),
        }}
      >
        <MyAvatar />
      </IconButton>

      <MenuPopover
        anchorEl={open}
        onClose={handleClose}
        open={Boolean(open)}
        sx={{
          '& .MuiMenuItem-root': {
            borderRadius: 0.75,
            typography: 'body2',
          },
          ml: 0.75,
          mt: 1.5,
          p: 0,
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography noWrap variant="subtitle2">
            {user?.displayName}
          </Typography>
          <Typography noWrap sx={{ color: 'text.secondary' }} variant="body2">
            {user?.email}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        {!company?.isOnBoardingDone && (
          <WithRoles excludeRoleHierarchy roles={USER_ROLES.COMPANY}>
            <MenuItem
              component={RouterLink}
              onClick={handleClose}
              sx={{
                backgroundColor:
                  isOnboardingPath &&
                  alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
                color: isOnboardingPath && 'primary.main',
                m: 1,
              }}
              to={PATH_DASHBOARD.routing}
            >
              {translate('layouts.dashboardNavbarHeaderAccountPopoverOnboarding')}
            </MenuItem>
          </WithRoles>
        )}
        <WithRoles excludeRoleHierarchy roles={USER_ROLES.COMPANY}>
          <MenuItem
            component={RouterLink}
            onClick={handleClose}
            sx={{
              backgroundColor:
                isProfilePath &&
                alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
              color: isProfilePath && 'primary.main',
              m: 1,
            }}
            to={PATH_DASHBOARD.users.profile(PROFILE_TABS.ACCOUNT)}
          >
            {translate('layouts.dashboardNavbarHeaderAccountPopoverProfile')}
          </MenuItem>
        </WithRoles>
        {!isLoggedAsUser && (
          <MenuItem onClick={logout} sx={{ color: 'error.main', m: 1 }}>
            {translate('layouts.dashboardNavbarHeaderAccountPopoverLogout')}
          </MenuItem>
        )}
      </MenuPopover>
    </>
  );
};

export default AccountPopover;
