import React from 'react';
import { useRoutes } from 'react-router-dom';
import AuthGuard from 'src/guards/AuthGuard';
import DashboardLayout from 'src/layouts/dashboard';
import LogoOnlyLayout from 'src/layouts/LogoOnlyLayout';
import authRoutes from 'src/routes/auth';
import dashboardRoutes from 'src/routes/dashboard';
import mainRoutes from 'src/routes/main';
import userProfileRoutes from 'src/routes/user-profile';

const AppRoutes = [
  {
    children: authRoutes,
    path: '/',
  },
  {
    children: userProfileRoutes,
    element: (
      <AuthGuard needsOnboardingDone={false}>
        <DashboardLayout />
      </AuthGuard>
    ),
    path: '/',
  },
  {
    children: dashboardRoutes,
    path: '/',
  },
  {
    children: mainRoutes,
    element: <LogoOnlyLayout />,
    path: '*',
  },
];

const Router = () => useRoutes(AppRoutes);

export default Router;
