const Input = (theme) => ({
  MuiFilledInput: {
    styleOverrides: {
      root: {
        '&.Mui-disabled': {
          backgroundColor: theme.palette.action.disabledBackground,
        },
        '&.Mui-focused': {
          backgroundColor: theme.palette.action.focus,
        },
        '&:hover': {
          backgroundColor: theme.palette.grey[500_16],
        },
        backgroundColor: theme.palette.grey[500_12],
      },
      underline: {
        '&:before': {
          borderBottomColor: theme.palette.grey[500_56],
        },
      },
    },
  },
  MuiInput: {
    styleOverrides: {
      underline: {
        '&:before': {
          borderBottomColor: theme.palette.grey[500_56],
        },
      },
    },
  },
  MuiInputBase: {
    defaultProps: {
      size: 'small',
    },
    styleOverrides: {
      input: {
        '&::placeholder': {
          color: theme.palette.text.disabled,
          opacity: 1,
        },
        padding: theme.spacing(2, 1),
      },
      root: {
        '&.Mui-disabled': {
          '& svg': { color: theme.palette.text.disabled },
        },
        backgroundColor: theme.palette.background.default,
        marginTop: theme.spacing(2.5),
      },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: theme.palette.grey[500_32],
        },
        '&.Mui-disabled': {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.action.disabledBackground,
          },
          backgroundColor: theme.palette.grey[200],
        },
        padding: theme.spacing(0.5, 1),
      },
    },
  },
});

export default Input;
