const Menu = (theme) => ({
  MuiMenuItem: {
    styleOverrides: {
      root: {
        '&.Mui-selected': {
          '&:hover': {
            backgroundColor: theme.palette.action.hover,
          },
          backgroundColor: theme.palette.action.selected,
        },
      },
    },
  },
});

export default Menu;
