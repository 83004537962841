import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Stack,
  Toolbar,
  Typography,
  styled,
} from '@mui/material';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import FormErrors from 'src/components/form/FormErrors';
import { FormProvider } from 'src/components/hook-form/index.js';
import Iconify from 'src/components/Iconify.js';
import Stepper from 'src/components/Stepper';
import useLocales from 'src/hooks/useLocales';
import useResponsive from 'src/hooks/useResponsive';
import * as Yup from 'yup';

const StickyDialog = styled(Dialog)(({ isMobile }) => ({
  '& .MuiDialog-container': {
    alignItems: 'flex-start',
  },
  '& .MuiPaper-root': isMobile && {
    margin: 0,
  },

  '& .MuiPaper-rounded': {
    ...(isMobile && {
      borderRadius: `0 !important`,
    }),
  },
}));

const FormDialog = ({
  children,
  disabled,
  edit,
  error,
  expectedErrors,
  maxWidth,
  onClose,
  onSubmit,
  open,
  stepConfiguration,
  stepsConfigurations,
}) => {
  const initialStep = 0;
  const [activeStep, setActiveStep] = useState(initialStep);
  const [forceDisableSubmit, setForceDisableSubmit] = useState(false);
  const [sharedData, setSharedData] = useState({});
  const { translate } = useLocales();
  const isMobile = useResponsive('down', 'sm');

  const isLastStep = activeStep === stepsConfigurations?.length - 1;
  const isFirstStep = activeStep === 0;
  const canClose = (stepsConfigurations && isFirstStep) || !stepsConfigurations;
  const canSubmit = (stepsConfigurations && isLastStep) || !stepsConfigurations;
  const safeStepsConfigurations = stepsConfigurations || [stepConfiguration];
  const getDisplayProperty = (displayOnStep) => (activeStep === displayOnStep ? 'block' : 'none');

  const mergedFormsDefaultValues = safeStepsConfigurations.reduce(
    (acc, { defaultValues }) => ({ ...acc, ...defaultValues }),
    {}
  );
  const mergedSchemas = safeStepsConfigurations.reduce(
    (acc, { schema }) => ({ ...acc, ...schema }),
    {}
  );
  const FormSchema = Yup.object().shape(mergedSchemas);

  const methods = useForm({
    defaultValues: mergedFormsDefaultValues,
    mode: 'onChange',
    resolver: yupResolver(FormSchema),
  });

  const {
    formState: { errors, isSubmitting },
    handleSubmit,
    reset,
    trigger,
  } = methods;

  const handleForceDisableSubmit = (value) => {
    setForceDisableSubmit(value);
  };

  const handleReset = () => {
    handleForceDisableSubmit(false);
    reset();
  };

  const handleSharedData = (data) => {
    setSharedData((previousData) => ({ ...previousData, ...data }));
  };

  const handleClose = () => {
    onClose();
    handleReset();
    if (stepsConfigurations) {
      setActiveStep(initialStep);
    }
  };

  const handleNextStep = async () => {
    const currentStepFields = Object.keys(stepsConfigurations[activeStep].schema);
    await trigger(currentStepFields);
    const stepContainsErrors = currentStepFields.some((field) => errors[field]);
    if (!stepContainsErrors) setActiveStep((currentActiveStep) => currentActiveStep + 1);
  };

  const handlePreviousStep = () => {
    setActiveStep((previousActiveStep) => {
      handleForceDisableSubmit(false);
      return previousActiveStep - 1;
    });
  };

  const onSubmitOverride = async (data) => {
    try {
      await onSubmit(data);
      handleClose();
    } catch {
      // Nothing to do
    }
  };

  const currentStep = safeStepsConfigurations[activeStep];
  const {
    onSubmitLabel: stepOnSubmitLabel,
    subTitle: stepSubTitle,
    title: stepTitle,
  } = currentStep?.formProps || {};

  const onSubmitStep = canSubmit ? handleSubmit(onSubmitOverride) : handleNextStep;

  return (
    <StickyDialog
      fullScreen={isMobile}
      fullWidth
      isMobile={isMobile}
      maxWidth={maxWidth}
      open={open}
      scroll="paper"
    >
      <DialogTitle sx={{ p: 0 }}>
        <Stack spacing={2}>
          <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Stack>
              <Typography component="p" variant="h6">
                {!edit && !stepTitle && translate('components.dialogsFormDialogCreateTitleLabel')}
                {edit && translate('components.dialogsFormDialogEditTitleLabel')}
                {stepTitle && stepTitle}
              </Typography>
              {stepSubTitle && (
                <Typography component="p" variant="caption">
                  {stepSubTitle}
                </Typography>
              )}
            </Stack>
            <IconButton onClick={handleClose}>
              <Iconify icon="eva:close-fill" />
            </IconButton>
          </Toolbar>
          {stepsConfigurations && (
            <Box sx={{ pb: 2 }}>
              <Stepper
                activeStep={activeStep}
                steps={stepsConfigurations.map((step) => step.label)}
              />
            </Box>
          )}
          {error && (
            <Box pb={2} px={3}>
              <FormErrors error={error} expectedErrors={expectedErrors} />
            </Box>
          )}
        </Stack>
      </DialogTitle>

      <Divider />
      <DialogContent sx={{ p: 0 }}>
        <FormProvider methods={methods} onSubmit={(e) => e.preventDefault()}>
          <Box px={3} py={6}>
            {children}
            {safeStepsConfigurations.map(({ Component, label, props }, index) => (
              <Box key={label} display={getDisplayProperty(index)}>
                <Stack spacing={5}>
                  <Component
                    {...props}
                    handleForceDisableSubmit={handleForceDisableSubmit}
                    handleSharedData={handleSharedData}
                    isLastStep={isLastStep}
                    onClose={onClose}
                    onSubmit={onSubmitStep}
                    sharedData={sharedData}
                  />
                </Stack>
              </Box>
            ))}
          </Box>
        </FormProvider>
      </DialogContent>
      <Divider />

      <DialogActions>
        <Stack direction="row" justifyContent="flex-end" spacing={2}>
          <Button onClick={canClose ? handleClose : handlePreviousStep}>
            {canClose && translate('components.dialogsFormDialogCancelActionLabel')}
            {!canClose && translate('components.dialogsFormDialogPreviousActionLabel')}
          </Button>
          <LoadingButton
            disabled={forceDisableSubmit || disabled || isSubmitting}
            loading={isSubmitting}
            onClick={onSubmitStep}
            variant="contained"
          >
            {canSubmit &&
              !stepOnSubmitLabel &&
              translate('components.dialogsFormDialogSaveActionLabel')}
            {!canSubmit && translate('components.dialogsFormDialogNextActionLabel')}
            {stepOnSubmitLabel && stepOnSubmitLabel}
          </LoadingButton>
        </Stack>
      </DialogActions>
    </StickyDialog>
  );
};

FormDialog.defaultProps = {
  children: null,
  maxWidth: 'sm',
};

export default FormDialog;
