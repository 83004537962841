import i18n from 'src/locales/i18n';

import snackbar from './snackbar';

const onUpdate = (registration) => {
  const registrationWaiting = registration.waiting;
  if (registrationWaiting) {
    registrationWaiting.postMessage({ type: 'SKIP_WAITING' });
    registrationWaiting.addEventListener('statechange', (e) => {
      if (e.target.state === 'activated') {
        const currentUrl = new URL(window.location.href);
        const randomValue = new Date().getTime();
        // cspell-checker: disable-next-line
        currentUrl.searchParams.set('cachebust', randomValue);
        window.location.href = currentUrl.href;
        snackbar.enqueue(i18n.t('commons.serviceWorkerOnUpdate'), { variant: 'success' });
      }
    });
  }
};

// eslint-disable-next-line import/prefer-default-export
export { onUpdate };
