import { gql } from '@apollo/client';

const businessAreaFragment = gql`
  fragment BusinessArea on BusinessArea {
    id
    name
    color
    createdAt
    updatedAt
  }
`;

export default businessAreaFragment;
