import { toLower } from 'lodash';

const FORMATS_IMG = ['jpg', 'jpeg', 'gif', 'bmp', 'png'];
const FORMAT_TEXT = ['txt'];
const FORMATS_VIDEO = ['m4v', 'avi', 'mpg', 'mp4', 'webm'];
const FORMATS_WORD = ['doc', 'docx'];
const FORMATS_EXCEL = ['xls', 'xlsx', 'csv'];
const FORMATS_POWERPOINT = ['ppt', 'pptx'];
const FORMAT_PDF = ['pdf'];
const FORMAT_PHOTOSHOP = ['psd'];
const FORMATS_ILLUSTRATOR = ['ai', 'esp'];
const FORMAT_ZIP = ['zip', 'rar', 'iso'];
const FORMAT_AUDIO = ['wav', 'aif', 'mp3', 'aac'];
const FORMAT_EMAIL = ['eml'];
const FORMAT_JSON = ['json'];

const iconUrl = (icon) => `/icons/files/${icon}.svg`;

const getFileType = (fileUrl = '') => (fileUrl && fileUrl.split('.').pop()) || '';

const getFileName = (fileUrl) =>
  fileUrl.substring(fileUrl.lastIndexOf('/') + 1).replace(/\.[^/.]+$/, '');

const getFileFullName = (fileUrl) => fileUrl.split('/').pop();

const getFileFormat = (fileUrl) => {
  let format;
  const fileType = getFileType(fileUrl);

  switch (fileUrl.includes(fileType)) {
    case FORMAT_EMAIL.includes(fileType):
      format = 'eml';
      break;
    case FORMATS_IMG.includes(fileType):
      format = 'image';
      break;
    case FORMAT_TEXT.includes(fileType):
      format = 'txt';
      break;
    case FORMAT_ZIP.includes(fileType):
      format = 'zip';
      break;
    case FORMAT_AUDIO.includes(fileType):
      format = 'audio';
      break;
    case FORMATS_VIDEO.includes(fileType):
      format = 'video';
      break;
    case FORMATS_WORD.includes(fileType):
      format = 'word';
      break;
    case FORMATS_EXCEL.includes(fileType):
      format = 'excel';
      break;
    case FORMATS_POWERPOINT.includes(fileType):
      format = 'powerpoint';
      break;
    case FORMAT_PDF.includes(fileType):
      format = 'pdf';
      break;
    case FORMAT_PHOTOSHOP.includes(fileType):
      format = 'photoshop';
      break;
    case FORMATS_ILLUSTRATOR.includes(fileType):
      format = 'illustrator';
      break;
    default:
      format = fileType;
  }

  return toLower(format);
};

const fileThumb = (fileUrl) => {
  let thumb;

  switch (getFileFormat(fileUrl)) {
    case 'folder':
      thumb = iconUrl('ic_folder');
      break;
    case 'txt':
      thumb = iconUrl('ic_txt');
      break;
    case 'zip':
      thumb = iconUrl('ic_zip');
      break;
    case 'audio':
      thumb = iconUrl('ic_audio');
      break;
    case 'video':
      thumb = iconUrl('ic_video');
      break;
    case 'word':
      thumb = iconUrl('ic_word');
      break;
    case 'excel':
      thumb = iconUrl('ic_excel');
      break;
    case 'powerpoint':
      thumb = iconUrl('ic_power_point');
      break;
    case 'pdf':
      thumb = iconUrl('ic_pdf');
      break;
    case 'photoshop':
      thumb = iconUrl('ic_pts');
      break;
    case 'eml':
      thumb = iconUrl('ic_mail');
      break;
    case 'illustrator':
      thumb = iconUrl('ic_ai');
      break;
    case 'image':
      thumb = iconUrl('ic_img');
      break;
    default:
      thumb = iconUrl('ic_file');
  }
  return thumb;
};

const getAuthorizedFileFormats = (formats) => {
  const flattenAuthorizedFileFormats = formats.flat();
  const formattedAuthorizedFormats = flattenAuthorizedFileFormats.map((format) => `.${format}`);
  return formattedAuthorizedFormats.join(', ');
};

const getFileIcon = (fileUrl) => {
  const format = getFileFormat(fileUrl);
  let icon;
  if (format === 'excel') {
    icon = 'ant-design:file-excel-filled';
  }
  if (format === 'pdf') {
    // cspell-checker: disable-next-line
    icon = 'ant-design:file-pdf-twotone';
  }
  return icon;
};

const getShortFileName = (fileName) => {
  const maxLength = 15;
  if (fileName.length > maxLength) {
    return `${fileName.slice(0, maxLength)}...`;
  }
  return null;
};

export {
  FORMAT_PDF,
  FORMATS_EXCEL,
  FORMATS_ILLUSTRATOR,
  FORMATS_IMG,
  FORMATS_POWERPOINT,
  FORMATS_VIDEO,
  FORMATS_WORD,
  FORMAT_EMAIL,
  FORMAT_PHOTOSHOP,
  FORMAT_JSON,
  getAuthorizedFileFormats,
  getFileType,
  getShortFileName,
  getFileIcon,
  getFileName,
  getFileFullName,
  getFileFormat,
  fileThumb,
};
