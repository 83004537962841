const variants = (theme) => [
  {
    props: { size: 'small' },
    style: {
      padding: theme.spacing(0.6, 1),
    },
  },
  {
    props: { size: 'medium' },
    style: {
      padding: theme.spacing(1.25, 2),
    },
  },
  {
    props: { size: 'large' },
    style: {
      padding: theme.spacing(1.5, 2.25),
    },
  },
  {
    props: { variant: 'gradient' },
    style: {
      '&.Mui-disabled': {
        backgroundImage: theme.palette.gradients.mainDisabled,
        color: theme.palette.common.white,
      },
      backgroundImage: theme.palette.gradients.main,
      color: theme.palette.common.white,
      padding: theme.spacing(1.4, 2.2),
    },
  },
];

export default variants;
