/* eslint-disable react/destructuring-assignment */
import React from 'react';

import { CloseIcon } from './CustomIcons';

const Chip = (theme) => ({
  MuiChip: {
    defaultProps: {
      deleteIcon: <CloseIcon />,
    },

    styleOverrides: {
      avatarColorError: {
        backgroundColor: theme.palette.error.dark,
        color: theme.palette.error.contrastText,
      },

      //
      avatarColorInfo: {
        backgroundColor: theme.palette.info.dark,
        color: theme.palette.info.contrastText,
      },

      avatarColorSuccess: {
        backgroundColor: theme.palette.success.dark,
        color: theme.palette.success.contrastText,
      },
      avatarColorWarning: {
        backgroundColor: theme.palette.warning.dark,
        color: theme.palette.warning.contrastText,
      },
      colorDefault: {
        '& .MuiChip-avatarMedium, .MuiChip-avatarSmall': {
          color: theme.palette.text.secondary,
        },
      },
      outlined: {
        '&.MuiChip-colorPrimary': {
          borderColor: theme.palette.primary.main,
        },
        '&.MuiChip-colorSecondary': {
          borderColor: theme.palette.secondary.main,
        },
        borderColor: theme.palette.grey[500_32],
      },
    },
  },
});

export default Chip;
