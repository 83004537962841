import { Button, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import CONTACT_EMAILS from 'src/constants/contactEmails';
import i18n from 'src/locales/i18n';
import generateMailTo from 'src/utils/generateMailTo';

import Iconify from './Iconify';
import Image from './Image';

const RootStyle = styled('div')(({ theme, withoutPadding }) => ({
  alignItems: 'center',
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  justifyContent: 'center',
  padding: theme.spacing(8, 2),
  textAlign: 'center',
  width: '100%',
  ...(withoutPadding && { padding: theme.spacing(1.5) }),
}));

const EmptyContent = ({
  children,
  count,
  description,
  imageUrl,
  loading,
  title,
  titleVariant,
  withDescription,
  withSupportEmailAction,
  withoutPadding,
  ...other
}) => {
  if (loading) return null;

  const renderContent = () => (
    <RootStyle withoutPadding={withoutPadding} {...other}>
      {imageUrl && (
        <Image
          alt={title}
          disabledEffect
          src={imageUrl}
          sx={{ height: 80, mb: 3 }}
          visibleByDefault
        />
      )}

      <Typography gutterBottom variant={titleVariant}>
        {title}
      </Typography>
      {withDescription && (
        <Typography color="text.secondary" variant="body2">
          {description}
        </Typography>
      )}
      {withSupportEmailAction && (
        <Button
          color="primary"
          fullWidth
          href={generateMailTo({
            email: CONTACT_EMAILS.SUPPORT,
            subject: i18n.t('commons.contactSupportEmailSubject'),
          })}
          startIcon={<Iconify color="primary" icon="solar:help-bold" />}
          sx={{ mt: 3 }}
          variant="outlined"
        >
          {i18n.t('commons.contactSupport')}
        </Button>
      )}
    </RootStyle>
  );

  return (
    count <= 0 && (typeof children === 'function' ? children({ renderContent }) : renderContent())
  );
};

EmptyContent.defaultProps = {
  description: i18n.t('components.emptyContentText'),
  title: i18n.t('components.emptyContentTitle'),
  titleVariant: 'h6',
  withDescription: true,
  withSupportEmailAction: false,
  withoutPadding: false,
};

export default EmptyContent;
