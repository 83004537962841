import { FINANCING_MODULES } from 'src/constants/financingModuleTypes';
import * as Yup from 'yup';

import ModuleForm from './ModuleForm';

const getModuleFormConfiguration = ({ translate, modules = FINANCING_MODULES }) => {
  const schema = {
    module: Yup.string()
      .nullable()
      .required(translate('commons.requiredSelectField'))
      .oneOf(modules.map((financingModule) => financingModule.value)),
  };

  const defaultValues = {
    module: null,
  };

  return {
    Component: ModuleForm,
    defaultValues,
    label: translate('sections.dashboardAdministrationFinancingCreateFinancingFormStepFour'),
    props: { financingModules: modules },
    schema,
  };
};

export default getModuleFormConfiguration;
