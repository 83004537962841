/* eslint-disable react/destructuring-assignment */
import React from 'react';

import { StarIcon } from './CustomIcons';

const ICON_SMALL = { height: 20, width: 20 };
const ICON_LARGE = { height: 28, width: 28 };

const Rating = (theme) => ({
  MuiRating: {
    defaultProps: {
      emptyIcon: <StarIcon />,
      icon: <StarIcon />,
    },

    styleOverrides: {
      iconEmpty: { color: theme.palette.grey[500_48] },
      root: {
        '&.Mui-disabled': {
          opacity: 0.48,
        },
      },
      sizeLarge: { '& svg': { ...ICON_LARGE } },
      sizeSmall: { '& svg': { ...ICON_SMALL } },
    },
  },
});

export default Rating;
