// cspell-checker: disable-next-line
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { IconButton, InputAdornment, Link, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link as RouterLink } from 'react-router-dom';
import FormErrors from 'src/components/form/FormErrors';
import { FormProvider, RHFCheckbox, RHFTextField } from 'src/components/hook-form';
import Iconify from 'src/components/Iconify';
import expectedErrors from 'src/graphql/users/expectedErrors';
import { LOGIN } from 'src/graphql/users/mutations';
import useAuth from 'src/hooks/useAuth';
import useLocales from 'src/hooks/useLocales';
import useRecaptcha from 'src/hooks/useRecaptcha';
import { PATH_AUTH } from 'src/routes/paths';
import { useSafeMutation } from 'src/services/apollo-client/wrappers';
import * as Yup from 'yup';

const LoginForm = () => {
  const { onLoginSuccess } = useAuth();
  const { translate } = useLocales();
  const { executeRecaptcha } = useRecaptcha();

  const [login, { error }] = useSafeMutation(LOGIN, {
    displaySnackbar: false,
    onCompleted: ({ login: result }) => {
      onLoginSuccess(result.token);
    },
  });

  const [showPassword, setShowPassword] = useState(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email(translate('commons.invalidEmail'))
      .required(translate('commons.requiredField')),
    password: Yup.string().required(translate('commons.requiredField')),
  });

  const defaultValues = {
    email: '',
    password: '',
    remember: true,
  };

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(LoginSchema),
  });

  const {
    formState: { isSubmitting },
    handleSubmit,
  } = methods;

  const onSubmit = async ({ email, password }) => {
    try {
      await login({
        variables: { email, password, recaptchaToken: await executeRecaptcha('loginAction') },
      });
    } catch {
      // Nothing to do
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack mt={2} spacing={3}>
        <FormErrors
          error={error}
          expectedErrors={[
            expectedErrors.INVALID_CREDENTIALS,
            expectedErrors.USER_NOT_FOUND,
            expectedErrors.ACCOUNT_SUSPENDED,
          ]}
          withChallenge
        />

        <RHFTextField
          label={translate('sections.authLoginLoginFormEmailLabel')}
          name="email"
          required
        />

        <RHFTextField
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton edge="end" onClick={() => setShowPassword(!showPassword)}>
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
          label={translate('sections.authLoginLoginFormPasswordLabel')}
          name="password"
          required
          type={showPassword ? 'text' : 'password'}
        />
      </Stack>

      <Stack alignItems="center" direction="row" justifyContent="space-between" sx={{ my: 2 }}>
        <RHFCheckbox
          label={translate('sections.authLoginLoginFormRememberMeLabel')}
          name="remember"
        />
        <Link component={RouterLink} to={PATH_AUTH.resetPasswordRequest} variant="subtitle2">
          {translate('sections.authLoginLoginFormForgotPasswordAction')}
        </Link>
      </Stack>
      <Stack spacing={2} sx={{ my: 2 }}>
        <LoadingButton
          endIcon={<Iconify icon="mdi:arrow-right" />}
          loading={isSubmitting}
          onClick={() => setShowPassword(false)}
          size="large"
          type="submit"
          variant="gradient"
        >
          {translate('sections.authLoginLoginFormLoginAction')}
        </LoadingButton>
        <Stack alignItems="center" direction="row">
          <Typography align="center" variant="body2">
            {translate('pages.authLoginSignUpText')}
          </Typography>
          <Link
            component={RouterLink}
            ml={0.5}
            sx={{ textDecoration: 'underline' }}
            to={PATH_AUTH.register}
            variant="subtitle2"
          >
            {translate('pages.authLoginSignUpAction')}
          </Link>
        </Stack>
      </Stack>
    </FormProvider>
  );
};

export default LoginForm;
