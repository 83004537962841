import { gql } from '@apollo/client';

const defaultEventFragment = gql`
  fragment DefaultEvent on DefaultEvent {
    id
    companyId
    message
    createdAt
    updatedAt
  }
`;

export default defaultEventFragment;
