import { Link, Stack, Typography } from '@mui/material';
import React from 'react';
import config from 'src/config/index';
import useFormatDateTime from 'src/hooks/useFormatDateTime';
import useLocales from 'src/hooks/useLocales';

const constructPappersUrl = (siren) => `${config.pappers.baseUrl}/entreprise/${siren}`;

const CompanyWebsiteLink = ({ translate, websiteUrl }) => (
  <Stack direction="row" justifyContent="space-between" spacing={1}>
    <Typography color="text.secondary" variant="body2">
      {translate('sections.companyInformationWebsiteLabel')}
    </Typography>
    <Typography component={Link} href={websiteUrl} noWrap target="_blank" variant="subtitle2">
      {websiteUrl}
    </Typography>
  </Stack>
);

const CompanyPappersLink = ({ siren, translate }) => {
  const formattedSirenWithSpaces = siren.replace(/(.{3})/g, '$1 ');
  return (
    <Stack direction="row" justifyContent="space-between" spacing={1}>
      <Typography color="text.secondary" variant="body2">
        {translate('sections.companyInformationSirenLabel')}
      </Typography>
      <Typography
        component={Link}
        href={constructPappersUrl(siren)}
        target="_blank"
        variant="subtitle2"
      >
        {formattedSirenWithSpaces}
      </Typography>
    </Stack>
  );
};

const CompanyAddress = ({ address, translate }) => (
  <Stack direction="row" justifyContent="space-between" spacing={1}>
    <Typography color="text.secondary" variant="body2">
      {translate('sections.companyInformationAddressLabel')}
    </Typography>
    <Typography noWrap variant="body2">
      {address}
    </Typography>
  </Stack>
);

const CompanyCreationDate = ({ creationDate, fDate, translate }) => (
  <Stack direction="row" justifyContent="space-between" spacing={1}>
    <Typography color="text.secondary" variant="body2">
      {translate('sections.companyInformationCreationDateLabel')}
    </Typography>
    <Typography variant="body2">{fDate(creationDate)}</Typography>
  </Stack>
);

const CompanyInformations = ({ company }) => {
  const { translate } = useLocales();
  const { fDate } = useFormatDateTime();

  return (
    <>
      <Stack p={3} spacing={3}>
        <Stack spacing={2}>
          <CompanyWebsiteLink translate={translate} websiteUrl={company.websiteUrl} />
          <CompanyPappersLink siren={company.siren} translate={translate} />
          <CompanyAddress address={company.fullAddress} translate={translate} />
          <CompanyCreationDate
            creationDate={company.createdAt}
            fDate={fDate}
            translate={translate}
          />
        </Stack>
      </Stack>
    </>
  );
};

export default CompanyInformations;
