import i18n from 'src/locales/i18n';

const expectedErrors = {
  FINANCING_IDENTIFIERS_ALREADY_EXISTS: {
    code: 'FINANCING_IDENTIFIERS_ALREADY_EXISTS',
    message: i18n.t('commons.alreadyHasFinancingWithSameIdentifiers'),
  },
};

export default expectedErrors;
