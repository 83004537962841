import { gql } from '@apollo/client';

const directDebitLogFragment = gql`
  fragment DirectDebitLog on DirectDebitLog {
    id
    directDebitId
    failReason
    createdAt
    isResolved
  }
`;

export default directDebitLogFragment;
