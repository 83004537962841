import { PATH_DASHBOARD } from 'src/routes/paths';

import getDefaultStep from './getDefaultStep';

const getDefaultStepPath = (activeOffers, isKYBDone) => {
  const defaultStep = getDefaultStep(activeOffers, isKYBDone);

  const stepPathPart = defaultStep?.toLocaleLowerCase();

  return PATH_DASHBOARD.offers[stepPathPart];
};

export default getDefaultStepPath;
