import { Box, Tooltip } from '@mui/material';
import React, { useEffect, useState } from 'react';
import EUROPEAN_COUNTRIES from 'src/constants/europeanCountries';
import useLocales from 'src/hooks/useLocales';

const CountryFlag = ({ countryCode, currentLang, withTooltip }) => {
  const { currentLang: defaultLang } = useLocales();
  const [{ flagUrl, label }, setCurrentLang] = useState({});
  const tooltipLabel = withTooltip && label ? label : '';

  useEffect(() => {
    if (currentLang) setCurrentLang(currentLang);
    else if (countryCode)
      setCurrentLang(EUROPEAN_COUNTRIES.find(({ code }) => code === countryCode));
    else setCurrentLang(defaultLang);
  }, [countryCode, currentLang, defaultLang, setCurrentLang]);

  return (
    <Tooltip placement="top" title={tooltipLabel}>
      <Box
        alt={label}
        component="img"
        src={flagUrl}
        sx={{ borderRadius: '4px', maxWidth: (theme) => theme.spacing(3) }}
      />
    </Tooltip>
  );
};

export default CountryFlag;
