import { Box, Button, Container, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';
import { GenericErrorIllustration } from 'src/assets';
import useAuth from 'src/hooks/useAuth';
import useLocales from 'src/hooks/useLocales';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { logger } from 'src/services/datadog-logs';

const RootStyle = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  minHeight: '100%',
  paddingBottom: theme.spacing(10),
  paddingTop: theme.spacing(15),
}));

const FallbackComponent = () => {
  const { translate } = useLocales();

  const handleRefresh = () => {
    window.location.href = PATH_DASHBOARD.routing;
  };

  return (
    <RootStyle>
      <Container>
        <Box sx={{ margin: 'auto', maxWidth: 480, textAlign: 'center' }}>
          <Typography paragraph variant="h3">
            {translate('components.errorDialogErrorTitle')}
          </Typography>
          <Typography sx={{ color: 'text.secondary' }}>
            {translate('components.errorDialogErrorText')}
          </Typography>
          <GenericErrorIllustration sx={{ height: 260, my: { sm: 10, xs: 5 } }} />
          <Button onClick={handleRefresh} size="large" variant="contained">
            {translate('components.errorDialogErrorAction')}
          </Button>
        </Box>
      </Container>
    </RootStyle>
  );
};

const ErrorBoundary = ({ children, name, ...props }) => {
  const { user } = useAuth();

  const handleError = (error, info) => {
    logger.error(`[${name}] ${error.message}`, { info, user });
  };

  return (
    <ReactErrorBoundary
      fallbackRender={(fallbackRenderProps) => <FallbackComponent {...fallbackRenderProps} />}
      onError={handleError}
      {...props}
    >
      {children}
    </ReactErrorBoundary>
  );
};
export default ErrorBoundary;
