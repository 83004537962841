import React from 'react';
import Iconify from 'src/components/Iconify';
import SvgIconStyle from 'src/components/SvgIconStyle';
import config from 'src/config/index';
import USER_ROLES from 'src/constants/userRoles';
import useAuth from 'src/hooks/useAuth';
import useLocales from 'src/hooks/useLocales';
import useNotifications from 'src/hooks/useNotifications';
import { EXTERNAL_PATHS, PATH_AUTH, PATH_DASHBOARD } from 'src/routes/paths';
import getDefaultStepPath from 'src/sections/@dashboard/users/offers/utils/getDefaultStepPath';
import isKYBDone from 'src/sections/@dashboard/users/offers/utils/isKYBDone';

const ICON_STYLE = { height: 1, width: 1 };

const ICONS = {
  adminInvoices: <Iconify icon="fa-solid:file-invoice-dollar" sx={ICON_STYLE} />,
  administrators: <SvgIconStyle src="/icons/ic_admin_panel_settings.svg" sx={ICON_STYLE} />,
  amortizations: <SvgIconStyle src="/icons/ic_layers.svg" sx={ICON_STYLE} />,
  bankRatesChange: <Iconify icon="mdi-currency-eur" sx={ICON_STYLE} />,
  blog: <Iconify icon="carbon:blog" sx={ICON_STYLE} />,
  businessAreas: <SvgIconStyle src="/icons/ic_briefcase.svg" sx={ICON_STYLE} />,
  changelogs: <Iconify icon="eva:book-outline" sx={ICON_STYLE} />,
  companies: <SvgIconStyle src="/icons/ic_people.svg" sx={ICON_STYLE} />,
  deals: <Iconify icon="mdi:deal" sx={ICON_STYLE} />,
  emails: <SvgIconStyle src="/icons/ic_mail.svg" sx={ICON_STYLE} />,
  financing: <SvgIconStyle src="/icons/ic_star.svg" sx={ICON_STYLE} />,
  grow: <SvgIconStyle src="/icons/ic_grow.svg" sx={ICON_STYLE} />,
  home: <SvgIconStyle src="/icons/ic_home.svg" sx={ICON_STYLE} />,
  invoices: <Iconify icon="fa-solid:file-invoice-dollar" sx={ICON_STYLE} />,
  maintenance: <Iconify icon="eva:settings-2-outline" sx={ICON_STYLE} />,
  notion: <Iconify icon="eva:external-link-outline" sx={ICON_STYLE} />,
  offers: <Iconify icon="mdi:offer" sx={ICON_STYLE} />,
  onboarding: <SvgIconStyle src="/icons/ic_kanban.svg" sx={ICON_STYLE} />,
  person: <SvgIconStyle src="/icons/ic_person.svg" sx={ICON_STYLE} />,
  simulations: <Iconify icon="mdi:calculator" sx={ICON_STYLE} />,
};

const useNavConfig = () => {
  const { company } = useAuth();
  const { translate } = useLocales();
  const { notifications } = useNotifications();

  // Sum for each company the pendingInvoiceNumber
  const pendingInvoicesCount = notifications?.companyWithPendingInvoices.reduce(
    (acc, invoice) => acc + invoice.pendingInvoicesNumber,
    0
  );

  const defaultOffersPath = getDefaultStepPath(company?.activeOffers, isKYBDone(company));
  const hasOffers = Boolean(company?.activeOffers?.length);

  return [
    // GENERAL
    {
      items: [
        {
          hidden: !company?.isOnBoardingDone || !hasOffers,
          icon: ICONS.offers,
          path: defaultOffersPath,
          title: translate('layouts.dashboardNavbarNavbarConfigOffers'),
        },
        {
          hidden: !(company?.isOnBoardingDone && company?.financingData?.hasFinancing),
          icon: ICONS.home,
          path: PATH_DASHBOARD.general.home,
          title: translate('layouts.dashboardNavbarNavbarConfigHome'),
        },
        {
          hidden: !company?.financingData?.hasFinancingInvoicesModule,
          icon: ICONS.invoices,
          path: PATH_DASHBOARD.users.invoices,
          title: translate('layouts.dashboardNavbarNavbarConfigInvoices'),
        },
        {
          hidden: company?.isOnBoardingDone,
          icon: ICONS.onboarding,
          path: PATH_AUTH.onboarding,
          title: translate('layouts.dashboardNavbarNavbarConfigOnBoarding'),
        },
        {
          icon: ICONS.person,
          path: PATH_DASHBOARD.users.profile(),
          title: translate('layouts.dashboardNavbarNavbarConfigProfile'),
        },
        {
          icon: ICONS.blog,
          path: PATH_DASHBOARD.general.blog,
          title: translate('layouts.dashboardNavbarNavbarConfigBlog'),
        },
      ],
      roles: USER_ROLES.COMPANY,
      subheader: translate('layouts.dashboardNavbarNavbarConfigGeneralSubHeader'),
    },
    // ADMINISTRATION
    {
      items: [
        {
          icon: ICONS.companies,
          path: PATH_DASHBOARD.administration.companies,
          title: translate('layouts.dashboardNavbarNavbarConfigCompanies'),
        },
        {
          icon: ICONS.financing,
          path: PATH_DASHBOARD.administration.financing,
          title: translate('layouts.dashboardNavbarNavbarConfigFinancing'),
        },
        //
        {
          icon: ICONS.amortizations,
          path: PATH_DASHBOARD.administration.amortizationsAndWithdrawalsOverview,
          title: translate('layouts.dashboardNavbarNavbarConfigDirectDebits'),
        },
        {
          icon: ICONS.deals,
          path: PATH_DASHBOARD.administration.offers.list,
          title: translate('layouts.dashboardNavbarNavbarConfigOffers'),
        },
        {
          icon: ICONS.simulations,
          path: PATH_DASHBOARD.administration.simulations,
          title: translate('layouts.dashboardNavbarNavbarConfigSimulations'),
        },
        {
          badgeCount: pendingInvoicesCount,
          children: [
            {
              badgeCount: pendingInvoicesCount,
              path: PATH_DASHBOARD.invoices.pending,
              title: translate('layouts.dashboardNavbarNavbarConfigAdminInvoicesPending'),
            },
            {
              path: PATH_DASHBOARD.invoices.overview,
              title: translate('layouts.dashboardNavbarNavbarConfigAdminInvoicesOverview'),
            },
          ],
          icon: ICONS.adminInvoices,
          title: translate('layouts.dashboardNavbarNavbarConfigAdminInvoices'),
        },
        {
          children: [
            {
              path: PATH_DASHBOARD.metrics.creditBook,
              title: translate('layouts.dashboardNavbarNavbarConfigMetabaseOverview'),
            },
            {
              path: PATH_DASHBOARD.metrics.creditEvolution,
              title: translate('layouts.dashboardNavbarNavbarConfigMetabaseOngoing'),
            },
            {
              path: PATH_DASHBOARD.metrics.commercialSupervision,
              title: translate('layouts.dashboardNavbarNavbarConfigMetabaseCommercialSupervision'),
            },
            {
              path: PATH_DASHBOARD.metrics.cohorts,
              title: translate('layouts.dashboardNavbarNavbarConfigCohorts'),
            },
            {
              path: PATH_DASHBOARD.metrics.creditUnpaid,
              title: translate('layouts.dashboardNavbarNavbarConfigMetabaseUnpaids'),
            },
            {
              path: PATH_DASHBOARD.metrics.default,
              title: translate('layouts.dashboardNavbarNavbarConfigMetabaseDefaults'),
            },
            {
              path: PATH_DASHBOARD.metrics.healthCenter,
              title: translate('layouts.dashboardNavbarNavbarConfigMetabaseHealthCenter'),
            },
          ],
          icon: ICONS.grow,
          // cspell-checker: disable-next-line
          title: translate('layouts.dashboardNavbarNavbarConfigMetabase'),
        },
        {
          hidden: !config.mailer.storeEmails,
          icon: ICONS.emails,
          path: PATH_DASHBOARD.administration.emails,
          title: translate('layouts.dashboardNavbarNavbarConfigEmails'),
        },
      ],
      roles: [USER_ROLES.SUPER_ADMIN, USER_ROLES.ADMIN],
      subheader: translate('layouts.dashboardNavbarNavbarConfigAdministrationSubHeader'),
    },
    // SETTINGS
    {
      items: [
        {
          icon: ICONS.administrators,
          path: PATH_DASHBOARD.settings.administrators,
          title: translate('layouts.dashboardNavbarNavbarConfigOperators'),
        },
        {
          icon: ICONS.businessAreas,
          path: PATH_DASHBOARD.settings.businessAreas,
          title: translate('layouts.dashboardNavbarNavbarConfigBusinessAreas'),
        },
        {
          icon: ICONS.bankRatesChange,
          path: PATH_DASHBOARD.settings.bankRatesChange,
          roles: USER_ROLES.SUPER_ADMIN,
          title: translate('layouts.dashboardNavbarNavbarConfigRatesChangeBank'),
        },
        {
          icon: ICONS.maintenance,
          path: PATH_DASHBOARD.settings.maintenance,
          roles: USER_ROLES.SUPER_ADMIN,
          title: translate('layouts.dashboardNavbarNavbarConfigMaintenance'),
        },
        {
          icon: ICONS.changelogs,
          path: PATH_DASHBOARD.settings.changelogs,
          title: translate('layouts.dashboardNavbarNavbarConfigChangelogs'),
        },
        {
          icon: ICONS.notion,
          path: EXTERNAL_PATHS.notion,
          title: translate('layouts.dashboardNavbarNavbarConfigImprovementsBugs'),
        },
      ],
      roles: [USER_ROLES.SUPER_ADMIN, USER_ROLES.ADMIN],
      subheader: translate('layouts.dashboardNavbarNavbarConfigSettingsSubHeader'),
    },
  ];
};

export default useNavConfig;
