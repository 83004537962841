const SPECIAL_CHARACTERS = [
  '!',
  '@',
  '#',
  '$',
  '%',
  '^',
  '&',
  '*',
  '(',
  ')',
  '_',
  '+',
  '=',
  '[',
  '\\]',
  '{',
  '}',
  ';',
  ':',
  '"',
  "'",
  '-',
  ',',
  '.',
  '<',
  '>',
  '/',
  '?',
  '\\',
  '`',
];

export default SPECIAL_CHARACTERS;
