import { List, ListSubheader } from '@mui/material';
import React from 'react';
import useLocales from 'src/hooks/useLocales';

import IntegrationNotificationItem from './IntegrationNotificationItem';

const NotificationsListInvalidIntegrations = ({ integrations, onClose }) => {
  const { translate } = useLocales();
  if (!integrations.length) return null;

  return (
    <List
      disablePadding
      subheader={
        <ListSubheader disableSticky sx={{ px: 2.5, py: 1, typography: 'overline' }}>
          {translate(
            'layouts.dashboardHeaderNotificationsIntegrationsNotificationsListInvalidIntegrationsListSubheaderTitle'
          )}
        </ListSubheader>
      }
    >
      {integrations.map((integration) => (
        <IntegrationNotificationItem
          key={integration.id}
          integration={integration}
          labelType="error"
          onClose={onClose}
          title={translate(
            'layouts.dashboardHeaderNotificationsIntegrationsNotificationsListInvalidIntegrationsNotificationTitle'
          )}
        />
      ))}
    </List>
  );
};

export default NotificationsListInvalidIntegrations;
