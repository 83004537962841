import { ListItemAvatar, ListItemButton, ListItemText, Stack, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router';
import Avatar from 'src/components/Avatar.js';
import Label from 'src/components/Label';
import INTEGRATION_SOURCES from 'src/constants/integrationSources';
import PROFILE_TABS from 'src/constants/profileTab';
import useAuth from 'src/hooks/useAuth';
import useLocales from 'src/hooks/useLocales';
import { PATH_DASHBOARD } from 'src/routes/paths';

const getIntegrationName = (integration) => {
  const { csvFile, metadata, source } = integration;
  switch (source) {
    case INTEGRATION_SOURCES.CSV_FILE:
      return csvFile.displayName;
    case INTEGRATION_SOURCES.READ_ONLY:
      return metadata.connectorName;
    default:
      return '';
  }
};

const getIntegrationsPath = ({ integration, isAtLeastAdmin }) => {
  if (isAtLeastAdmin()) {
    return PATH_DASHBOARD.companies.profile(integration.companyId, PROFILE_TABS.INTEGRATIONS);
  }
  return PATH_DASHBOARD.users.profile(PROFILE_TABS.INTEGRATIONS);
};

const IntegrationNotificationItem = ({ integration, onClose, title }) => {
  const navigate = useNavigate();
  const { translate } = useLocales();
  const { isAtLeastAdmin } = useAuth();

  return (
    <ListItemButton
      onClick={() => {
        const pathToNavigate = getIntegrationsPath({ integration, isAtLeastAdmin });
        navigate(pathToNavigate);
        onClose();
      }}
      sx={{
        mt: '1px',
        px: 2.5,
        py: 1.5,
      }}
    >
      <ListItemAvatar>
        <Avatar
          alt="Integration"
          src="/icons/ic_storm.svg"
          sx={{
            height: 24,
            width: 24,
          }}
        />
      </ListItemAvatar>
      <ListItemText
        primary={<Typography variant="subtitle2">{title}</Typography>}
        secondary={
          <Stack spacing={1}>
            <Stack alignItems="center" direction="row" spacing={1}>
              <Typography variant="caption">{`#${getIntegrationName(integration)}`}</Typography>
              <Label color="error" size="s" type="error">
                {translate(
                  'layouts.dashboardHeaderNotificationsIntegrationsIntegrationNotificationItemInvalidLabel'
                )}
              </Label>
            </Stack>
          </Stack>
        }
      />
    </ListItemButton>
  );
};

export default IntegrationNotificationItem;
