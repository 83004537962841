const Fab = (theme) => ({
  MuiFab: {
    defaultProps: {
      color: 'primary',
    },

    styleOverrides: {
      extended: {
        '& svg': {
          marginRight: theme.spacing(1),
        },
      },
      primary: {
        '&:hover': {
          backgroundColor: theme.palette.primary.dark,
        },
        boxShadow: theme.customShadows.primary,
      },
      root: {
        '&:hover': {
          backgroundColor: theme.palette.grey[400],
          boxShadow: 'none',
        },
        boxShadow: theme.customShadows.z8,
      },
      secondary: {
        '&:hover': {
          backgroundColor: theme.palette.secondary.dark,
        },
        boxShadow: theme.customShadows.secondary,
      },
    },
  },
});

export default Fab;
