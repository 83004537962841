import { gql } from '@apollo/client';

const kybReviewFragment = gql`
  fragment KYBReview on KYBReview {
    id
    kybFileType
    fileId
    reason
    message
    createdAt
    updatedAt
  }
`;

export default kybReviewFragment;
