import { gql } from '@apollo/client';

const changelogFragment = gql`
  fragment Changelog on Changelog {
    id
    version
    publicationDate
    env
    changelog
    updatedAt
    createdAt
  }
`;

export default changelogFragment;
