import styled from '@emotion/styled';
import { Box } from '@mui/material';
import React from 'react';

const StackedToRightSidePanel = styled(Box)(() => ({
  height: '100vh',
  position: 'sticky',
  top: 0,
  width: 375,
}));

const SideInformationLayout = ({ screen }) => (
  <StackedToRightSidePanel>{screen}</StackedToRightSidePanel>
);

export default SideInformationLayout;
