import MuiPhoneNumber from 'material-ui-phone-number-2';
import React, { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

const RHFPhoneTextField = ({ defaultCountry, name, regions, variant, ...other }) => {
  const { control, setValue, watch } = useFormContext();

  const values = watch();

  useEffect(() => {
    if (values[name]) {
      const splittedNum = values[name].split(' ');
      if (splittedNum.length > 1 && splittedNum[1].charAt(0) === '0') {
        const phoneIndicator = splittedNum.shift();
        splittedNum.shift();
        setValue(name, `${phoneIndicator} ${splittedNum.concat(' ')}`);
      }
    }
  }, [values, name, setValue]);

  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState: { error } }) => (
        <MuiPhoneNumber
          defaultCountry={defaultCountry}
          regions={regions}
          {...field}
          countryCodeEditable={false}
          error={!!error}
          fullWidth
          helperText={error?.message}
          variant={variant}
          {...other}
        />
      )}
    />
  );
};

RHFPhoneTextField.defaultProps = {
  defaultCountry: 'fr',
  regions: 'europe',
  variant: 'outlined',
};

export default RHFPhoneTextField;
