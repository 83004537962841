import i18n from 'src/locales/i18n';

const FINANCING_MODULE_TYPES = {
  ADS: 'ADS',
  ALL: 'ALL',
  INVOICES: 'INVOICES',
  MARKETPLACE: 'MARKETPLACE',
  OTHER: 'OTHER',
  SAAS: 'SAAS',
};

const FINANCING_MODULES = [
  {
    icon: '/static/financing-modules/ic_saas.svg',
    label: i18n.t('sections.dashboardAdministrationFinancingCreateModuleFormModulesSaasLabel'),
    value: FINANCING_MODULE_TYPES.SAAS,
  },
  {
    icon: '/static/financing-modules/ic_invoice.svg',
    label: i18n.t('sections.dashboardAdministrationFinancingCreateModuleFormInvoicesCardLabel'),
    value: FINANCING_MODULE_TYPES.INVOICES,
  },
  {
    icon: '/static/financing-modules/ic_card.svg',
    label: i18n.t('sections.dashboardAdministrationFinancingCreateModuleFormModulesAdsLabel'),
    value: FINANCING_MODULE_TYPES.ADS,
  },
  {
    icon: '/static/financing-modules/ic_other.svg',
    label: i18n.t('sections.dashboardAdministrationFinancingCreateModuleFormModulesOtherLabel'),
    value: FINANCING_MODULE_TYPES.OTHER,
  },
];

export { FINANCING_MODULES };
export default FINANCING_MODULE_TYPES;
