import getSafeArray from 'src/utils/getSafeArray';

const USER_ROLES = {
  ADMIN: 'ADMIN',
  COMPANY: 'COMPANY',
  SUPER_ADMIN: 'SUPER_ADMIN',
};

const roleHierarchy = {
  [USER_ROLES.COMPANY]: [USER_ROLES.COMPANY, USER_ROLES.ADMIN, USER_ROLES.SUPER_ADMIN],
  [USER_ROLES.ADMIN]: [USER_ROLES.ADMIN, USER_ROLES.SUPER_ADMIN],
  [USER_ROLES.SUPER_ADMIN]: [USER_ROLES.SUPER_ADMIN],
};

const checkRole = ({ currentRole, excludeRoleHierarchy = false, rolesToCheck }) => {
  const safeRequiredRoles = getSafeArray(rolesToCheck);
  if (excludeRoleHierarchy) return safeRequiredRoles.includes(currentRole);
  return safeRequiredRoles.some((requiredRole) =>
    roleHierarchy[requiredRole].includes(currentRole)
  );
};

export { checkRole };
export default USER_ROLES;
