import React, { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import Loadable from 'src/components/Loadable';
import USER_ROLES from 'src/constants/userRoles';
import RoleBasedGuard from 'src/guards/RoleBasedGuard';

// General
const UsersProfile = Loadable(lazy(() => import('../../pages/dashboard/users/Profile')));

const userProfileRoutes = [
  {
    children: [
      {
        element: <Navigate replace to="users/profile" />,
        index: true,
        path: '',
      },
      {
        element: (
          <RoleBasedGuard accessibleRoles={USER_ROLES.COMPANY}>
            <UsersProfile />
          </RoleBasedGuard>
        ),
        path: 'profile',
      },
    ],
    path: 'users',
  },
];

export default userProfileRoutes;
