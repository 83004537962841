import { Box } from '@mui/material';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

const Logo = ({ disabledLink = false, logoOnly = false, sx }) => {
  const titledLogo = logoOnly ? '/static/logo-192x192.png' : '/static/logo-titled.png';

  const logo = <Box component="img" src={titledLogo} sx={{ height: 24, width: 'auto', ...sx }} />;

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/">{logo}</RouterLink>;
};

export default Logo;
