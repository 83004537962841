import { useLazyQuery } from '@apollo/client';

import useQueryErrors from './useQueryErrors';

const useSafeLazyQuery = (query, queryOptions, errorsOptions) => {
  const [lazyQuery, { error, ...queryResult }] = useLazyQuery(query, queryOptions);
  useQueryErrors(error, errorsOptions);

  return [lazyQuery, { error, ...queryResult }];
};

export default useSafeLazyQuery;
