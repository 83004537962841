import { camelCase, capitalize } from 'lodash';
import i18n from 'src/locales/i18n';

const expectedErrors = {
  CHALLENGE_REQUIRED: {
    code: 'CHALLENGE_REQUIRED',
    message: ({ challengeType }) =>
      i18n.t(`commons.challenge${capitalize(camelCase(challengeType))}`),
  },
  EXPIRED_LINK: {
    code: 'EXPIRED_LINK',
    message: i18n.t('commons.expiredLink'),
  },
  ITEM_NOT_FOUND: {
    code: 'ITEM_NOT_FOUND',
    message: i18n.t('commons.itemNotFound'),
  },
  NO_CHALLENGE_ASSOCIATED: {
    code: 'NO_CHALLENGE_ASSOCIATED',
    message: i18n.t('commons.suspiciousActivity'),
  },
  UNAUTHENTICATED: {
    code: 'UNAUTHENTICATED',
    message: i18n.t('commons.userDisconnected'),
  },
};

export default expectedErrors;
