import { useSubscription } from '@apollo/client';

const useSafeSubscription = (query, queryOptions) => {
  try {
    return useSubscription(query, queryOptions);
  } catch {
    // Nothing to do
    return null;
  }
};

export default useSafeSubscription;
