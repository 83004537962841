import { FormHelperText } from '@mui/material';
import React, { useCallback, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { UploadAvatar, UploadMultiFile, UploadSingleFile } from '../upload';

const RHFUploadAvatar = ({ name, ...other }) => {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState: { error } }) => {
        const checkError = !!error && !field.value;

        return (
          <div>
            <UploadAvatar error={checkError} {...other} file={field.value} />
            {checkError && (
              <FormHelperText error sx={{ px: 2, textAlign: 'center' }}>
                {error.message}
              </FormHelperText>
            )}
          </div>
        );
      }}
    />
  );
};

const RHFUploadSingleFile = ({ name, ...other }) => {
  const [loading, setLoading] = useState(false);
  const { control, setValue } = useFormContext();
  const { accept, onDrop } = other;

  const handleDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];
      if (file) {
        setValue(name, file);
      }
    },
    [setValue, name]
  );

  const finalHandleDrop = async (acceptedFiles) => {
    const callback = onDrop || handleDrop;
    setLoading(true);
    try {
      await callback(acceptedFiles);
    } catch {
      // Nothing to do
    }
    setLoading(false);
  };

  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState: { error } }) => {
        const checkError = !!error && !field.value;

        return (
          <UploadSingleFile
            accept={accept}
            error={checkError}
            file={field.value}
            helperText={
              checkError && (
                <FormHelperText error sx={{ px: 2 }}>
                  {error.message}
                </FormHelperText>
              )
            }
            loading={loading}
            {...other}
            onDrop={finalHandleDrop}
          />
        );
      }}
    />
  );
};

const RHFUploadMultiFile = ({ name, ...other }) => {
  const { control, setValue } = useFormContext();
  const { accept } = other;

  const handleDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];
      if (file) {
        setValue(name, file);
      }
    },
    [setValue, name]
  );

  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState: { error } }) => {
        const checkError = !!error && field.value?.length === 0;

        return (
          <UploadMultiFile
            accept={accept}
            error={checkError}
            files={field.value}
            helperText={
              checkError && (
                <FormHelperText error sx={{ px: 2 }}>
                  {error?.message}
                </FormHelperText>
              )
            }
            onDrop={handleDrop}
            {...other}
          />
        );
      }}
    />
  );
};

export { RHFUploadAvatar, RHFUploadSingleFile, RHFUploadMultiFile };
