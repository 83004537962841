import { Badge, Box, Divider, IconButton, Typography } from '@mui/material';
import React, { forwardRef, useRef, useState } from 'react';
import Iconify from 'src/components/Iconify';
import MenuPopover from 'src/components/MenuPopover';
import Scrollbar from 'src/components/Scrollbar';
import WithRoles from 'src/components/WithRoles';
import USER_ROLES from 'src/constants/userRoles';
import useLocales from 'src/hooks/useLocales';
import useNotifications from 'src/hooks/useNotifications';
import NotificationsListInvalidIntegrations from 'src/layouts/dashboard/header/notifications/integrations/NotificationsListInvalidIntegrations';
import NotificationsCompanyWithPendingInvoices from 'src/layouts/dashboard/header/notifications/invoices/NotificationsCompanyWithPendingInvoices.js';
import NotificationsEmptyLists from 'src/layouts/dashboard/header/notifications/NotificationsEmptyLists.js';

import NotificationsListInvalidBankConnections from './bank-connections/NotificationsListInvalidBankConnections';
import NotificationsListWithdrawalTodo from './withdrawals/NotificationsListWithdrawalTodo';

// eslint-disable-next-line react/display-name
const AnimatedIconButton = forwardRef(({ children, ...other }, ref) => (
  <Box>
    <IconButton ref={ref} {...other}>
      {children}
    </IconButton>
  </Box>
));

const NotificationsPopover = () => {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const { translate } = useLocales();
  const { getNotificationsState, notifications } = useNotifications();

  const { loading } = getNotificationsState;

  if (!notifications)
    return (
      <AnimatedIconButton disabled={loading} size="large">
        <Iconify icon="eva:bell-fill" />
      </AnimatedIconButton>
    );

  const {
    companyWithPendingInvoices,
    invalidBankConnections,
    invalidIntegrations,
    todoWithdrawals,
  } = notifications;

  const todoWithdrawalsCounter = todoWithdrawals.length > 0 ? 1 : 0;

  const companyWithPendingInvoicesCounter = companyWithPendingInvoices.length;
  const invalidIntegrationsCounter = invalidIntegrations.length;
  const invalidBankConnectionsCounter = invalidBankConnections.length;

  const notificationsTotal =
    invalidIntegrationsCounter +
    companyWithPendingInvoicesCounter +
    todoWithdrawalsCounter +
    invalidBankConnectionsCounter;

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <AnimatedIconButton ref={anchorRef} disabled={loading} onClick={handleOpen} size="large">
        <Badge badgeContent={notificationsTotal} color="primary">
          <Iconify icon="eva:bell-fill" />
        </Badge>
      </AnimatedIconButton>

      <MenuPopover
        anchorEl={anchorRef.current}
        onClose={handleClose}
        open={open}
        sx={{ p: 0, width: 360 }}
      >
        <Typography sx={{ px: 2.5, py: 2 }} variant="subtitle1">
          {translate('layouts.dashboardHeaderNotificationsNotificationsPopoverTitle')}
        </Typography>
        <Divider />
        {notificationsTotal === 0 && <NotificationsEmptyLists />}
        <Scrollbar sx={{ height: 360 }}>
          <WithRoles roles={USER_ROLES.ADMIN}>
            <NotificationsListWithdrawalTodo onClose={handleClose} withdrawals={todoWithdrawals} />
          </WithRoles>
          <WithRoles roles={USER_ROLES.ADMIN}>
            <NotificationsCompanyWithPendingInvoices
              companyWithPendingInvoices={companyWithPendingInvoices}
              onClose={handleClose}
            />
          </WithRoles>
          <NotificationsListInvalidIntegrations
            integrations={invalidIntegrations}
            onClose={handleClose}
          />
          <NotificationsListInvalidBankConnections
            invalidBankConnections={invalidBankConnections}
            onClose={handleClose}
          />
          {notificationsTotal > 0 && (
            <Typography align="center" component="p" sx={{ my: 2 }} variant="caption">
              {translate('layouts.dashboardHeaderNotificationsNotificationsPopoverEndListMessage')}
            </Typography>
          )}
        </Scrollbar>
      </MenuPopover>
    </>
  );
};

export default NotificationsPopover;
