import axios from 'axios';
import config from 'src/config/index';

const { apiUrlHttp } = config;
const axiosInstance = axios.create({
  baseURL: apiUrlHttp,
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export default axiosInstance;
