const Progress = (theme) => ({
  MuiLinearProgress: {
    styleOverrides: {
      bar: {
        borderRadius: 4,
      },
      buffer: {
        backgroundColor: 'transparent',
      },
      colorPrimary: {
        backgroundColor: theme.palette.primary.lighter,
      },
      colorSecondary: {
        backgroundColor: theme.palette.secondary.lighter,
      },
      root: {
        borderRadius: 4,
        height: 6,
        overflow: 'hidden',
      },
    },
  },
});

export default Progress;
