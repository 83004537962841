const Dialog = (theme) => ({
  MuiDialog: {
    styleOverrides: {
      paper: {
        '&.MuiDialog-paper .MuiDialogActions-root': {
          padding: theme.spacing(3),
        },
        '&.MuiDialog-paperFullScreen': {
          borderRadius: 0,
        },
        '&.MuiPaper-rounded': {
          borderRadius: Number(theme.shape.borderRadius),
        },
        '@media (max-width: 600px)': {
          margin: theme.spacing(2),
        },
        '@media (max-width: 663.95px)': {
          '&.MuiDialog-paperWidthSm.MuiDialog-paperScrollBody': {
            maxWidth: '100%',
          },
        },
        borderColor: theme.palette.divider,
        borderStyle: 'solid',
        borderWidth: 1,
        boxShadow: theme.customShadows.dialog,
      },
      paperFullWidth: {
        width: '100%',
      },
    },
  },
  MuiDialogActions: {
    styleOverrides: {
      root: {
        '& > :not(:first-of-type)': {
          marginLeft: theme.spacing(1.5),
        },
      },
    },
  },
  MuiDialogContent: {
    styleOverrides: {
      root: {
        borderBottom: 0,
        borderTop: 0,
        padding: theme.spacing(3),
      },
    },
  },
  MuiDialogTitle: {
    styleOverrides: {
      root: {
        padding: theme.spacing(3, 3, 0),
      },
    },
  },
});

export default Dialog;
