import { Box, Collapse, Divider, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import Iconify from 'src/components/Iconify';
import Scrollbar from 'src/components/Scrollbar';
import { faqContentPart1, faqContentPart2 } from 'src/constants/faqContent';
import useLocales from 'src/hooks/useLocales';

import ScreenContainer from '../ScreenContainer';

const FaqCollapsibleSection = ({ content, title }) => {
  const [open, setOpen] = useState(false);
  return (
    <Stack spacing={2}>
      <Box>
        <Stack alignItems="center" direction="row" justifyContent="space-between">
          <Typography color="grey.600" onClick={() => setOpen(!open)} variant="subtitle2">
            {title}
          </Typography>
          <Box sx={{ height: 18, width: 18 }}>
            <Iconify
              icon={`eva:${open ? 'minus-outline' : 'plus-outline'}`}
              onClick={() => setOpen(!open)}
              sx={{ color: 'text.secondary', height: 18, width: 18 }}
            />
          </Box>
        </Stack>
        <Collapse in={open}>
          <Typography color="grey.600" marginTop={2} variant="body1">
            {content}
          </Typography>
        </Collapse>
      </Box>
    </Stack>
  );
};

const Faq = ({ isPostRegistration, withSupportHelper }) => {
  const { translate } = useLocales();

  const faqContentDependingOnRegistration = isPostRegistration ? faqContentPart2 : faqContentPart1;

  return (
    <ScreenContainer
      image="/static/onboarding/side-panels/background_2.png"
      withSupportHelper={withSupportHelper}
    >
      <Scrollbar>
        <Stack spacing={4}>
          <Typography color="grey.600" variant="h4">
            FAQ Financement
          </Typography>
          <Stack spacing={3}>
            {faqContentDependingOnRegistration.map((item, index) => (
              <>
                <FaqCollapsibleSection
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  content={translate(item.content)}
                  title={translate(item.title)}
                />
                <Divider />
              </>
            ))}
          </Stack>
        </Stack>
      </Scrollbar>
    </ScreenContainer>
  );
};
export default Faq;
