const SvgIcon = () => ({
  MuiSvgIcon: {
    styleOverrides: {
      fontSizeLarge: {
        fontSize: 'inherit',
        height: 32,
        width: 32,
      },
      fontSizeSmall: {
        fontSize: 'inherit',
        height: 20,
        width: 20,
      },
    },
  },
});

export default SvgIcon;
