import ValidateForm from './ValidateForm';

const getValidateFormConfiguration = ({ translate }) => ({
  Component: ValidateForm,
  defaultValues: {},
  label: translate('sections.dashboardAdministrationFinancingCreateFinancingFormStepFive'),
  props: {},
  schema: {},
});

export default getValidateFormConfiguration;
