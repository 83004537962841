import config from 'src/config/index';

const path = (root, ...subLink) => `${root}${subLink.join('/')}`;
const toParams = (params) => new URLSearchParams(params).toString();
const { notion, stripe, swan } = config;

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/';
const STRIPE_ROOT = stripe.baseUrl;
const SWAN_ROOT = swan.baseUrl;

const PATH_AUTH = {
  login: path(ROOTS_AUTH, '/login'),
  notEligible: path(ROOTS_AUTH, '/not-eligible'),
  onboarding: path(ROOTS_AUTH, '/onboarding'),
  onboardingDone: path(ROOTS_AUTH, '/onboarding-done'),
  register: path(ROOTS_AUTH, '/register'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  resetPasswordRequest: path(ROOTS_AUTH, '/reset-password/request'),
  root: ROOTS_AUTH,
  verifyEmail: path(ROOTS_AUTH, '/verify-email'),
  verifyEmailPending: path(ROOTS_AUTH, '/verify-email/pending'),
};

const PATH_PAGE = {
  bankSyncedCallbackError: '/bank-synced/callback/error',
  bankSyncedCallbackSuccess: '/bank-synced/callback/success',
  codatCallback: '/codat/callback',
  externalCallback: '/external/callback',
  page404: '/404',
};

const PATH_DASHBOARD = {
  administration: {
    amortizationsAndWithdrawalsOverview: path(
      ROOTS_DASHBOARD,
      'amortizations-withdrawals-overview'
    ),
    businessAreas: path(ROOTS_DASHBOARD, 'business-areas'),
    companies: path(ROOTS_DASHBOARD, 'companies'),
    deals: {
      detail: (dealId) => path(ROOTS_DASHBOARD, `deals/${dealId}`),
      list: path(ROOTS_DASHBOARD, 'deals'),
    },
    directDebits: path(ROOTS_DASHBOARD, 'direct-debits'),
    emails: path(ROOTS_DASHBOARD, 'emails'),
    financing: path(ROOTS_DASHBOARD, 'financing'),
    financingDetails: (financingId) => path(ROOTS_DASHBOARD, `financing/${financingId}`),
    offers: {
      detail: (id) => path(ROOTS_DASHBOARD, 'product-offers', id),
      list: path(ROOTS_DASHBOARD, 'product-offers'),
    },
    scoringDetails: ({ scoringInputId, tab }) =>
      path(ROOTS_DASHBOARD, `scoring/${scoringInputId}?tab=${tab}`),
    simulations: path(ROOTS_DASHBOARD, 'simulations'),
  },
  companies: {
    dataRoom: (companyId) => path(ROOTS_DASHBOARD, `companies/${companyId}?tab=dataroom`),
    details: (companyId) => path(ROOTS_DASHBOARD, `companies/${companyId}`),
    mandate: path(ROOTS_DASHBOARD, `mandate-creation`),
    profile: (companyId, tab, queryParams) =>
      path(ROOTS_DASHBOARD, `companies/${companyId}?tab=${tab}&${toParams(queryParams)}`),
  },
  general: {
    blog: path(ROOTS_DASHBOARD, 'blog'),
    grow: path(ROOTS_DASHBOARD, 'revenue-financing'),
    home: path(ROOTS_DASHBOARD, 'home'),
  },
  invoices: {
    overview: path(ROOTS_DASHBOARD, 'invoices/overview'),
    pending: path(ROOTS_DASHBOARD, 'invoices/pending'),
  },
  metrics: {
    cohorts: path(ROOTS_DASHBOARD, 'metrics/cohorts'),
    commercialSupervision: path(ROOTS_DASHBOARD, 'metrics/commercial-supervision'),
    creditBook: path(ROOTS_DASHBOARD, 'metrics/credit-book'),
    creditEvolution: path(ROOTS_DASHBOARD, 'metrics/credit-evolution'),
    creditUnpaid: path(ROOTS_DASHBOARD, 'metrics/credit-unpaid'),
    default: path(ROOTS_DASHBOARD, 'metrics/credit-default'),
    healthCenter: path(ROOTS_DASHBOARD, 'metrics/credit-health-center'),
  },
  offers: {
    analysis: path(ROOTS_DASHBOARD, 'offers', 'analysis'),
    contract: path(ROOTS_DASHBOARD, 'offers', 'contract'),
    kyb: path(ROOTS_DASHBOARD, 'offers', 'kyb'),
    products: path(ROOTS_DASHBOARD, 'offers', 'products'),
    refused: path(ROOTS_DASHBOARD, 'offers', 'refused'),
    request: path(ROOTS_DASHBOARD, 'offers', 'request'),
    root: path(ROOTS_DASHBOARD, 'offers'),
  },
  root: ROOTS_DASHBOARD,
  routing: path(ROOTS_DASHBOARD, 'redirect'),
  settings: {
    administrators: path(ROOTS_DASHBOARD, 'administrators'),
    bankRatesChange: path(ROOTS_DASHBOARD, 'bank-rates-change'),
    businessAreas: path(ROOTS_DASHBOARD, 'business-areas'),
    changelogs: path(ROOTS_DASHBOARD, 'changelogs'),
    maintenance: path(ROOTS_DASHBOARD, 'maintenance'),
  },
  users: {
    invoices: path(ROOTS_DASHBOARD, 'invoices'),
    profile: (tab) => path(ROOTS_DASHBOARD, `users/profile?tab=${tab}`),
  },
};

const EXTERNAL_PATHS = {
  notion: notion.url,
  stripe: {
    paymentDetails: (paymentIntentId) => path(STRIPE_ROOT, `/payments/${paymentIntentId}`),
  },
  swan: {
    paymentDetails: (externalPaymentId) =>
      path(
        SWAN_ROOT,
        `/projects/${swan.projectId}/sandbox/data/accounts/${swan.unlimitdAccountId}/transactions/${externalPaymentId}`
      ),
  },
};

export { PATH_AUTH, PATH_PAGE, PATH_DASHBOARD, ROOTS_AUTH, EXTERNAL_PATHS };
