import React, { Suspense } from 'react';
import { useLocation } from 'react-router-dom';
import LoadingScreen from 'src/components/LoadingScreen';
import useAuth from 'src/hooks/useAuth';
import { ROOTS_AUTH } from 'src/routes/paths';

// eslint-disable-next-line react/display-name
const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { isInitialized } = useAuth();

  const renderLoadingScreen = <LoadingScreen isDashboard={!pathname.includes(ROOTS_AUTH)} />;

  return (
    <Suspense fallback={renderLoadingScreen}>
      {isInitialized ? <Component {...props} /> : renderLoadingScreen}
    </Suspense>
  );
};

export default Loadable;
