import { AppBar, Box, Stack, Toolbar } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import { IconButtonAnimate } from 'src/components/animate';
import Iconify from 'src/components/Iconify';
import Logo from 'src/components/Logo';
import { HEADER, NAVBAR } from 'src/config';
import useAuth from 'src/hooks/useAuth';
import useResponsive from 'src/hooks/useResponsive';
import AccountPopover from 'src/layouts/dashboard/header/AccountPopover';
import LanguagePopover from 'src/layouts/dashboard/header/LanguagePopover';
import NotificationsPopover from 'src/layouts/dashboard/header/notifications/NotificationsPopover';
import LoggedAsAppBar from 'src/sections/shared/logged-as-app-bar/LoggedAsAppBar';
import cssStyles from 'src/utils/cssStyles';

const RootStyle = styled(AppBar, {
  shouldForwardProp: (prop) =>
    prop !== 'isCollapse' && prop !== 'isOffset' && prop !== 'hideSidebarAndFooter',
})(({ hideSidebarAndFooter, isCollapse, theme }) => ({
  ...cssStyles(theme).bgBlur(),
  boxShadow: 'none',
  height: HEADER.MOBILE_HEIGHT,
  transition: theme.transitions.create(['width', 'height'], {
    duration: theme.transitions.duration.shorter,
  }),
  zIndex: theme.zIndex.appBar + 1,
  [theme.breakpoints.up('lg')]: {
    height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
    width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH + 1}px)`,
    ...(isCollapse && {
      width: `calc(100% - ${NAVBAR.DASHBOARD_COLLAPSE_WIDTH}px)`,
    }),
    ...(hideSidebarAndFooter && {
      width: '100%',
    }),
  },
}));

const DashboardHeader = ({ hideSidebarAndFooter, isCollapse, onOpenSidebar }) => {
  const isDesktop = useResponsive('up', 'lg');
  const { company, isAtLeastAdmin } = useAuth();

  return (
    <RootStyle hideSidebarAndFooter={hideSidebarAndFooter} isCollapse={isCollapse}>
      <Toolbar
        sx={{
          minHeight: '100% !important',
          px: { lg: 4 },
        }}
      >
        {hideSidebarAndFooter && <Logo logoOnly={isCollapse} />}

        {!isDesktop && !hideSidebarAndFooter && (
          <IconButtonAnimate onClick={onOpenSidebar} sx={{ color: 'text.primary', mr: 1 }}>
            <Iconify icon="eva:menu-2-fill" />
          </IconButtonAnimate>
        )}

        <Box sx={{ flexGrow: 1 }} />

        <Stack alignItems="center" direction="row" spacing={1}>
          <LanguagePopover />
          {(isAtLeastAdmin() || company?.isOnBoardingDone) && <NotificationsPopover />}
          <AccountPopover />
        </Stack>
      </Toolbar>
      <LoggedAsAppBar />
    </RootStyle>
  );
};

DashboardHeader.defaultProps = {
  isCollapse: false,
};

export default DashboardHeader;
