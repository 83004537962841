/* eslint-disable react/destructuring-assignment */
import React from 'react';

import { CheckboxCheckedIcon, CheckboxIcon, CheckboxIndeterminateIcon } from './CustomIcons';

const Checkbox = (theme) => ({
  MuiCheckbox: {
    defaultProps: {
      checkedIcon: <CheckboxCheckedIcon />,
      disableFocusRipple: true,
      disableRipple: true,
      disableTouchRipple: true,
      icon: <CheckboxIcon />,
      indeterminateIcon: <CheckboxIndeterminateIcon />,
      size: 'small',
    },

    styleOverrides: {
      root: {
        '& .MuiSvgIcon-fontSizeMedium': {
          height: 24,
          width: 24,
        },
        '& .MuiSvgIcon-fontSizeSmall': {
          height: 20,
          width: 20,
        },
        '&.Mui-checked.Mui-disabled, &.Mui-disabled': {
          color: theme.palette.action.disabled,
        },
        padding: theme.spacing(1),
        svg: {
          '&[font-size=small]': {
            fontSize: 20,
          },
          fontSize: 24,
        },
      },
    },
  },
});

export default Checkbox;
