import moment from 'moment';
import * as Yup from 'yup';

import LoanForm from './LoanForm';

const getLoanFormConfiguration = ({ hubspotData, translate }) => {
  const schema = {
    contractSignedAt: Yup.date()
      .nullable(true)
      .required(translate('commons.requiredField'))
      .transform((curr, orig) => (orig === '' ? null : curr))
      .test('contractSignedAt', (value) => moment(value).isValid()),
    paidOutAt: Yup.date()
      .nullable(true)
      .required(translate('commons.requiredField'))
      .transform((curr, orig) => (orig === '' ? null : curr))
      .test('paidOutAt', (value) => moment(value).isValid()),
    totalAmountPaidOut: Yup.number()
      .nullable()
      .required(translate('commons.requiredField'))
      .transform((value) => (Number.isNaN(value) ? null : value)),
  };

  const defaultValues = {
    contractSignedAt: null,
    paidOutAt: null,
    totalAmountPaidOut: hubspotData.totalAmountPaidOut,
  };

  return {
    Component: LoanForm,
    defaultValues,
    label: translate('sections.dashboardAdministrationFinancingCreateFinancingFormStepOne'),
    props: {},
    schema,
  };
};

export default getLoanFormConfiguration;
