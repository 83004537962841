import { Box, IconButton } from '@mui/material';
import { m } from 'framer-motion';
import React, { forwardRef } from 'react';

// eslint-disable-next-line react/display-name
const IconButtonAnimate = forwardRef(({ children, size = 'medium', ...other }, ref) => (
  <AnimateWrap size={size}>
    <IconButton ref={ref} size={size} {...other}>
      {children}
    </IconButton>
  </AnimateWrap>
));

export default IconButtonAnimate;

const varSmall = {
  hover: { scale: 1.1 },
  tap: { scale: 0.95 },
};

const varMedium = {
  hover: { scale: 1.09 },
  tap: { scale: 0.97 },
};

const varLarge = {
  hover: { scale: 1.08 },
  tap: { scale: 0.99 },
};

const AnimateWrap = ({ children, size }) => {
  const isSmall = size === 'small';
  const isLarge = size === 'large';

  return (
    <Box
      component={m.div}
      sx={{
        display: 'inline-flex',
      }}
      variants={(isSmall && varSmall) || (isLarge && varLarge) || varMedium}
      whileHover="hover"
      whileTap="tap"
    >
      {children}
    </Box>
  );
};
