import { Link, TableCell, Typography } from '@mui/material';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { PATH_DASHBOARD } from 'src/routes/paths';
import FinancingRefundChart from 'src/sections/@dashboard/administration/financing/list/FinancingRefundChart.js';
import { fEuro } from 'src/utils/formatNumber';

const MinimalistFinancingListTableRow = ({ financing }) => {
  const { reference, reimbursementPercentage, totalAmountPaidOut } = financing;

  return (
    <>
      <TableCell>
        <Link
          component={RouterLink}
          to={PATH_DASHBOARD.administration.financingDetails(financing.id)}
        >
          <Typography fontWeight="bold">{`#${reference}`}</Typography>
        </Link>
      </TableCell>
      <TableCell align="center">{fEuro({ number: totalAmountPaidOut })}</TableCell>
      <TableCell align="center">
        <FinancingRefundChart reimbursementPercentage={reimbursementPercentage} />
      </TableCell>
    </>
  );
};
export default MinimalistFinancingListTableRow;
