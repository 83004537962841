import styled from '@emotion/styled';
import { Badge, Card, Stack, Typography } from '@mui/material';
import React from 'react';
import Iconify from 'src/components/Iconify';
import Image from 'src/components/Image';
import useHubSpotChat from 'src/hooks/useHubSpotChat';
import i18n from 'src/locales/i18n';

const scheduleCallUrl = 'https://meetings-eu1.hubspot.com/rdv30min/decouverte-meeting-1';

const GlassedCard = styled(Card)(({ theme }) => ({
  backdropFilter: 'blur(1px)',
  backgroundColor: 'rgba(255, 255, 255, 0.8)',
  border: 'none',
  boxShadow: 'none',
  margin: theme.spacing(4, 2, 2, 2),
  minHeight: '200px',
  padding: theme.spacing(2),
  zIndex: 999,
}));

const NeedSupportHelper = () => {
  const { open } = useHubSpotChat();

  return (
    <GlassedCard>
      <Stack spacing={2}>
        <Typography variant="subtitle2">{i18n.t('commons.needHelp')}</Typography>
        <Stack direction="row" spacing={1}>
          <Image
            src="/memojis/mens/young/4.png"
            sx={{
              height: 48,
              width: 48,
            }}
          />
          <Stack>
            <Typography variant="subtitle2">{i18n.t('commons.salesTeam.pb')}</Typography>
            <Stack alignItems="center" direction="row" spacing={2}>
              <Badge color="success" variant="dot" />
              <Typography variant="body2">{i18n.t('commons.online')}</Typography>
            </Stack>
          </Stack>
        </Stack>

        <Stack
          alignItems="center"
          direction="row"
          onClick={() => {
            window.open(scheduleCallUrl, '_blank');
          }}
          spacing={1}
          sx={{
            '&:hover': {
              cursor: 'pointer',
            },
          }}
        >
          <Image src="/icons/help.png" width={10} />
          <Typography>{i18n.t('pages.onboardingScheduleCall')}</Typography>
        </Stack>
        <Stack
          alignItems="center"
          direction="row"
          onClick={() => {
            open();
          }}
          spacing={1}
          sx={{
            '&:hover': {
              cursor: 'pointer',
            },
          }}
        >
          <Iconify color="primary.main" icon="eva:message-square-outline" />
          <Typography>{i18n.t('pages.onboardingLetsTalk')}</Typography>
        </Stack>
      </Stack>
    </GlassedCard>
  );
};

export default NeedSupportHelper;
