import { Box } from '@mui/material';
import React from 'react';

const SvgIconStyle = ({ src, sx }) => (
  <Box
    component="span"
    sx={{
      WebkitMask: `url(${src}) no-repeat center / contain`,
      bgcolor: 'currentColor',
      display: 'inline-block',
      height: 24,
      mask: `url(${src}) no-repeat center / contain`,
      width: 24,
      ...sx,
    }}
  />
);

export default SvgIconStyle;
