import { List, ListSubheader } from '@mui/material';
import React from 'react';
import useLocales from 'src/hooks/useLocales';
import CompanyWithPendingInvoicesNotificationItem from 'src/layouts/dashboard/header/notifications/invoices/CompanyWithPendingInvoicesNotificationItem';

const NotificationsCompanyWithPendingInvoices = ({ companyWithPendingInvoices, onClose }) => {
  const { translate } = useLocales();
  const hasCompanyWithPendingInvoices = companyWithPendingInvoices.length > 0;

  if (!hasCompanyWithPendingInvoices) return null;

  return (
    <List
      disablePadding
      subheader={
        <ListSubheader
          disableSticky
          sx={{
            px: 2.5,
            py: 1,
            typography: 'overline',
          }}
        >
          {translate(
            'layouts.dashboardHeaderNotificationsInvoicesNotificationsCompanyWithPendingInvoicesTitle'
          )}
        </ListSubheader>
      }
    >
      {companyWithPendingInvoices.map((companyWithPendingInvoice) => (
        <CompanyWithPendingInvoicesNotificationItem
          key={companyWithPendingInvoices.id}
          companyWithPendingInvoice={companyWithPendingInvoice}
          onClose={onClose}
        />
      ))}
    </List>
  );
};

export default NotificationsCompanyWithPendingInvoices;
