import { Box } from '@mui/material';
import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const getRatio = (ratio = '1/1') =>
  ({
    '1/1': '100%',
    '16/9': 'calc(100% / 16 * 9)',
    '21/9': 'calc(100% / 21 * 9)',
    '3/4': 'calc(100% / 3 * 4)',
    '4/3': 'calc(100% / 4 * 3)',
    '4/6': 'calc(100% / 4 * 6)',
    '6/4': 'calc(100% / 6 * 4)',
    '9/16': 'calc(100% / 9 * 16)',
    '9/21': 'calc(100% / 9 * 21)',
  }[ratio]);

const Image = ({ disabledEffect = false, effect = 'blur', ratio, sx, ...other }) => {
  if (ratio) {
    return (
      <Box
        component="span"
        sx={{
          '& .wrapper': {
            backgroundSize: 'cover !important',
            bottom: 0,
            left: 0,
            lineHeight: 0,
            position: 'absolute',
            right: 0,
            top: 0,
          },
          display: 'block',
          lineHeight: 0,
          overflow: 'hidden',
          position: 'relative',
          pt: getRatio(ratio),
          width: 1,
          ...sx,
        }}
      >
        <Box
          component={LazyLoadImage}
          effect={disabledEffect ? undefined : effect}
          placeholderSrc="https://zone-assets-api.vercel.app/assets/img_placeholder.svg"
          sx={{ height: 1, objectFit: 'cover', width: 1 }}
          wrapperClassName="wrapper"
          {...other}
        />
      </Box>
    );
  }

  return (
    <Box
      component="span"
      sx={{
        '& .wrapper': { backgroundSize: 'cover !important', height: 1, width: 1 },
        display: 'block',
        lineHeight: 0,
        overflow: 'hidden',
        ...sx,
      }}
    >
      <Box
        component={LazyLoadImage}
        effect={disabledEffect ? undefined : effect}
        placeholderSrc="https://zone-assets-api.vercel.app/assets/img_placeholder.svg"
        sx={{ height: 1, objectFit: 'cover', width: 1 }}
        wrapperClassName="wrapper"
        {...other}
      />
    </Box>
  );
};

export default Image;
