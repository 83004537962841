import { Divider, Stack } from '@mui/material';
import React from 'react';

import AdminActions from '../actions/AdminActions';
import CompanyActions from '../actions/CompanyActions';
import UserActions from '../actions/UserActions';
import CompanySectorsSelects from '../CompanySectorsSelects';

const CompanyDrawerActionsTabPanel = ({ businessAreas, company, theme, translate }) => (
  <Stack p={3} spacing={4}>
    <CompanyActions company={company} theme={theme} translate={translate} />
    <Divider variant="middle" />
    <UserActions company={company} theme={theme} translate={translate} />
    <Divider variant="middle" />
    <CompanySectorsSelects businessAreas={businessAreas} company={company} translate={translate} />
    {!company.isInDefault && (
      <>
        <Divider variant="middle" />
        <AdminActions company={company} theme={theme} translate={translate} />
      </>
    )}
  </Stack>
);

export default CompanyDrawerActionsTabPanel;
