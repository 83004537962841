import { List, ListSubheader } from '@mui/material';
import React from 'react';
import useLocales from 'src/hooks/useLocales';

import InvalidBankConnectionNotificationItem from './InvalidBankConnectionNotificationItem';

const NotificationsListInvalidBankConnections = ({ invalidBankConnections, onClose }) => {
  const { translate } = useLocales();
  if (!invalidBankConnections.length) return null;

  const getInvalidBankConnectionTitle = (invalidBankConnection) => {
    const { bankName } = invalidBankConnection;

    return translate(
      'layouts.dashboardHeaderNotificationsBankConnectionNotificationsListInvalidNotificationTitle',
      {
        bankName,
      }
    );
  };

  return (
    <List
      disablePadding
      subheader={
        <ListSubheader disableSticky sx={{ px: 2.5, py: 1, typography: 'overline' }}>
          {translate(
            'layouts.dashboardHeaderNotificationsBankConnectionNotificationsListInvalidNotificationListTitle'
          )}
        </ListSubheader>
      }
    >
      {invalidBankConnections.map((invalidBankConnection) => (
        <InvalidBankConnectionNotificationItem
          key={invalidBankConnection.id}
          invalidBankConnection={invalidBankConnection}
          labelType="error"
          onClose={onClose}
          title={getInvalidBankConnectionTitle(invalidBankConnection)}
        />
      ))}
    </List>
  );
};

export default NotificationsListInvalidBankConnections;
