import styled from '@emotion/styled';
import { Box, Stack } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import GradientTypography from './GradientTypography';

const AnimatedDiv = styled(Box)`
  animation: scrollAnimation 15s linear infinite;
  display: flex;
  flex-direction: row;
  white-space: nowrap;

  @keyframes scrollAnimation {
    0% {
      transform: translateX(0%);
    }
    100% {
      transform: translateX(-100%);
    }
  }
`;

const ScrollingFrieze = ({ text }) => {
  const textRef = useRef(null);
  const containerRef = useRef(null);
  const [textWidth, setTextWidth] = useState(window.innerWidth);

  useEffect(() => {
    if (textRef.current && containerRef.current) {
      setTextWidth(textRef.current.offsetWidth);
    }
  }, [text]);

  const minimumTextCount = useMemo(() => Math.ceil(window.innerWidth / textWidth), [textWidth]);

  return (
    <Box
      ref={containerRef}
      sx={{
        overflowX: 'hidden',
      }}
    >
      <AnimatedDiv>
        <Stack alignItems="center" direction="row" py={2} spacing={5}>
          {Array.from({ length: minimumTextCount }, (_, i) => (
            <Stack key={i} ref={textRef} alignItems="center" direction="row" spacing={5}>
              <GradientTypography>{text}</GradientTypography>
              <GradientTypography>•</GradientTypography>
            </Stack>
          ))}
          {Array.from({ length: minimumTextCount }, (_, i) => (
            <Stack key={i} alignItems="center" direction="row" spacing={5}>
              <GradientTypography>{text}</GradientTypography>
              <GradientTypography>•</GradientTypography>
            </Stack>
          ))}
        </Stack>
      </AnimatedDiv>
    </Box>
  );
};

ScrollingFrieze.propTypes = {
  text: PropTypes.string.isRequired,
};

export default ScrollingFrieze;
