import React from 'react';
import { MODAL_TYPES } from 'src/contexts/DialogsContext';

import GenericDialog from './GenericDialog';

const AlertDialog = ({ confirmLabel, message, onConfirm, open, title, ...props }) => (
  <GenericDialog
    confirmLabel={confirmLabel}
    dialogType={MODAL_TYPES.ALERT}
    message={message}
    onConfirm={onConfirm}
    open={open}
    title={title}
    {...props}
  />
);

export default AlertDialog;
