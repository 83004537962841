import React from 'react';
import { checkRole } from 'src/constants/userRoles';
import useAuth from 'src/hooks/useAuth';

const WithRoles = ({ children, excludeRoleHierarchy, roles }) => {
  const { user } = useAuth();

  if (roles && !checkRole({ currentRole: user.role, excludeRoleHierarchy, rolesToCheck: roles }))
    return null;

  return <>{children}</>;
};

WithRoles.defaultProps = {
  excludeRoleHierarchy: false,
};

export default WithRoles;
