import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { Box, Button, Stack, alpha } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import Iconify from 'src/components/Iconify';
import USER_ROLES from 'src/constants/userRoles';
import {
  COMPLETE_ONBOARDING,
  DELETE_COMPANY,
  GENERATE_MANDATE_CREATION_URL,
  INCOMPLETE_ONBOARDING,
} from 'src/graphql/companies/mutations';
import {
  CREATE_USER_TOKEN,
  DELETE_USER_CACHE,
  REQUEST_RESET_PASSWORD,
} from 'src/graphql/users/mutations';
import useAntiSpammingAction from 'src/hooks/useAntiSpammingAction';
import useAuth from 'src/hooks/useAuth';
import useDialogs from 'src/hooks/useDialogs';
import useLocales from 'src/hooks/useLocales';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { useSafeMutation } from 'src/services/apollo-client/wrappers';
import copyToClipboard from 'src/utils/copyToClipboard';

import ReportDefaultEventFormDialog from '../../../infos/ReportDefaultEventFormDialog';

const createAction = (options) => ({
  backgroundColor: options.backgroundColor,
  color: options.color,
  context: options.context,
  disabled: options.disabled || false,
  handlerName: options.handlerName,
  hidden: options.hidden || false,
  icon: options.icon,
  img: options.img || false,
  label: options.label,
  variant: options.variant || 'contained',
});

const getAvailableActions = ({
  actionContext,
  canPerformActionReset,
  company,
  isSuperAdmin,
  theme,
  translate,
}) => {
  const hasAtLeastOneVerifiedUser = company.users.some((user) => user.emailVerified);
  const hasFinancing = company.financingData.financingNumber > 0;
  const hasMandate = Boolean(company?.metadata?.mandateUrl);
  const canDeleteCompany = !hasFinancing;
  const { isOnBoardingDone, users } = company;
  const [mainUser] = users;
  const resetPasswordHidden = !mainUser.passwordCreatedAt && mainUser.createdByAdmin;
  const disabledBgColor = alpha(theme.palette.text.disabled, 0.1);
  const errorBgColor = alpha(theme.palette.error.main, 0.16);

  const actions = [
    createAction({
      backgroundColor: disabledBgColor,
      color: 'inherit',
      context: 'user',
      handlerName: 'openSession',
      icon: 'ic:baseline-vpn-key',
      label: translate(
        'sections.dashboardAdministrationCompaniesV2DetailsCompanyActionsConnectLikeAction'
      ),
      variant: 'inherit',
    }),
    createAction({
      backgroundColor: disabledBgColor,
      color: 'inherit',
      context: 'company',
      handlerName: 'seeMandate',
      hidden: !hasMandate,
      icon: 'ic_banking.svg',
      img: true,
      label: translate(
        'sections.dashboardAdministrationCompaniesV2DetailsCompanyActionsSeeMandateAction'
      ),
      variant: 'inherit',
    }),
    createAction({
      backgroundColor: disabledBgColor,
      color: 'inherit',
      context: 'company',
      handlerName: 'generateMandate',
      hidden: !isOnBoardingDone || company.isInDefault,
      icon: 'ic_banking.svg',
      img: true,
      label: translate(
        'sections.dashboardAdministrationCompaniesV2DetailsCompanyActionsGenerateMandateAction'
      ),
      variant: 'inherit',
    }),
    createAction({
      backgroundColor: disabledBgColor,
      color: 'inherit',
      context: 'user',
      disabled: !canPerformActionReset,
      handlerName: 'resetPassword',
      hidden: resetPasswordHidden,
      icon: 'ic:baseline-vpn-key',
      label: canPerformActionReset
        ? translate(
            'sections.dashboardAdministrationCompaniesV2DetailsCompanyActionsResetPasswordAction'
          )
        : translate(
            'sections.dashboardAdministrationCompaniesV2DetailsCompanyActionsResetPasswordActionSuccess'
          ),
      variant: 'inherit',
    }),
    createAction({
      backgroundColor: disabledBgColor,
      color: 'inherit',
      context: 'user',
      handlerName: 'clearUserCache',
      icon: 'ic:sharp-warning',
      label: translate(
        'sections.dashboardAdministrationCompaniesV2DetailsCompanyActionsClearUserCacheAction'
      ),
      variant: 'inherit',
    }),
    createAction({
      backgroundColor: disabledBgColor,
      color: 'inherit',
      context: 'company',
      handlerName: 'toggleOnboarding',
      hidden: !hasAtLeastOneVerifiedUser || company.isInDefault,
      icon: isOnBoardingDone ? 'mdi:close-circle' : 'carbon:checkbox-checked-filled',
      label: isOnBoardingDone
        ? translate(
            'sections.dashboardAdministrationCompaniesV2DetailsCompanyActionsInvalidateOnboardingAction'
          )
        : translate(
            'sections.dashboardAdministrationCompaniesV2DetailsCompanyActionsForceOnboardingAction'
          ),
      variant: 'inherit',
    }),
    createAction({
      backgroundColor: errorBgColor,
      color: theme.palette.error.main,
      context: 'admin',
      handlerName: 'deleteCompany',
      hidden: !isSuperAdmin || !canDeleteCompany,
      icon: 'ri:delete-bin-5-fill',
      label: translate(
        'sections.dashboardAdministrationCompaniesV2DetailsCompanyActionsDeleteAction'
      ),
      variant: 'error',
    }),
    createAction({
      backgroundColor: errorBgColor,
      color: theme.palette.error.main,
      context: 'admin',
      handlerName: 'openReportDefaultDialog',
      hidden: company.isInDefault,
      icon: 'eva:alert-circle-outline',
      label: translate(
        'sections.dashboardAdministrationCompaniesV2DetailsCompanyActionsDeclareDefaultAction'
      ),
      variant: 'error',
    }),
  ];

  return actions.filter((action) => !action.hidden && action.context === actionContext);
};

const createActionHandlers = (handlers) => ({
  clearUserCache: handlers.handleAction(handlers.deleteUserCache),
  deleteCompany: handlers.handleAction(handlers.deleteCompany),
  generateMandate: handlers.handleAction(handlers.generateUrl),
  openReportDefaultDialog: handlers.handleAction(handlers.openReportDefaultDialog, true),
  openSession: handlers.createUserToken,
  reportDefault: handlers.handleAction(handlers.onReportDefaultEventFormDialogOpen),
  resetPassword: handlers.handleAction(handlers.safeAdminSendRequestResetPassword),
  seeMandate: handlers.handleAction(handlers.seeMandate, true),
  toggleOnboarding: handlers.handleAction(handlers.upsertOnboarding),
});

const IconStyle = styled(Iconify)(() => ({
  flexShrink: 0,
  height: 20,
  marginTop: 1,
  width: 20,
}));

const Actions = ({ actionContext, company, onClose }) => {
  const { user } = useAuth();
  const theme = useTheme();
  const { translate } = useLocales();
  const navigate = useNavigate();
  const { confirm } = useDialogs();
  const { enqueueSnackbar } = useSnackbar();
  const [mainUser] = company.users;
  const isSuperAdmin = user.role === USER_ROLES.SUPER_ADMIN;
  const [defaultOpen, setDefaultOpen] = useState(false);

  const handleAction = (mutation, withoutConfirm) => async (event) => {
    event.preventDefault();
    event.stopPropagation();

    if (withoutConfirm || (await confirm())) return mutation();
    return null;
  };

  const [createUserToken] = useSafeMutation(CREATE_USER_TOKEN, {
    displaySnackbar: false,
    onCompleted: ({ createUserToken: res }) => {
      if (res.token) {
        window.localStorage.setItem('logged-as-token', res.token);
        window.location.reload();
      }
    },
    variables: { userId: mainUser.id },
  });

  const [requestResetPassword] = useSafeMutation(
    REQUEST_RESET_PASSWORD,
    {
      variables: {
        email: mainUser.email,
      },
    },
    {
      expectExpiredLink: true,
      silentError: false,
    }
  );

  const [deleteUserCache] = useSafeMutation(
    DELETE_USER_CACHE,
    {
      variables: {
        id: mainUser.id,
      },
    },
    {
      expectExpiredLink: true,
      silentError: false,
    }
  );

  const [safeAdminSendRequestResetPassword, { canPerformAction: canPerformActionReset }] =
    useAntiSpammingAction(`administratorRequestResetPassword_${company.id}`, requestResetPassword);

  const OPERATION = company.isOnBoardingDone ? INCOMPLETE_ONBOARDING : COMPLETE_ONBOARDING;

  const [upsertOnboarding] = useSafeMutation(OPERATION, {
    variables: {
      companyId: company.id,
    },
  });

  const [generateUrl] = useSafeMutation(GENERATE_MANDATE_CREATION_URL, {
    displaySnackbar: false,
    onCompleted: async ({ generateMandateCreationUrl }) => {
      copyToClipboard(generateMandateCreationUrl);
      enqueueSnackbar(
        translate(
          'sections.dashboardAdministrationCompaniesCompaniesMandateGenerateMandateConfirmDialog'
        )
      );
    },
    variables: {
      companyId: company.id,
    },
  });

  const [deleteCompany] = useSafeMutation(DELETE_COMPANY, {
    onCompleted: () => {
      navigate(PATH_DASHBOARD.administration.companies);
      onClose();
    },
    refetchQueries: ['listCompanies'],
    variables: {
      companyId: company.id,
    },
  });

  const seeMandate = () => {
    window.open(company.metadata.mandateUrl, '_blank');
  };
  const openReportDefaultDialog = () => setDefaultOpen(true);
  const actions = getAvailableActions({
    actionContext,
    canPerformActionReset,
    company,
    isSuperAdmin,
    theme,
    translate,
  });

  const actionHandlers = createActionHandlers({
    createUserToken,
    deleteCompany,
    deleteUserCache,
    generateUrl,
    handleAction,
    openReportDefaultDialog,
    safeAdminSendRequestResetPassword,
    seeMandate,
    upsertOnboarding,
  });

  return (
    <Stack justifyContent="space-between" pt={1} spacing={2}>
      <ReportDefaultEventFormDialog
        company={company}
        onClose={() => setDefaultOpen(false)}
        open={defaultOpen}
      />
      {actions.map((action) => (
        <Box key={action.label}>
          <Button
            color={action.color}
            disabled={action.disabled}
            onClick={actionHandlers[action.handlerName]}
            startIcon={
              action.img ? (
                <IconStyle
                  component="img"
                  src={`/icons/${action.icon}`}
                  sx={{ borderRadius: '50%', height: 24, width: 24 }}
                />
              ) : (
                <Iconify icon={action.icon} />
              )
            }
            sx={{
              backgroundColor: action.backgroundColor,
              color: action.color,
            }}
            variant={action.variant}
          >
            {action.label}
          </Button>
        </Box>
      ))}
    </Stack>
  );
};

export default Actions;
