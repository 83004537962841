import React from 'react';
import OnboardingPage from 'src/components/onboarding/OnboardingPage';
import OnboardingPageHeader from 'src/components/onboarding/OnboardingPageHeader';
import useLocales from 'src/hooks/useLocales';
import { TotalAmountFunded } from 'src/layouts/side-information/screens';
import { LoginForm } from 'src/sections/auth/login';

const LoginPage = () => {
  const { translate } = useLocales();
  return (
    <OnboardingPage hasRecaptcha sideLayout={<TotalAmountFunded />} title="Login">
      <OnboardingPageHeader
        headerSubtitle={translate('pages.authLoginSignInSubtitleText')}
        headerTitle={translate('pages.authLoginSignInTitleText')}
        icon="/icons/glass/locker.png"
      />
      <LoginForm />
    </OnboardingPage>
  );
};

export default LoginPage;
