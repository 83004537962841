import { gql } from '@apollo/client';

import { bankConnectionFragment, integrationsFragment, withdrawalFragment } from '../fragments';

const GET_NOTIFICATIONS = gql`
  query getNotifications {
    getNotifications {
      todoWithdrawals {
        ...Withdrawal
      }
      companyWithPendingInvoices {
        companyId
        businessName
        faviconUrl
        firstPendingInvoiceDate
        pendingInvoicesNumber
      }
      invalidIntegrations {
        ...Integration
      }
      invalidBankConnections {
        ...BankConnection
      }
    }
  }
  ${integrationsFragment}
  ${bankConnectionFragment}
  ${withdrawalFragment}
`;

// eslint-disable-next-line import/prefer-default-export
export { GET_NOTIFICATIONS };
