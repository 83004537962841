const Tooltip = (theme) => {
  const isLight = theme.palette.mode === 'light';

  return {
    MuiTooltip: {
      styleOverrides: {
        arrow: {
          color: theme.palette.grey[isLight ? 800 : 700],
        },
        tooltip: {
          backgroundColor: theme.palette.grey[isLight ? 800 : 700],
          borderRadius: theme.shape.borderRadius / 4,
        },
      },
    },
  };
};

export default Tooltip;
