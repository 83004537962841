import {
  FormControl,
  InputAdornment,
  InputLabel,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import React, { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import PHONE_INDICATORS from 'src/constants/phoneIndicators';

const RHFPhoneFRTextField = ({ label, name, ...other }) => {
  const theme = useTheme();
  const { control, setValue, watch } = useFormContext();

  const values = watch();

  useEffect(() => {
    if (values[name]) {
      const num = values[name];
      let formattedNum = num;
      let shouldUpdate = false;
      if (num.charAt(0) === '0') {
        formattedNum = formattedNum.replace(/^0+/, '');
        shouldUpdate = true;
      }
      let index = 0;

      const finalFormattedNum = formattedNum
        .replace(/\s|\D/g, '')
        .replace(/\d/g, (match) => {
          index += 1;
          return index === 1 || index % 2 === 1 ? `${match} ` : match;
        })
        .trim();
      if (finalFormattedNum !== formattedNum) {
        shouldUpdate = true;
        formattedNum = finalFormattedNum;
      }

      if (shouldUpdate) setValue(name, `${formattedNum}`);
    }
  }, [values, name, setValue]);

  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState: { error } }) => (
        <FormControl fullWidth variant="standard">
          <InputLabel shrink>{label}</InputLabel>
          <TextField
            {...field}
            error={!!error}
            helperText={error?.message}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Typography component="span" sx={{ mx: 0.5 }}>
                    {PHONE_INDICATORS.FR}
                  </Typography>
                </InputAdornment>
              ),
              sx: {
                '& .MuiInputBase-inputAdornedStart': {
                  backgroundColor: theme.palette.background.default,
                  borderLeftColor: theme.palette.divider,
                  borderLeftStyle: 'solid',
                  borderLeftWidth: 1,
                  paddingLeft: theme.spacing(1),
                },
                '&.MuiInputBase-adornedStart': {
                  backgroundColor: theme.palette.grey[200],
                },
              },
            }}
            {...other}
          />
        </FormControl>
      )}
    />
  );
};

RHFPhoneFRTextField.defaultProps = {
  variant: 'outlined',
};

export default RHFPhoneFRTextField;
