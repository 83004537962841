import { Link, TableCell } from '@mui/material';
import React from 'react';
import REFUND_TYPES from 'src/constants/refundTypes';
import { fEuro } from 'src/utils/formatNumber';
import { getHubspotExternalLink } from 'src/utils/hubspot';

const HubspotDealsListTableRow = ({ deal }) => (
  <>
    <TableCell>
      <Link
        href={getHubspotExternalLink({
          fieldId: deal.hubspotDealId,
          fieldType: 'deal',
        })}
        target="_blank"
      >
        {deal.hubspotDealId}
      </Link>
    </TableCell>
    <TableCell>{REFUND_TYPES[deal.refundType].label}</TableCell>
    <TableCell>{fEuro({ number: deal.totalAmountPaidOut })}</TableCell>
  </>
);
export default HubspotDealsListTableRow;
