import { gql } from '@apollo/client';

import { businessAreaFragment } from '../fragments';

const LIST_BUSINESS_AREAS = gql`
  query listBusinessAreas(
    $rowsPerPage: Int!
    $page: Int!
    $filters: BusinessAreaFiltersInput
    $orderBy: OrderByInput
  ) {
    listBusinessAreas(
      rowsPerPage: $rowsPerPage
      page: $page
      filters: $filters
      orderBy: $orderBy
    ) {
      items {
        ...BusinessArea
      }
      total
      hasMore
    }
  }
  ${businessAreaFragment}
`;

// eslint-disable-next-line import/prefer-default-export
export { LIST_BUSINESS_AREAS };
