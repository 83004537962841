import { pxToRem, responsiveFontSizes } from '../utils/getFontValue';

// cspell-checker: disable-next-line
const FONT_PRIMARY = 'ClashGrotesk-Variable'; // Google Font

const typography = {
  body1: {
    fontSize: pxToRem(16),
    fontWeight: 400,
    lineHeight: 1.5,
  },
  body2: {
    fontSize: pxToRem(14),
    fontWeight: 400,
    lineHeight: 22 / 14,
  },
  button: {
    fontSize: pxToRem(12),
    fontWeight: 600,
    lineHeight: 24 / 14,
    textTransform: 'uppercase',
  },
  caption: {
    fontSize: pxToRem(12),
    lineHeight: 1.5,
  },
  fontFamily: FONT_PRIMARY,
  fontWeightBold: 600,
  fontWeightMedium: 500,
  fontWeightRegular: 400,
  h1: {
    fontSize: pxToRem(40),
    fontWeight: 400,
    letterSpacing: 2,
    lineHeight: 80 / 64,
    ...responsiveFontSizes({ lg: 64, md: 58, sm: 52 }),
  },
  h2: {
    fontSize: pxToRem(32),
    fontWeight: 400,
    lineHeight: 64 / 48,
    ...responsiveFontSizes({ lg: 48, md: 44, sm: 40 }),
  },
  h3: {
    fontSize: pxToRem(24),
    fontWeight: 400,
    lineHeight: 1.5,
    ...responsiveFontSizes({ lg: 32, md: 30, sm: 26 }),
  },
  h4: {
    fontSize: pxToRem(20),
    fontWeight: 400,
    lineHeight: 1.5,
    ...responsiveFontSizes({ lg: 24, md: 24, sm: 20 }),
  },
  h5: {
    fontSize: pxToRem(18),
    fontWeight: 400,
    lineHeight: 1.5,
    ...responsiveFontSizes({ lg: 20, md: 20, sm: 19 }),
  },
  h6: {
    fontSize: pxToRem(17),
    fontWeight: 500,
    lineHeight: 28 / 18,
    ...responsiveFontSizes({ lg: 18, md: 18, sm: 18 }),
  },
  overline: {
    fontSize: pxToRem(12),
    fontWeight: 600,
    lineHeight: 1.5,
    textTransform: 'uppercase',
  },
  subtitle1: {
    fontSize: pxToRem(16),
    fontWeight: 600,
    lineHeight: 1.5,
  },
  subtitle2: {
    fontSize: pxToRem(14),
    fontWeight: 600,
    lineHeight: 22 / 14,
  },
};

export default typography;
