import { Button, FormControl, InputAdornment, InputLabel, TextField } from '@mui/material';
import React, { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import useLocales from 'src/hooks/useLocales';

const RHFTextField = ({ label, name, required, withApplyButton, ...other }) => {
  const { translate } = useLocales();
  const { control, setValue, watch } = useFormContext();
  const formValue = watch()?.[name];
  const [tempValue, setTempValue] = useState(formValue);
  const canApply = formValue !== tempValue;

  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState: { error } }) => (
        <FormControl fullWidth required={required} variant="standard">
          <InputLabel shrink>{label}</InputLabel>
          <TextField
            {...field}
            {...(withApplyButton && {
              InputProps: {
                endAdornment: (
                  <InputAdornment position="end">
                    <Button
                      disabled={!canApply}
                      onClick={() => setValue(name, tempValue)}
                      size="small"
                    >
                      {translate('components.rhfTextFieldTriggerOnBlurOptionButtonLabel')}
                    </Button>
                  </InputAdornment>
                ),
              },
              onChange: (event) => setTempValue(event.target.value),
              value: tempValue,
            })}
            error={!!error}
            helperText={error?.message}
            onWheel={(e) => e.preventDefault()}
            {...other}
          />
        </FormControl>
      )}
    />
  );
};

export default RHFTextField;
