import { Icon } from '@iconify/react';
import { Box } from '@mui/material';
import React from 'react';

// Compute size function that returns the size of the icon. Height is the same as the width.
const computeSize = (size) => {
  switch (size) {
    case 'xs':
      return 14;
    case 's':
      return 16;
    default:
    case 'm':
      return 18;
    case 'l':
      return 20;
    case 'xl':
      return 22;
  }
};

const Iconify = ({ icon, size, sx, ...other }) => {
  const computedSize = computeSize(size);
  return (
    <Box
      component={Icon}
      height={computedSize}
      icon={icon}
      width={computedSize}
      // eslint-disable-next-line react/jsx-sort-props
      sx={{ ...sx }}
      {...other}
    />
  );
};

Iconify.defaultProps = {
  size: 'm',
};

export default Iconify;
