const ButtonGroup = (theme) => {
  const styleContained = (color) => ({
    props: { color, variant: 'contained' },
    style: { boxShadow: theme.customShadows[color] },
  });

  return {
    MuiButtonGroup: {
      styleOverrides: {
        root: {
          '&:hover': {
            boxShadow: 'none',
          },
        },
      },

      variants: [
        {
          props: { color: 'inherit', variant: 'contained' },
          style: { boxShadow: theme.customShadows.z8 },
        },
        styleContained('primary'),
        styleContained('secondary'),
        styleContained('info'),
        styleContained('success'),
        styleContained('warning'),
        styleContained('error'),

        {
          props: { disabled: true },
          style: {
            '& .MuiButtonGroup-grouped.Mui-disabled': {
              '&.MuiButton-contained': {
                backgroundColor: theme.palette.action.disabledBackground,
              },
              borderColor: `${theme.palette.action.disabledBackground} !important`,
              color: theme.palette.action.disabled,
            },
            boxShadow: 'none',
          },
        },
      ],
    },
  };
};

export default ButtonGroup;
