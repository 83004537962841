const EUROPEAN_COUNTRIES = [
  { code: 'FR', flagUrl: '/static/countries/fr.svg', label: 'France' },
  { code: 'AT', flagUrl: '/static/countries/at.svg', label: 'Austria' },
  { code: 'BE', flagUrl: '/static/countries/be.svg', label: 'Belgium' },
  { code: 'BG', flagUrl: '/static/countries/bg.svg', label: 'Bulgaria' },
  { code: 'CY', flagUrl: '/static/countries/cy.svg', label: 'Cyprus' },
  { code: 'HR', flagUrl: '/static/countries/cz.svg', label: 'Croatia' },
  { code: 'DK', flagUrl: '/static/countries/de.svg', label: 'Denmark' },
  { code: 'EE', flagUrl: '/static/countries/ee.svg', label: 'Estonia' },
  { code: 'ES', flagUrl: '/static/countries/es.svg', label: 'Spain' },
  { code: 'FI', flagUrl: '/static/countries/fi.svg', label: 'Finland' },
  { code: 'DE', flagUrl: '/static/countries/de.svg', label: 'Germany' },
  { code: 'GR', flagUrl: '/static/countries/gr.svg', label: 'Greece' },
  { code: 'HU', flagUrl: '/static/countries/hu.svg', label: 'Hungary' },
  { code: 'IE', flagUrl: '/static/countries/ie.svg', label: 'Ireland' },
  { code: 'IT', flagUrl: '/static/countries/it.svg', label: 'Italy' },
  { code: 'LV', flagUrl: '/static/countries/lv.svg', label: 'Latvia' },
  { code: 'LT', flagUrl: '/static/countries/lt.svg', label: 'Lithuania' },
  { code: 'LU', flagUrl: '/static/countries/lu.svg', label: 'Luxembourg' },
  { code: 'MT', flagUrl: '/static/countries/mt.svg', label: 'Malta' },
  { code: 'NL', flagUrl: '/static/countries/nl.svg', label: 'Netherlands' },
  { code: 'PL', flagUrl: '/static/countries/pl.svg', label: 'Poland' },
  { code: 'PT', flagUrl: '/static/countries/pt.svg', label: 'Portugal' },
  { code: 'CZ', flagUrl: '/static/countries/cz.svg', label: 'Czechia' },
  { code: 'RO', flagUrl: '/static/countries/ro.svg', label: 'Romania' },
  { code: 'SK', flagUrl: '/static/countries/sk.svg', label: 'Slovakia' },
  { code: 'SI', flagUrl: '/static/countries/si.svg', label: 'Slovenia' },
  { code: 'SE', flagUrl: '/static/countries/se.svg', label: 'Sweden' },
];

export default EUROPEAN_COUNTRIES;
