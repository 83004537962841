import { Box, List, ListSubheader } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import { checkRole } from 'src/constants/userRoles';
import useAuth from 'src/hooks/useAuth';

import { NavListRoot } from './NavList';

const ListSubheaderStyle = styled((props) => (
  <ListSubheader disableGutters disableSticky {...props} />
))(({ theme }) => ({
  ...theme.typography.overline,
  color: theme.palette.text.primary,
  paddingBottom: theme.spacing(1),
  paddingLeft: theme.spacing(2),
  paddingTop: theme.spacing(3),
  transition: theme.transitions.create('opacity', {
    duration: theme.transitions.duration.shorter,
  }),
}));

const NavSectionVertical = ({ isCollapse = false, navConfig, ...other }) => {
  const { user } = useAuth();

  return (
    <Box {...other}>
      {navConfig
        .filter(({ hidden }) => !hidden)
        .map((group) => {
          if (
            group.roles &&
            !checkRole({
              currentRole: user.role,
              excludeRoleHierarchy: true,
              rolesToCheck: group.roles,
            })
          )
            return null;
          return (
            <List key={group.subheader} disablePadding sx={{ px: 2 }}>
              <ListSubheaderStyle
                sx={{
                  ...(isCollapse && {
                    opacity: 0,
                  }),
                }}
              >
                {group.subheader}
              </ListSubheaderStyle>

              {group.items
                .filter(({ hidden }) => !hidden)
                .map((list) => {
                  if (
                    list.roles &&
                    !checkRole({
                      currentRole: user.role,
                      excludeRoleHierarchy: true,
                      rolesToCheck: list.roles,
                    })
                  )
                    return null;
                  return <NavListRoot key={list.title} isCollapse={isCollapse} list={list} />;
                })}
            </List>
          );
        })}
    </Box>
  );
};

export { ListSubheaderStyle };
export default NavSectionVertical;
