import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import config from 'src/config/index';

const { withRecaptcha } = config.recaptcha;

const useRecaptcha = () => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  if (!withRecaptcha) {
    return { executeRecaptcha: async () => undefined };
  }

  return { executeRecaptcha };
};

export default useRecaptcha;
