import { TableCell } from '@mui/material';
import React from 'react';
import useLocales from 'src/hooks/useLocales';

const getTableHeads = ({ translate }) => [
  {
    align: 'center',
    field: 'financing.reference',
    id: 'reference',
    label: translate('sections.dashboardAdministrationFinancingV2ListFinancingTableHeadReference'),
  },
  {
    align: 'center',
    field: 'financing.totalAmountPaidOut',
    id: 'totalAmountPaidOut',
    label: translate('sections.dashboardAdministrationFinancingV2ListFinancingTableHeadAmount'),
  },
  {
    align: 'center',
    field: 'reimbursementPercentage',
    id: 'reimbursementPercentage',
    label: translate('sections.dashboardAdministrationFinancingV2ListFinancingTableHeadRepaid'),
  },
];

const MinimalistFinancingListTableHeadRow = ({ isNestedList }) => {
  const { translate } = useLocales();
  const TABLE_HEADS = getTableHeads({ isNestedList, translate });

  return TABLE_HEADS.map((headCell) => (
    <TableCell key={headCell.label} align={headCell.align} maxWidth={8}>
      {headCell.label}
    </TableCell>
  ));
};

export default MinimalistFinancingListTableHeadRow;
