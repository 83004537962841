import { gql } from '@apollo/client';

const ibanIntegrationFragment = gql`
  fragment IbanIntegration on IbanIntegration {
    id
    integrationId
    sourceId
    iban
    createdAt
    updatedAt
  }
`;

export default ibanIntegrationFragment;
