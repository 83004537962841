import { useLazyQuery } from '@apollo/client';
import jwtDecode from 'jwt-decode';
import React, { createContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import USER_ROLES, { checkRole } from 'src/constants/userRoles';
import { MY_COMPANY } from 'src/graphql/companies/queries';
import { CURRENT_USER } from 'src/graphql/users/queries';
import useDialogs from 'src/hooks/useDialogs';
import useLocales from 'src/hooks/useLocales';
import { PATH_AUTH } from 'src/routes/paths';
import { wsLink } from 'src/services/apollo-client';
import { setSession } from 'src/utils/jwt';

const AuthContext = createContext({
  logout: () => Promise.resolve(),
});

const AuthProvider = ({ children }) => {
  const { alert } = useDialogs();
  const { translate } = useLocales();

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);
  const navigate = useNavigate();

  const onFetchError = () => {
    window.localStorage.removeItem('logged-as-token');
    setSession(null);
    setIsAuthenticated(false);
    setIsInitialized(true);
  };

  const [getMyCompany, { data: companyData, refetch: refetchGetMyCompany }] = useLazyQuery(
    MY_COMPANY,
    {
      nextFetchPolicy: 'cache-and-network',
      onCompleted: () => {
        // Force socket reconnection
        wsLink.subscriptionClient.close(true);
        setIsAuthenticated(true);
        setIsInitialized(true);
      },
      onError: onFetchError,
      refetchWritePolicy: 'overwrite',
    }
  );

  const [getMe, { data: userData }] = useLazyQuery(CURRENT_USER, {
    nextFetchPolicy: 'cache-and-network',
    onCompleted: getMyCompany,
    onError: onFetchError,
    refetchWritePolicy: 'overwrite',
  });

  const currentRole = userData?.me.role;

  const accessToken = window.localStorage.getItem('token');
  useEffect(() => {
    const initialize = async () => {
      if (accessToken) {
        setSession(accessToken);
        getMe();
      } else {
        setIsInitialized(true);
      }
    };

    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onLoginSuccess = (token) => {
    setSession(token);
    // Do not call refetchMe() no more, but reload the page instead for hubspot chat widget to reload
    window.location.reload();
  };

  const logout = async () => {
    setSession(null);
    setIsAuthenticated(false);
    if (userData?.me.role === USER_ROLES.COMPANY) {
      // Fix hubspot pb for user company
      window.location.href = PATH_AUTH.login;
    } else {
      navigate(PATH_AUTH.login);
    }
  };

  const expireUser = async () => {
    await alert({
      message: translate('commons.expiredSessionAlertDialogText'),
      title: translate('commons.expiredSessionAlertDialogTitle'),
    });
    //
    logout();
  };

  const isLoggedAsUser = () => {
    const loggedAsToken = window.localStorage.getItem('logged-as-token');
    if (!loggedAsToken) return false;
    try {
      const decodedToken = jwtDecode(loggedAsToken);
      return userData?.me.id === decodedToken.id;
    } catch {
      return false;
    }
  };
  const isAtLeastAdmin = () => checkRole({ currentRole, rolesToCheck: USER_ROLES.ADMIN });
  const isAdmin = () =>
    checkRole({
      currentRole,
      excludeRoleHierarchy: true,
      rolesToCheck: USER_ROLES.ADMIN,
    });
  const isSuperAdmin = () =>
    checkRole({
      currentRole,
      excludeRoleHierarchy: true,
      rolesToCheck: USER_ROLES.SUPER_ADMIN,
    });
  const isCompany = () =>
    checkRole({
      currentRole,
      excludeRoleHierarchy: true,
      rolesToCheck: USER_ROLES.COMPANY,
    });

  return (
    <AuthContext.Provider
      value={{
        accessToken,
        company: companyData?.myCompany,
        expireUser,
        isAdmin,
        isAtLeastAdmin,
        isAuthenticated,
        isCompany,
        isInitialized,
        isLoggedAsUser: isLoggedAsUser(),
        isSuperAdmin,
        logout,
        onLoginSuccess,
        refetchGetMyCompany,
        user: userData?.me,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
