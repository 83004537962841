const breakpoints = {
  values: {
    lg: 1200,
    md: 900,
    sm: 600,
    xl: 1536,
    xs: 0,
  },
};

export default breakpoints;
