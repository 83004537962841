const variants = () => [
  {
    props: { variant: 'money' },
    style: {
      fontFamily: 'helvetica',
      // cspell-checker: disable-next-line
      fontFeatureSettings: "'tnum'",
    },
  },
];

export default variants;
