import { datadogLogs } from '@datadog/browser-logs';
import CONFIG from 'src/config/index';
import { decodeCookie, getCookie } from 'src/utils/cookies';

import DATADOG_LOGS_MOCK from './mock';

const clientToken = CONFIG.logger.datadogToken;
const ddLogs = clientToken ? datadogLogs : DATADOG_LOGS_MOCK;
const { transportToConsole } = CONFIG.logger;

let loggerIsKilled = false;

const initLogger = () => {
  ddLogs.init({
    clientToken,
    env: CONFIG.logger.env,
    forwardErrorsToLogs: false, // don't forward logged errors to Datadog
    service: CONFIG.logger.service,
    site: CONFIG.logger.site,
  });
};

const getHttpCookie = () => {
  const cookie = getCookie('uid');
  if (!cookie) return null;
  const decodedCookie = decodeCookie(cookie);
  if (!decodedCookie) return null;
  return `uid=${decodedCookie}`;
};

const logToConsole = (status, message, messageContext) => {
  if (transportToConsole)
    // eslint-disable-next-line no-console
    console[status]('[logger]', message, messageContext);
};

/* eslint-disable sort-keys-fix/sort-keys-fix */
const logger = {
  debug: (message, messageContext) => {
    if (loggerIsKilled) return;
    ddLogs.logger.debug(message, {
      ...messageContext,
      httpCookie: getHttpCookie(),
    });
    logToConsole('debug', message, messageContext);
  },
  info: (message, messageContext) => {
    if (loggerIsKilled) return;
    ddLogs.logger.info(message, {
      ...messageContext,
      httpCookie: getHttpCookie(),
    });
    logToConsole('info', message, messageContext);
  },
  warn: (message, messageContext) => {
    if (loggerIsKilled) return;
    ddLogs.logger.warn(message, {
      ...messageContext,
      httpCookie: getHttpCookie(),
    });
    logToConsole('warn', message, messageContext);
  },
  error: (message, messageContext) => {
    if (loggerIsKilled) return;
    ddLogs.logger.error(message, {
      ...messageContext,
      httpCookie: getHttpCookie(),
    });
    logToConsole('error', message, messageContext);
  },
  log: (message, messageContext, status) => {
    if (loggerIsKilled) return;
    ddLogs.logger.log(message, { ...messageContext, httpCookie: getHttpCookie() }, status);
    logToConsole(status, message, messageContext);
  },
};
/* eslint-enable sort-keys-fix/sort-keys-fix */

const killLogger = () => {
  loggerIsKilled = true;
};

export { initLogger, logger, killLogger };
