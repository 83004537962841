import { Stack } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet-async';

import GoBackButton from './GoBackButton';
import RecaptchaStyles from './recaptcha/RecaptchaStyles';

const Page = ({ children, hasParent, meta, title }) => (
  <>
    <Helmet>
      <title>{`${title} | Unlimitd`}</title>
      {meta}
    </Helmet>
    <RecaptchaStyles visible={false} />
    <Stack spacing={2}>
      {hasParent && <GoBackButton />}
      {children}
    </Stack>
  </>
);

Page.defaultProps = {
  hasParent: false,
  title: '',
};

Page.propTypes = {
  children: PropTypes.node.isRequired,
  hasParent: PropTypes.bool,
  meta: PropTypes.node,
  title: PropTypes.string,
};

export default Page;
