const Popover = (theme) => ({
  MuiPopover: {
    styleOverrides: {
      paper: {
        borderRadius: Number(theme.shape.borderRadius) / 2,
        boxShadow: theme.customShadows.z1,
      },
    },
  },
});

export default Popover;
