import { Stack, Typography } from '@mui/material';
import React from 'react';
import GenericTableList from 'src/components/table/GenericTableList';
import { GET_LAST_FINANCING_BY_COMPANY_ID } from 'src/graphql/financing/queries';
import { useSafeQuery } from 'src/services/apollo-client/wrappers';

import MinimalistFinancingListTableHeadRow from './MinimalistFinancingListTableHeadRow';
import MinimalistFinancingListTableRow from './MinimalistFinancingListTableRow';

const MAXIMUM_NUMBER_OF_FINANCING = 5;

const MinimalistFinancingList = ({ company, translate }) => {
  const { data, loading } = useSafeQuery(GET_LAST_FINANCING_BY_COMPANY_ID, {
    variables: {
      companyId: company.id,
      financingNumber: MAXIMUM_NUMBER_OF_FINANCING,
    },
  });

  if (!data) return null;

  const tableListProps = {
    isLoading: loading,
    isNestedTable: true,
    items: data.getLastFinancingByCompanyId,
    renderHeadRow: () => <MinimalistFinancingListTableHeadRow isNestedList={false} />,
    renderRow: (financing) => (
      <MinimalistFinancingListTableRow
        key={financing.id}
        financing={financing}
        isNestedList={false}
      />
    ),
    totalItems: data.getLastFinancingByCompanyId.length,
  };

  return (
    <>
      <Stack pl={3}>
        <Typography sx={{ fontWeight: 'bold' }} variant="body1">
          {translate('sections.dashboardAdministrationCompaniesFinancingTitle')}
        </Typography>
        <Typography
          color="text.secondary"
          sx={{ fontStyle: 'italic', fontWeight: 'bold' }}
          variant="body2"
        >
          {translate('sections.dashboardAdministrationCompaniesFinancingSubtitle')}
        </Typography>
      </Stack>

      <GenericTableList {...tableListProps} />
    </>
  );
};

export default MinimalistFinancingList;
