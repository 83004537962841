import { Stack } from '@mui/material';
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { RHFMoneyField, RHFTextField } from 'src/components/hook-form';
import useLocales from 'src/hooks/useLocales';

const LoanForm = () => {
  const { translate } = useLocales();
  const methods = useFormContext();
  const { setValue, watch } = methods;
  const values = watch();
  const { contractSignedAt } = values;

  useEffect(() => {
    if (contractSignedAt) setValue('paidOutAt', contractSignedAt);
  }, [setValue, contractSignedAt]);

  return (
    <>
      <Stack direction="row" spacing={2}>
        <RHFMoneyField
          label={translate(
            'sections.dashboardAdministrationFinancingCreateLoanFormTotalAmountPaidOutLabel'
          )}
          name="totalAmountPaidOut"
          required
        />
        <RHFTextField
          InputLabelProps={{ shrink: true }}
          label={translate('sections.dashboardAdministrationFinancingCreateLoanFormSignInAtLabel')}
          name="contractSignedAt"
          required
          type="date"
        />
      </Stack>
      <Stack direction="row" spacing={2}>
        <RHFTextField
          InputLabelProps={{ shrink: true }}
          label={translate('sections.dashboardAdministrationFinancingCreateLoanFormPaidOutAtLabel')}
          name="paidOutAt"
          required
          type="date"
        />
      </Stack>
    </>
  );
};

export default LoanForm;
