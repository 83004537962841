import { Stack, Typography } from '@mui/material';
import React, { useCallback, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { RHFTextField, RHFUploadSingleFile } from 'src/components/hook-form';
import { GET_FINANCING_IDENTIFIERS } from 'src/graphql/financing/queries';
import useLocales from 'src/hooks/useLocales';
import { useSafeLazyQuery } from 'src/services/apollo-client/wrappers';
import { FORMAT_PDF, getAuthorizedFileFormats } from 'src/utils/file';

const ContractForm = () => {
  const { translate } = useLocales();
  const methods = useFormContext();
  const { setValue, watch } = methods;
  const values = watch();

  const handleDrop = useCallback(
    (acceptedFiles) => {
      const contractFile = acceptedFiles[0];
      if (contractFile) {
        setValue('contractFile', contractFile);
      }
    },
    [setValue]
  );

  const [getFinancingIdentifiers, { data }] = useSafeLazyQuery(GET_FINANCING_IDENTIFIERS);

  useEffect(() => {
    if (values.module) {
      getFinancingIdentifiers({
        variables: {
          module: values.module,
        },
      });
    }
  }, [getFinancingIdentifiers, values.module]);

  useEffect(() => {
    if (data) {
      const { contractNumber, reference } = data.getFinancingIdentifiers;
      setValue('contractNumber', contractNumber);
      setValue('reference', reference);
    }
  }, [data, setValue]);

  return (
    <>
      <Stack direction="row" spacing={2}>
        <RHFTextField
          InputLabelProps={{ shrink: true }}
          label={translate(
            'sections.dashboardAdministrationFinancingCreateContractFormContractNumberLabel'
          )}
          name="contractNumber"
          required
        />
        <RHFTextField
          InputLabelProps={{ shrink: true }}
          label={translate(
            'sections.dashboardAdministrationFinancingCreateContractFormReferenceLabel'
          )}
          name="reference"
          required
          type="text"
        />
      </Stack>
      <Stack>
        <Typography color="text.secondary" sx={{ ml: 2 }} variant="caption">
          {translate(
            'sections.dashboardAdministrationFinancingCreateContractFormContractFileLabel'
          )}
        </Typography>
        <RHFUploadSingleFile
          accept={getAuthorizedFileFormats(FORMAT_PDF)}
          name="contractFile"
          onDrop={handleDrop}
          required
          withoutIllustration
        />
      </Stack>
    </>
  );
};

export default ContractForm;
