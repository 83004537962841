import { Box, Stack, Typography } from '@mui/material';
import React from 'react';
import useLocales from 'src/hooks/useLocales';

import { UploadIllustration } from '../../assets';

const BlockContent = ({ accept, withoutIllustration }) => {
  const { translate } = useLocales();

  return (
    <Stack
      alignItems="center"
      direction={{ md: 'row', xs: 'column' }}
      justifyContent="center"
      spacing={2}
      sx={{ textAlign: { md: 'left', xs: 'center' }, width: 1 }}
    >
      {!withoutIllustration && <UploadIllustration sx={{ width: 150 }} />}

      <Box>
        <Typography gutterBottom variant="h5">
          {translate('components.uploadBlockContentDropOrSelectFiles')}
        </Typography>

        <Typography sx={{ color: 'text.secondary' }} variant="body2">
          {translate('components.uploadBlockContentDropHere')}&nbsp;
          <Typography
            component="span"
            sx={{ color: 'primary.main', textDecoration: 'underline' }}
            variant="body2"
          >
            {translate('components.uploadBlockContentDropBrowseFilesPartOne')}
          </Typography>
          &nbsp;{translate('components.uploadBlockContentDropBrowseFilesPartTwo')}
        </Typography>
        <Typography sx={{ color: 'text.secondary' }} variant="body2">
          Format(s) accepté(s) : {accept}, Taille max : 15 Mo
        </Typography>
      </Box>
    </Stack>
  );
};

export default BlockContent;
