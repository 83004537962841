const Accordion = (theme) => ({
  MuiAccordion: {
    styleOverrides: {
      root: {
        '&.Mui-disabled': {
          backgroundColor: 'transparent',
        },
        '&.Mui-expanded': {
          borderRadius: theme.shape.borderRadius,
          boxShadow: theme.customShadows.z8,
        },
      },
    },
  },
  MuiAccordionSummary: {
    styleOverrides: {
      expandIconWrapper: {
        color: 'inherit',
      },
      root: {
        '&.Mui-disabled': {
          '& .MuiTypography-root': {
            color: 'inherit',
          },
          color: theme.palette.action.disabled,
          opacity: 1,
        },
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
      },
    },
  },
});

export default Accordion;
