import React from 'react';
import FormDialog from 'src/components/dialogs/FormDialog';
import { REPORT_DEFAULT } from 'src/graphql/companies/mutations';
import useLocales from 'src/hooks/useLocales';
import { useSafeMutation } from 'src/services/apollo-client/wrappers';

import getReportDefaultEventFormConfiguration from './forms/getReportDefaultEventFormConfiguration';

const ReportDefaultEventFormDialog = ({ company, onClose, open }) => {
  const { translate } = useLocales();

  const DEFAULT_EVENT_STEP_CONFIGURATION = getReportDefaultEventFormConfiguration({ translate });

  const [reportDefault, { error }] = useSafeMutation(REPORT_DEFAULT, {
    refetchQueries: ['listFinancing', 'getFinancingStatistics', 'getCompany', 'getNotifications'],
  });

  const onSubmit = async (data) => {
    try {
      await reportDefault({
        variables: {
          companyId: company.id,
          input: data,
        },
      });
    } catch {
      // Nothing to do
    }
  };

  return (
    <FormDialog
      edit={false}
      error={error}
      onClose={onClose}
      onSubmit={onSubmit}
      open={open}
      stepConfiguration={DEFAULT_EVENT_STEP_CONFIGURATION}
    />
  );
};

export default ReportDefaultEventFormDialog;
