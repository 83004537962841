/* eslint-disable react/destructuring-assignment */
import React from 'react';

import { TreeViewCollapseIcon, TreeViewEndIcon, TreeViewExpandIcon } from './CustomIcons';

const TreeView = (theme) => ({
  MuiTreeItem: {
    styleOverrides: {
      iconContainer: { width: 'auto' },
      label: { ...theme.typography.body2 },
    },
  },
  MuiTreeView: {
    defaultProps: {
      defaultCollapseIcon: <TreeViewCollapseIcon sx={{ height: 20, width: 20 }} />,
      defaultEndIcon: <TreeViewEndIcon sx={{ color: 'text.secondary', height: 20, width: 20 }} />,
      defaultExpandIcon: <TreeViewExpandIcon sx={{ height: 20, width: 20 }} />,
    },
  },
});

export default TreeView;
