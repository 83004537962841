import React from 'react';
import { MODAL_TYPES } from 'src/contexts/DialogsContext';

import GenericDialog from './GenericDialog';

const ConfirmDialog = ({
  confirmLabel,
  discardLabel,
  message,
  onConfirm,
  onDiscard,
  open,
  title,
  ...props
}) => (
  <GenericDialog
    confirmLabel={confirmLabel}
    dialogType={MODAL_TYPES.CONFIRM}
    discardLabel={discardLabel}
    message={message}
    onConfirm={() => onConfirm(true)}
    onDiscard={() => onDiscard(false)}
    open={open}
    title={title}
    {...props}
  />
);

export default ConfirmDialog;
