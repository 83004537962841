import React from 'react';
import TableSkeleton from 'src/components/table/TableSkeleton.js';
import { ROWS_PER_PAGE } from 'src/config';

const TableSkeletons = ({ itemsLength }) => {
  const rowsLength = itemsLength || ROWS_PER_PAGE;
  return Array.from(new Array(rowsLength)).map((_, index) => (
    // eslint-disable-next-line react/no-array-index-key
    <TableSkeleton key={index} />
  ));
};

export default TableSkeletons;
