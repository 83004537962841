const Slider = (theme) => {
  const isLight = theme.palette.mode === 'light';

  return {
    MuiSlider: {
      defaultProps: {
        size: 'small',
      },

      styleOverrides: {
        markLabel: {
          color: theme.palette.text.disabled,
          fontSize: 11,
          fontWeight: 400,
          marginLeft: 4,
          marginRight: 4,
        },
        root: {
          '&.Mui-disabled': {
            color: theme.palette.action.disabled,
            opacity: 0.25,
          },
          height: `2.5px !important`,
        },
        thumb: {
          '&:focus, &:hover, &.Mui-active': {
            boxShadow: 'none',
          },
          backgroundImage: theme.palette.gradients.main,
          border: `3px solid ${theme.palette.background.paper}`,
          height: 20,
          width: 20,
        },
        track: {
          backgroundImage: theme.palette.gradients.main,
          borderRadius: 7,
          overflow: 'hidden',
        },
        valueLabel: {
          backgroundColor: theme.palette.grey[isLight ? 800 : 700],
          borderRadius: 8,
        },
      },
    },
  };
};

export default Slider;
