import { gql } from '@apollo/client';
import { userFragment } from 'src/graphql/fragments';

const LOGIN = gql`
  mutation login($email: String!, $password: String!, $recaptchaToken: String) {
    login(email: $email, password: $password, recaptchaToken: $recaptchaToken) {
      token
    }
  }
`;

const PASS_CHALLENGE = gql`
  mutation passChallenge {
    passChallenge
  }
`;

const REGISTER = gql`
  mutation register(
    $user: RegisterUserInput!
    $company: RegisterCompanyInput!
    $recaptchaToken: String
  ) {
    register(user: $user, company: $company, recaptchaToken: $recaptchaToken) {
      token {
        token
      }
      companyId
    }
  }
`;

const VERIFY_EMAIL = gql`
  mutation verifyEmail {
    verifyEmail {
      ...User
    }
  }
  ${userFragment}
`;

const SEND_EMAIL_VERIFICATION = gql`
  mutation sendVerificationEmail {
    sendVerificationEmail
  }
`;

const REQUEST_RESET_PASSWORD = gql`
  mutation requestResetPassword($email: String!, $recaptchaToken: String) {
    requestResetPassword(email: $email, recaptchaToken: $recaptchaToken)
  }
`;

const DELETE_USER_CACHE = gql`
  mutation deleteUserCache($id: ID!) {
    deleteUserCache(id: $id)
  }
`;

const REQUEST_CREATE_PASSWORD = gql`
  mutation requestCreatePassword($email: String!) {
    requestCreatePassword(email: $email)
  }
`;

const RESET_PASSWORD = gql`
  mutation resetPassword($password: String!) {
    resetPassword(password: $password)
  }
`;

const CREATE_USER_TOKEN = gql`
  mutation createUserToken($userId: ID!) {
    createUserToken(userId: $userId) {
      token
    }
  }
`;

export {
  CREATE_USER_TOKEN,
  LOGIN,
  REGISTER,
  VERIFY_EMAIL,
  SEND_EMAIL_VERIFICATION,
  REQUEST_RESET_PASSWORD,
  RESET_PASSWORD,
  PASS_CHALLENGE,
  DELETE_USER_CACHE,
  REQUEST_CREATE_PASSWORD,
};
