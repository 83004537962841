import { IconButton, Link, Stack, Tooltip } from '@mui/material';
import React from 'react';

import Iconify from '../../../components/Iconify';

const SocialsButton = () => {
  const SOCIALS = [
    {
      icon: 'eva:facebook-fill',
      name: 'Facebook',
      path: 'https://www.facebook.com/getunlimitd',
      socialColor: '#1877F2',
    },
    {
      icon: 'ant-design:instagram-filled',
      name: 'Instagram',
      path: 'https://www.instagram.com/getunlimitd/',
      socialColor: '#E02D69',
    },
    {
      icon: 'eva:linkedin-fill',
      name: 'Linkedin',
      path: 'https://www.linkedin.com/company/unlimitd/',
      socialColor: '#007EBB',
    },
  ];

  return (
    <Stack direction="row" justifyContent="center">
      {SOCIALS.map(({ icon, name, path }) => (
        <Tooltip key={name} placement="top" title={name}>
          <IconButton href={path} LinkComponent={Link} target="_blank">
            <Iconify icon={icon} />
          </IconButton>
        </Tooltip>
      ))}
    </Stack>
  );
};

export default SocialsButton;
