import { gql } from '@apollo/client';
import { businessAreaFragment, companyFragment } from 'src/graphql/fragments';

const COMPLETE_ONBOARDING = gql`
  mutation completeOnboarding($companyId: ID) {
    completeOnboarding(companyId: $companyId) {
      ...Company
    }
  }
  ${companyFragment}
`;

const INCOMPLETE_ONBOARDING = gql`
  mutation incompleteOnboarding($companyId: ID!) {
    incompleteOnboarding(companyId: $companyId) {
      ...Company
    }
  }
  ${companyFragment}
`;

const UPDATE_COMPANY = gql`
  mutation updateCompany($companyId: ID!, $input: UpdateCompanyInput!) {
    updateCompany(companyId: $companyId, input: $input) {
      ...Company
      businessArea {
        ...BusinessArea
      }
    }
  }
  ${businessAreaFragment}
  ${companyFragment}
`;

const ASSOCIATE_SETUP_INTENT = gql`
  mutation associateSetUpIntent($setupIntentId: ID!) {
    associateSetUpIntent(setupIntentId: $setupIntentId)
  }
`;

const ASSOCIATE_SETUP_INTENT_WITH_INTERNAL_FUNNEL = gql`
  mutation associateSetUpIntentWithInternalFunnel($setupIntentId: ID!) {
    associateSetUpIntentWithInternalFunnel(setupIntentId: $setupIntentId)
  }
`;

const GENERATE_MANDATE_CREATION_URL = gql`
  mutation generateMandateCreationUrl($companyId: ID!) {
    generateMandateCreationUrl(companyId: $companyId)
  }
`;

const DELETE_COMPANY = gql`
  mutation deleteCompany($companyId: ID!) {
    deleteCompany(companyId: $companyId)
  }
`;

const SKIP_INTEGRATION = gql`
  mutation skipIntegration($companyId: ID!, $input: SkippableIntegrationInput!) {
    skipIntegration(companyId: $companyId, input: $input) {
      ...Company
    }
  }
  ${companyFragment}
`;

const REPORT_DEFAULT = gql`
  mutation reportDefault($companyId: ID!, $input: ReportDefaultInput!) {
    reportDefault(companyId: $companyId, input: $input) {
      ...Company
    }
  }
  ${companyFragment}
`;
const PLACE_UNDER_SURVEILLANCE = gql`
  mutation placeUnderSurveillance($companyId: ID!) {
    placeUnderSurveillance(companyId: $companyId) {
      ...Company
    }
  }
  ${companyFragment}
`;

const LOAD_ONBOARDING_INFORMATIONS_TO_HUBSPOT = gql`
  mutation loadOnboardingInformationsToHubspot(
    $input: OnboardingInformations!
    $recaptchaToken: String
    $isEligible: Boolean!
  ) {
    loadOnboardingInformationsToHubspot(
      input: $input
      recaptchaToken: $recaptchaToken
      isEligible: $isEligible
    )
  }
`;
const EXTRACT_DATA = gql`
  mutation extractData {
    extractData {
      filename
      content
    }
  }
`;

const UPDATE_HUBSPOT_COMPANY_PROPERTY = gql`
  mutation updateHubspotCompanyProperty(
    $companyId: ID!
    $input: UpdateHubspotCompanyPropertyInput!
  ) {
    updateHubspotCompanyProperty(companyId: $companyId, input: $input) {
      ...Company
    }
  }
  ${companyFragment}
`;

export {
  EXTRACT_DATA,
  COMPLETE_ONBOARDING,
  INCOMPLETE_ONBOARDING,
  UPDATE_COMPANY,
  ASSOCIATE_SETUP_INTENT,
  GENERATE_MANDATE_CREATION_URL,
  SKIP_INTEGRATION,
  ASSOCIATE_SETUP_INTENT_WITH_INTERNAL_FUNNEL,
  UPDATE_HUBSPOT_COMPANY_PROPERTY,
  REPORT_DEFAULT,
  DELETE_COMPANY,
  LOAD_ONBOARDING_INFORMATIONS_TO_HUBSPOT,
  PLACE_UNDER_SURVEILLANCE,
};
