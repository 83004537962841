import { ListItemAvatar, ListItemButton, ListItemText, Stack, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router';
import CompanyAvatar from 'src/components/CompanyAvatar';
import Iconify from 'src/components/Iconify';
import useFormatDateTime from 'src/hooks/useFormatDateTime';
import useLocales from 'src/hooks/useLocales';
import { PATH_DASHBOARD } from 'src/routes/paths';

const NotificationItem = ({ companyWithPendingInvoice, onClose }) => {
  const { fDate } = useFormatDateTime();
  const { translate } = useLocales();
  const navigate = useNavigate();
  const { businessName, companyId, faviconUrl, firstPendingInvoiceDate, pendingInvoicesNumber } =
    companyWithPendingInvoice;

  return (
    <ListItemButton
      onClick={() => {
        navigate(PATH_DASHBOARD.companies.details(companyId));
        onClose();
      }}
      sx={{
        mt: '1px',
        px: 2.5,
        py: 1.5,
      }}
    >
      <ListItemAvatar>
        <CompanyAvatar
          company={{
            businessName,
            companyId,
            faviconUrl,
          }}
          withLink={false}
        />
      </ListItemAvatar>
      <ListItemText
        primary={
          <Typography variant="subtitle2">
            {translate('layouts.dashboardHeaderNotificationsInvoicesNotificationItem', {
              pendingInvoicesNumber,
            })}
          </Typography>
        }
        secondary={
          <Stack spacing={1}>
            <Stack alignItems="center" direction="row" spacing={1}>
              <Typography variant="caption">{`#${businessName}`}</Typography>
            </Stack>
            <Typography
              sx={{
                alignItems: 'center',
                color: 'text.disabled',
                display: 'flex',
                mt: 0.5,
              }}
              variant="caption"
            >
              <Iconify icon="eva:clock-fill" size="xs" sx={{ mr: 0.5 }} />
              {fDate(firstPendingInvoiceDate)}
            </Typography>
          </Stack>
        }
      />
    </ListItemButton>
  );
};

export default NotificationItem;
