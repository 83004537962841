import { Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText } from '@mui/material';
import React, { forwardRef, useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

// eslint-disable-next-line react/display-name
const CheckboxWithError = forwardRef(({ error, onError, ...props }, ref) => {
  useEffect(() => {
    onError(error);
  }, [error, onError]);

  return <Checkbox ref={ref} {...props} />;
});

const RHFCheckbox = ({ helperText, name, ...other }) => {
  const { control } = useFormContext();
  const [formError, setFormError] = useState();

  return (
    <FormControl>
      <FormControlLabel
        control={
          <Controller
            control={control}
            name={name}
            render={({ field, fieldState: { error } }) => (
              <CheckboxWithError
                checked={field.value}
                error={error}
                onError={setFormError}
                {...field}
              />
            )}
          />
        }
        {...other}
      />
      <FormHelperText error={!!formError}>{formError?.message || helperText}</FormHelperText>
    </FormControl>
  );
};

const RHFMultiCheckbox = ({ name, options, ...other }) => {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => {
        const onSelected = (option) =>
          field.value.includes(option)
            ? field.value.filter((value) => value !== option)
            : [...field.value, option];

        return (
          <FormGroup>
            {options.map((option) => (
              <FormControlLabel
                key={option}
                control={
                  <Checkbox
                    checked={field.value.includes(option)}
                    onChange={() => field.onChange(onSelected(option))}
                  />
                }
                label={option}
                {...other}
              />
            ))}
          </FormGroup>
        );
      }}
    />
  );
};

export { RHFCheckbox, RHFMultiCheckbox };
