import { IconButton, List, ListItem, ListItemText, Stack } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { AnimatePresence, m } from 'framer-motion';
import isString from 'lodash/isString';
import React from 'react';
import { getFileFormat } from 'src/utils/file';

import { varFade } from '../animate';
import FileThumbnail from '../FileThumbnail';
import Iconify from '../Iconify';
import Image from '../Image';

const getFileData = (file) => {
  if (typeof file === 'string') {
    return {
      key: file,
    };
  }
  return {
    key: file.fileName,
    name: file.displayName,
  };
};

const MultiFilePreview = ({ files, onRemove, showPreview = false }) => {
  const hasFile = files.length > 0;

  return (
    <>
      <List disablePadding sx={{ ...(hasFile && { my: 3 }) }}>
        <AnimatePresence>
          {files.map((file) => {
            const { key, name, preview } = getFileData(file);
            const fileFormat = getFileFormat(file.displayName);

            if (showPreview) {
              return (
                <ListItem
                  key={key}
                  component={m.div}
                  {...varFade().inRight}
                  sx={{
                    border: (theme) => `solid 1px ${theme.palette.divider}`,
                    borderRadius: 1.25,
                    display: 'inline-flex',
                    height: 80,
                    m: 0.5,
                    overflow: 'hidden',
                    p: 0,
                    position: 'relative',
                    width: 80,
                  }}
                >
                  <Image alt="preview" ratio="1/1" src={isString(file) ? file : preview} />
                  <IconButton
                    onClick={() => onRemove(file)}
                    size="small"
                    sx={{
                      '&:hover': {
                        bgcolor: (theme) => alpha(theme.palette.grey[900], 0.48),
                      },
                      bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
                      color: 'common.white',
                      p: '2px',
                      position: 'absolute',
                      right: 6,
                      top: 6,
                    }}
                  >
                    <Iconify icon="eva:close-fill" />
                  </IconButton>
                </ListItem>
              );
            }

            return (
              <ListItem
                key={key}
                component={m.div}
                {...varFade().inRight}
                sx={{
                  '&:hover': {
                    borderColor: 'secondary.main',
                  },
                  backgroundColor: 'background.paper',
                  border: (theme) => `solid 1px ${theme.palette.divider}`,
                  borderRadius: 0.5,
                  my: 1,
                  px: 2,
                  py: 0.75,
                }}
              >
                <FileThumbnail format={fileFormat} sx={{ marginRight: 1 }} />

                <ListItemText
                  primary={isString(file) ? file : name}
                  primaryTypographyProps={{ variant: 'caption' }}
                  secondaryTypographyProps={{ variant: 'caption' }}
                />
                <Stack alignItems="center" direction="row">
                  <IconButton onClick={() => onRemove(file)} size="small">
                    <Iconify
                      icon="eva:trash-2-fill"
                      sx={{
                        '&:hover': {
                          color: 'primary.main',
                        },
                      }}
                    />
                  </IconButton>
                </Stack>
              </ListItem>
            );
          })}
        </AnimatePresence>
      </List>
    </>
  );
};

export default MultiFilePreview;
