import { useTheme } from '@mui/material/styles';

import useResponsive from '../hooks/useResponsive';

const remToPx = (value) => Math.round(parseFloat(value) * 16);

const pxToRem = (value) => `${value / 16}rem`;

const responsiveFontSizes = ({ lg, md, sm }) => ({
  '@media (min-width:1200px)': {
    fontSize: pxToRem(lg),
  },
  '@media (min-width:600px)': {
    fontSize: pxToRem(sm),
  },
  '@media (min-width:900px)': {
    fontSize: pxToRem(md),
  },
});

const useWidth = () => {
  const theme = useTheme();
  const keys = [...theme.breakpoints.keys].reverse();
  return (
    keys.reduce((output, key) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const matches = useResponsive('up', key);
      return !output && matches ? key : output;
    }, null) || 'xs'
  );
};

const GetFontValue = (variant) => {
  const theme = useTheme();
  const breakpoints = useWidth();

  const key = theme.breakpoints.up(breakpoints === 'xl' ? 'lg' : breakpoints);

  const hasResponsive =
    variant === 'h1' ||
    variant === 'h2' ||
    variant === 'h3' ||
    variant === 'h4' ||
    variant === 'h5' ||
    variant === 'h6';

  const getFont =
    hasResponsive && theme.typography[variant][key]
      ? theme.typography[variant][key]
      : theme.typography[variant];

  const fontSize = remToPx(getFont.fontSize);
  const lineHeight = Number(theme.typography[variant].lineHeight) * fontSize;
  const { fontWeight } = theme.typography[variant];
  const { letterSpacing } = theme.typography[variant];

  return { fontSize, fontWeight, letterSpacing, lineHeight };
};

export { remToPx, pxToRem, responsiveFontSizes };
export default GetFontValue;
