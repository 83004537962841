import { CssBaseline } from '@mui/material';
import {
  ThemeProvider as MUIThemeProvider,
  StyledEngineProvider,
  createTheme,
} from '@mui/material/styles';
import React, { useMemo } from 'react';

import useSettings from '../hooks/useSettings';
import breakpoints from './breakpoints';
import componentsOverride from './overrides';
import palette from './palette';
import shadows, { customShadows } from './shadows';
import typography from './typography';

const ThemeProvider = ({ children }) => {
  const { themeDirection, themeMode } = useSettings();
  const isLight = themeMode === 'light';

  const themeOptions = useMemo(
    () => ({
      breakpoints,
      customShadows: isLight ? customShadows.light : customShadows.dark,
      direction: themeDirection,
      palette: isLight ? palette.light : palette.dark,
      shadows: isLight ? shadows.light : shadows.dark,
      shape: { borderRadius: 24, borderRadiusLarge: 32 },
      typography,
    }),
    [isLight, themeDirection]
  );

  const theme = createTheme(themeOptions);
  theme.components = componentsOverride(theme);

  return (
    <StyledEngineProvider injectFirst>
      <MUIThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </MUIThemeProvider>
    </StyledEngineProvider>
  );
};

export default ThemeProvider;
