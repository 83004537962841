import { LinearProgress, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';

import Logo from './Logo';
import ProgressBar from './ProgressBar';

const RootStyle = styled('div')(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: theme.palette.background.default,
  bottom: 0,
  display: 'flex',
  height: '100%',
  justifyContent: 'center',
  position: 'fixed',
  right: 0,
  width: '100%',
  zIndex: 99999,
}));

const LoadingScreen = ({ isDashboard, ...other }) => (
  <>
    <ProgressBar />

    {!isDashboard && (
      <RootStyle {...other}>
        <Stack alignItems="center" spacing={3}>
          <Logo disabledLink logoOnly sx={{ height: 48 }} />
          <LinearProgress
            color="primary"
            sx={{
              height: 3,
              width: 300,
            }}
          />
        </Stack>
      </RootStyle>
    )}
  </>
);

export default LoadingScreen;
