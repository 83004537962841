import { Stack, Typography } from '@mui/material';
import React from 'react';
import useLocales from 'src/hooks/useLocales';

const NotificationsEmptyLists = () => {
  const { translate } = useLocales();
  return (
    <Stack px={2.5} py={2}>
      <Typography align="center" variant="subtitle2">
        {translate('layouts.dashboardHeaderNotificationsNotificationsEmptyListsTitle')}
      </Typography>
      <Typography align="center" color="text.secondary" variant="caption">
        {translate('layouts.dashboardHeaderNotificationsNotificationsEmptyListsSubtitle')}
      </Typography>
    </Stack>
  );
};

export default NotificationsEmptyLists;
