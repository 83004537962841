import React, { lazy } from 'react';
import Loadable from 'src/components/Loadable';
import USER_ROLES from 'src/constants/userRoles';
import AuthGuard from 'src/guards/AuthGuard';
import RoleBasedGuard from 'src/guards/RoleBasedGuard';
import DashboardLayout from 'src/layouts/dashboard';

// Routing page
const RoutingPage = Loadable(lazy(() => import('../../pages/Routing')));
// General
const GeneralOffers = Loadable(lazy(() => import('../../pages/dashboard/users/offers/Offers')));
const RequestOfferPage = Loadable(
  lazy(() => import('../../pages/dashboard/users/offers/steps/RequestOfferPage'))
);
const AnalysisOfferPage = Loadable(
  lazy(() => import('../../pages/dashboard/users/offers/steps/AnalysisOfferPage'))
);
const ProductsOfferPage = Loadable(
  lazy(() => import('../../pages/dashboard/users/offers/steps/ProductsOfferPage'))
);
const KYBOfferPage = Loadable(
  lazy(() => import('../../pages/dashboard/users/offers/steps/KYBOfferPage'))
);
const ContractOfferPage = Loadable(
  lazy(() => import('../../pages/dashboard/users/offers/steps/ContractOfferPage'))
);
const RefusedOfferPage = Loadable(
  lazy(() => import('../../pages/dashboard/users/offers/steps/RefusedOfferPage'))
);

const GeneralHome = Loadable(lazy(() => import('../../pages/dashboard/general/Home')));
const GeneralBlog = Loadable(lazy(() => import('../../pages/dashboard/general/Blog')));
const GeneralInvoices = Loadable(lazy(() => import('../../pages/dashboard/users/Invoices')));

// Administration
const AdministrationEmails = Loadable(
  lazy(() => import('../../pages/dashboard/administration/Emails'))
);
const AdministrationCompanies = Loadable(
  lazy(() => import('../../pages/dashboard/administration/companies/Companies'))
);
const AdministrationCompanyDetails = Loadable(
  lazy(() => import('../../pages/dashboard/administration/companies/CompanyDetails'))
);
const AdministrationAdminUsers = Loadable(
  lazy(() => import('../../pages/dashboard/administration/Administrators'))
);
const AdministrationBusinessAreas = Loadable(
  lazy(() => import('../../pages/dashboard/administration/BusinessAreas'))
);
const AdministrationChangelogs = Loadable(
  lazy(() => import('../../pages/dashboard/administration/Changelogs'))
);
const AdministrationMetricsCohorts = Loadable(
  lazy(() => import('../../pages/dashboard/administration/metrics/Cohorts'))
);
const AdministrationPendingInvoices = Loadable(
  lazy(() => import('../../pages/dashboard/administration/invoices/PendingInvoices'))
);
const AdministrationBatchsOverview = Loadable(
  lazy(() => import('../../pages/dashboard/administration/batchs/BatchsOverview'))
);
const AdministrationMetricsCreditBook = Loadable(
  lazy(() => import('../../pages/dashboard/administration/metrics/CreditBook'))
);
const AdministrationMetricsCreditEvolution = Loadable(
  lazy(() => import('../../pages/dashboard/administration/metrics/CreditEvolution'))
);
const AdministrationMetricsCommercialSupervision = Loadable(
  lazy(() => import('../../pages/dashboard/administration/metrics/CommercialSupervision'))
);
const AdministrationMetricsCreditUnpaid = Loadable(
  lazy(() => import('../../pages/dashboard/administration/metrics/CreditUnpaid'))
);
const AdministrationMetricsCreditDefault = Loadable(
  lazy(() => import('../../pages/dashboard/administration/metrics/CreditDefault'))
);
const AdministrationMetricsCreditHealthCenter = Loadable(
  lazy(() => import('../../pages/dashboard/administration/metrics/CreditHealthCenter'))
);
const AdministrationFinancing = Loadable(
  lazy(() => import('../../pages/dashboard/administration/financing/Financing'))
);
const AdministrationFinancingDetails = Loadable(
  lazy(() => import('../../pages/dashboard/administration/financing/FinancingDetails'))
);
const AdministrationScoringDetails = Loadable(
  lazy(() => import('../../pages/dashboard/administration/scoring/ScoringDetails'))
);
const AdministrationAmortizationsOverview = Loadable(
  lazy(() => import('../../pages/dashboard/administration/amortizations/AmortizationsOverview'))
);
const AdministrationMaintenance = Loadable(
  lazy(() => import('../../pages/dashboard/administration/Maintenance'))
);

const AdministrationSimulations = Loadable(
  lazy(() => import('../../pages/dashboard/administration/Simulations'))
);
const BankRatesChange = Loadable(
  lazy(() => import('../../pages/dashboard/administration/bankRatesChange/BankRatesChange'))
);
const AdministrationOffersPage = Loadable(
  lazy(() => import('../../pages/dashboard/administration/Offers'))
);

const dashboardRoutes = [
  {
    children: [
      {
        element: <RoutingPage />,
        path: 'redirect',
      },
      {
        children: [
          {
            element: (
              <RoleBasedGuard accessibleRoles={USER_ROLES.COMPANY}>
                <GeneralOffers />
              </RoleBasedGuard>
            ),
            index: true,
          },
          {
            element: (
              <RoleBasedGuard accessibleRoles={USER_ROLES.COMPANY}>
                <RequestOfferPage />
              </RoleBasedGuard>
            ),
            path: 'request',
          },
          {
            element: (
              <RoleBasedGuard accessibleRoles={USER_ROLES.COMPANY}>
                <AnalysisOfferPage />
              </RoleBasedGuard>
            ),
            path: 'analysis',
          },
          {
            element: (
              <RoleBasedGuard accessibleRoles={USER_ROLES.COMPANY}>
                <ProductsOfferPage />
              </RoleBasedGuard>
            ),
            path: 'products',
          },
          {
            element: (
              <RoleBasedGuard accessibleRoles={USER_ROLES.COMPANY}>
                <KYBOfferPage />
              </RoleBasedGuard>
            ),
            path: 'kyb',
          },
          {
            element: (
              <RoleBasedGuard accessibleRoles={USER_ROLES.COMPANY}>
                <ContractOfferPage />
              </RoleBasedGuard>
            ),
            path: 'contract',
          },
          {
            element: (
              <RoleBasedGuard accessibleRoles={USER_ROLES.COMPANY}>
                <RefusedOfferPage />
              </RoleBasedGuard>
            ),
            path: 'refused',
          },
        ],
        path: 'offers',
      },
      {
        element: (
          <RoleBasedGuard accessibleRoles={USER_ROLES.COMPANY}>
            <GeneralHome />
          </RoleBasedGuard>
        ),
        path: 'home',
      },
      {
        element: (
          <RoleBasedGuard accessibleRoles={USER_ROLES.COMPANY}>
            <GeneralBlog />
          </RoleBasedGuard>
        ),
        path: 'blog',
      },
      {
        element: (
          <RoleBasedGuard accessibleRoles={USER_ROLES.COMPANY}>
            <GeneralInvoices />
          </RoleBasedGuard>
        ),
        path: 'invoices',
      },
      {
        children: [
          {
            element: (
              <RoleBasedGuard accessibleRoles={[USER_ROLES.SUPER_ADMIN, USER_ROLES.ADMIN]}>
                <AdministrationPendingInvoices />
              </RoleBasedGuard>
            ),
            path: 'pending',
          },
          {
            element: (
              <RoleBasedGuard accessibleRoles={[USER_ROLES.SUPER_ADMIN, USER_ROLES.ADMIN]}>
                <AdministrationBatchsOverview />
              </RoleBasedGuard>
            ),
            path: 'overview',
          },
        ],
        path: 'invoices',
      },
      {
        children: [
          {
            element: (
              <RoleBasedGuard accessibleRoles={[USER_ROLES.SUPER_ADMIN, USER_ROLES.ADMIN]}>
                <AdministrationMetricsCohorts />
              </RoleBasedGuard>
            ),
            path: 'cohorts',
          },
          {
            element: (
              <RoleBasedGuard accessibleRoles={[USER_ROLES.SUPER_ADMIN, USER_ROLES.ADMIN]}>
                <AdministrationMetricsCreditBook />
              </RoleBasedGuard>
            ),
            path: 'credit-book',
          },
          {
            element: (
              <RoleBasedGuard accessibleRoles={[USER_ROLES.SUPER_ADMIN, USER_ROLES.ADMIN]}>
                <AdministrationMetricsCreditEvolution />
              </RoleBasedGuard>
            ),
            path: 'credit-evolution',
          },
          {
            element: (
              <RoleBasedGuard accessibleRoles={[USER_ROLES.SUPER_ADMIN, USER_ROLES.ADMIN]}>
                <AdministrationMetricsCommercialSupervision />
              </RoleBasedGuard>
            ),
            path: 'commercial-supervision',
          },
          {
            element: (
              <RoleBasedGuard accessibleRoles={[USER_ROLES.SUPER_ADMIN, USER_ROLES.ADMIN]}>
                <AdministrationMetricsCreditDefault />
              </RoleBasedGuard>
            ),
            path: 'credit-default',
          },
          {
            element: (
              <RoleBasedGuard accessibleRoles={[USER_ROLES.SUPER_ADMIN, USER_ROLES.ADMIN]}>
                <AdministrationMetricsCreditHealthCenter />
              </RoleBasedGuard>
            ),
            path: 'credit-health-center',
          },
          {
            element: (
              <RoleBasedGuard accessibleRoles={[USER_ROLES.SUPER_ADMIN, USER_ROLES.ADMIN]}>
                <AdministrationMetricsCreditUnpaid />
              </RoleBasedGuard>
            ),
            path: 'credit-unpaid',
          },
        ],
        path: 'metrics',
      },
      {
        element: (
          <RoleBasedGuard accessibleRoles={[USER_ROLES.SUPER_ADMIN, USER_ROLES.ADMIN]}>
            <AdministrationBusinessAreas />
          </RoleBasedGuard>
        ),
        path: 'business-areas',
      },
      {
        element: (
          <RoleBasedGuard accessibleRoles={USER_ROLES.SUPER_ADMIN}>
            <AdministrationMaintenance />
          </RoleBasedGuard>
        ),
        path: 'maintenance',
      },
      {
        element: (
          <RoleBasedGuard accessibleRoles={[USER_ROLES.SUPER_ADMIN, USER_ROLES.ADMIN]}>
            <AdministrationChangelogs />
          </RoleBasedGuard>
        ),
        path: 'changelogs',
      },
      {
        element: (
          <RoleBasedGuard accessibleRoles={[USER_ROLES.SUPER_ADMIN, USER_ROLES.ADMIN]}>
            <AdministrationEmails />
          </RoleBasedGuard>
        ),
        path: 'emails',
      },
      {
        children: [
          {
            element: (
              <RoleBasedGuard accessibleRoles={[USER_ROLES.SUPER_ADMIN, USER_ROLES.ADMIN]}>
                <AdministrationCompanies />
              </RoleBasedGuard>
            ),
            path: '',
          },
          {
            element: (
              <RoleBasedGuard accessibleRoles={[USER_ROLES.SUPER_ADMIN, USER_ROLES.ADMIN]}>
                <AdministrationCompanyDetails />
              </RoleBasedGuard>
            ),
            path: ':id',
          },
        ],
        path: 'companies',
      },
      {
        children: [
          {
            element: (
              <RoleBasedGuard accessibleRoles={[USER_ROLES.SUPER_ADMIN, USER_ROLES.ADMIN]}>
                <AdministrationFinancing />
              </RoleBasedGuard>
            ),
            path: '',
          },
          {
            element: (
              <RoleBasedGuard
                accessibleRoles={[USER_ROLES.SUPER_ADMIN, USER_ROLES.ADMIN, USER_ROLES.COMPANY]}
              >
                <AdministrationFinancingDetails />
              </RoleBasedGuard>
            ),
            path: ':id',
          },
        ],
        path: 'financing',
      },
      {
        children: [
          {
            element: (
              <RoleBasedGuard accessibleRoles={[USER_ROLES.SUPER_ADMIN, USER_ROLES.ADMIN]}>
                <AdministrationScoringDetails />
              </RoleBasedGuard>
            ),
            path: ':id',
          },
        ],
        path: 'scoring',
      },
      {
        element: (
          <RoleBasedGuard accessibleRoles={[USER_ROLES.SUPER_ADMIN, USER_ROLES.ADMIN]}>
            <AdministrationAmortizationsOverview />
          </RoleBasedGuard>
        ),
        path: 'amortizations-withdrawals-overview',
      },
      {
        children: [
          {
            element: (
              <RoleBasedGuard accessibleRoles={[USER_ROLES.SUPER_ADMIN, USER_ROLES.ADMIN]}>
                <AdministrationOffersPage />
              </RoleBasedGuard>
            ),
            index: true,
            path: '',
          },
          {
            element: (
              <RoleBasedGuard accessibleRoles={[USER_ROLES.SUPER_ADMIN, USER_ROLES.ADMIN]}>
                <AdministrationOffersPage />
              </RoleBasedGuard>
            ),
            path: ':id',
          },
        ],
        path: 'product-offers',
      },
      {
        element: (
          <RoleBasedGuard accessibleRoles={[USER_ROLES.SUPER_ADMIN, USER_ROLES.ADMIN]}>
            <AdministrationAdminUsers />
          </RoleBasedGuard>
        ),
        path: 'administrators',
      },
      {
        element: (
          <RoleBasedGuard accessibleRoles={[USER_ROLES.SUPER_ADMIN]}>
            <BankRatesChange />
          </RoleBasedGuard>
        ),
        path: 'bank-rates-change',
      },
      {
        element: (
          <RoleBasedGuard accessibleRoles={[USER_ROLES.SUPER_ADMIN, USER_ROLES.ADMIN]}>
            <AdministrationSimulations />
          </RoleBasedGuard>
        ),
        path: 'simulations',
      },
    ],
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    path: '/',
  },
];

export default dashboardRoutes;
