import { gql } from '@apollo/client';

const userFragment = gql`
  fragment User on User {
    id
    firstname
    lastname
    email
    emailVerified
    isSuspended
    role
    hubspotId
    displayName
    position
    lastFollowUpDate
    passwordCreatedAt
    createdByAdmin
    createdAt
    updatedAt
    phone
  }
`;

export default userFragment;
