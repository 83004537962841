import { Divider, Link, Stack, Typography } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import moment from 'moment';
import React from 'react';
import { NAVBAR } from 'src/config';
import config from 'src/config/index';
import CONTACT_EMAILS from 'src/constants/contactEmails';
import useLocales from 'src/hooks/useLocales';
import useResponsive from 'src/hooks/useResponsive';
import SocialsButton from 'src/layouts/dashboard/footer/SocialsButton';
import cssStyles from 'src/utils/cssStyles';
import generateMailTo from 'src/utils/generateMailTo';

const { landingUrl } = config;

const useLinks = () => {
  const { translate } = useLocales();

  return [
    {
      headline: translate('layouts.dashboardFooterUnlimitd'),
      items: [
        {
          href: `${landingUrl}`,
          name: translate('layouts.dashboardFooterOffer'),
        },
        { href: `${landingUrl}/blog`, name: translate('layouts.dashboardFooterBlog') },
        {
          href: `${landingUrl}/qui-sommes-nous`,
          name: translate('layouts.dashboardFooterAboutUs'),
        },
      ],
    },
    {
      headline: translate('layouts.dashboardFooterRessources'),
      items: [
        {
          // cspell-checker: disable-next-line
          href: `${landingUrl}/mentions-legales`,
          name: translate('layouts.dashboardFooterLegalInformation'),
        },
        {
          // cspell-checker: disable-next-line
          href: `${landingUrl}/politique-de-confidentialites`,
          name: translate('layouts.dashboardFooterPrivacyPolicy'),
        },
        {
          // cspell-checker: disable-next-line
          href: `${landingUrl}/conditions-generales-dutilisation`,
          name: translate('layouts.dashboardFooterTermsAndConditionsOfUse'),
        },
      ],
    },
    {
      headline: translate('layouts.dashboardFooterContact'),
      items: [
        {
          href: generateMailTo({
            email: CONTACT_EMAILS.CONTACT,
            subject: translate('layouts.dashboardFooterContactEmailSubject'),
          }),
          name: translate('layouts.dashboardFooterContactEmail'),
        },
        {
          href: 'https://www.google.com/maps/place/165+Av.+de+Bretagne,+59000+Lille/@50.6340123,3.0185842,17z/data=!3m1!4b1!4m5!3m4!1s0x47c2d5fb4c33e7f7:0xdfa7ca5a36aea19c!8m2!3d50.6340123!4d3.0207782',
          name: translate('layouts.dashboardFooterAddress'),
        },
      ],
    },
  ];
};

const Footer = ({ isCollapse }) => {
  const { translate } = useLocales();
  const isMobile = useResponsive('down', 'lg');
  const theme = useTheme();

  const links = useLinks();
  const withMarginLeft = isCollapse
    ? `${NAVBAR.DASHBOARD_COLLAPSE_WIDTH}px`
    : `${NAVBAR.DASHBOARD_WIDTH}px`;

  const currentYear = moment().year();

  const RootStyle = styled('div')(() => ({
    ...cssStyles(theme).bgBlur({
      blur: 10,
      opacity: 0.1,
    }),
  }));

  return (
    <RootStyle
      sx={{
        marginLeft: {
          lg: withMarginLeft,
        },
      }}
    >
      <Divider />
      <Stack
        alignItems={isMobile && 'center'}
        direction={isMobile ? 'column' : 'row'}
        justifyContent="center"
        my={12}
        spacing={{ md: 12, xs: 8 }}
      >
        {links.map((list) => (
          <Stack key={list.headline} spacing={2}>
            <Typography textAlign={isMobile && 'center'} variant="overline">
              {list.headline}
            </Typography>
            {list.items.map((link) => (
              <Link
                key={link.name}
                color="inherit"
                href={link.href}
                target="_blank"
                textAlign={isMobile && 'center'}
                variant="body2"
              >
                {link.name}
              </Link>
            ))}
          </Stack>
        ))}
      </Stack>
      <Stack spacing={1} sx={{ pb: 2 }}>
        {isMobile && <SocialsButton />}
        <Typography align="center" color="text.secondary" variant="caption">
          {translate('layouts.dashboardFooterRights', { currentYear })}
        </Typography>
      </Stack>
    </RootStyle>
  );
};

export default Footer;
