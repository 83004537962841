import SPECIAL_CHARACTERS from './specialCharacters';

const PHONE_REGEX = /^\+(?:[0-9] ?){10}[0-9]$/;

const WEBSITE_URL_REGEX =
  /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+\.)?[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(\/.*)?$/;

const LINKED_IN_URL_REGEX = /^https:\/\/www\.linkedin\.com\/in\/[a-zA-Z0-9-]+\/$/;

const FILE_EXTENSION_REGEX = /\/[^/?]+\.[^/]+$/;

const LOCAL_IP_REGEX = /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/;

const AT_LEAST_ONE_UPPER_CASE_REGEX = /(?=.*?[A-Z])/;
const AT_LEAST_ONE_LOWER_CASE_REGEX = /(?=.*?[a-z])/;
const ONLY_LETTERS = /(?=.*?[A-Za-z])/;
const AT_LEAST_ONE_DIGIT_REGEX = /(?=.*?[0-9])/;
const AT_LEAST_ONE_SPECIAL_CHARACTER_REGEX = new RegExp(`[${SPECIAL_CHARACTERS.join('')}]`);

export {
  PHONE_REGEX,
  ONLY_LETTERS,
  LINKED_IN_URL_REGEX,
  WEBSITE_URL_REGEX,
  FILE_EXTENSION_REGEX,
  LOCAL_IP_REGEX,
  AT_LEAST_ONE_UPPER_CASE_REGEX,
  AT_LEAST_ONE_LOWER_CASE_REGEX,
  AT_LEAST_ONE_DIGIT_REGEX,
  AT_LEAST_ONE_SPECIAL_CHARACTER_REGEX,
};
