import { Stack, Typography } from '@mui/material';
import React from 'react';

import Actions from './Actions';

const AdminActions = ({ company, translate }) => (
  <Stack>
    <Typography sx={{ color: 'error.main', fontWeight: 'bold' }} variant="body1">
      {translate('sections.dashboardAdministrationCompaniesAdminActionsTitle')}
    </Typography>

    <Stack direction="row">
      <Typography
        color="text.secondary"
        sx={{ fontStyle: 'italic', fontWeight: 'bold' }}
        variant="body2"
      >
        {translate('sections.dashboardAdministrationCompaniesAdminActionsSubtitle')}
      </Typography>
      &nbsp;
      <Typography sx={{ fontStyle: 'italic', fontWeight: 'bold' }} variant="body2">
        {translate('commons.irreversible')}
      </Typography>
    </Stack>
    <Stack mt={1}>
      <Actions actionContext="admin" company={company} />
    </Stack>
  </Stack>
);

export default AdminActions;
