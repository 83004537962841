import OFFER_STATUSES from 'src/constants/offerStatuses';

import OFFER_STEPS from '../../../../../pages/dashboard/users/offers/offerSteps';

const getDefaultStep = (activeOffers, isKYBDone) => {
  if (!activeOffers?.length) return OFFER_STEPS.REQUEST;

  // If all offers are in refused state, display the refuse screen
  if (activeOffers.every(({ status }) => status === OFFER_STATUSES.REFUSED))
    return OFFER_STEPS.REFUSED;

  const notRefusedOffers = activeOffers.filter(({ status }) => status !== OFFER_STATUSES.REFUSED);

  // If there at least one offer in analysis state, so render the analysis step
  if (
    activeOffers.some(({ status }) =>
      [OFFER_STATUSES.REQUEST, OFFER_STATUSES.DRAFT].includes(status)
    )
  )
    return OFFER_STEPS.ANALYSIS;

  // If there is at least one offer in a published state, display this step by default
  if (activeOffers.some(({ status }) => status === OFFER_STATUSES.PUBLISHED))
    return OFFER_STEPS.PRODUCTS;

  // If all none refused offers are accepted, display the KYB or contract step, depends on KYB state
  if (notRefusedOffers.every(({ status }) => status === OFFER_STATUSES.ACCEPTED) && !isKYBDone)
    return OFFER_STEPS.KYB;

  return OFFER_STEPS.CONTRACT;
};

export default getDefaultStep;
