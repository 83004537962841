import { useTheme } from '@emotion/react';
import { Alert, Stack, Tooltip } from '@mui/material';
import React from 'react';
// eslint-disable-next-line import/no-cycle
import CompanyAvatar from 'src/components/CompanyAvatar';
import Iconify from 'src/components/Iconify';
import useFormatDateTime from 'src/hooks/useFormatDateTime';
import useLocales from 'src/hooks/useLocales';

const computeOnBoardingIconProps = ({ isOnBoardingDone, theme }) => {
  const color = isOnBoardingDone ? theme.palette.success.main : theme.palette.warning.main;
  const icon = isOnBoardingDone ? 'mdi:check-circle-outline' : 'mdi:clock-outline';

  return { color, icon };
};

const CompanyStatusIcons = ({ company, fDate, theme, translate }) => (
  <Stack alignItems="center" direction="row" spacing={1}>
    {company.isInDefault && (
      <Tooltip
        placement="top"
        title={translate('components.companyAvatarDefaultTooltipTitle', {
          date: fDate(company.hasDefaultedAt),
        })}
      >
        <div>
          <Iconify color="error.main" icon="ri:spam-2-fill" />
        </div>
      </Tooltip>
    )}
    <Tooltip
      title={
        company.isOnBoardingDone
          ? translate('components.companyAvatarIsOnboardingDoneTooltipTitle')
          : translate('components.companyAvatarIsOnboardingNotDoneTooltipTitle')
      }
    >
      <div>
        <Iconify
          {...computeOnBoardingIconProps({ isOnBoardingDone: company.isOnBoardingDone, theme })}
        />
      </div>
    </Tooltip>
    {company.isUnderSurveillance && (
      <Tooltip
        placement="top"
        title={translate('components.companyAvatarMonitoringTooltipTitle', {
          date: fDate(company.startSurveillanceAt),
        })}
      >
        <div>
          <Iconify color="success.main" icon="mdi:bell-ring" />
        </div>
      </Tooltip>
    )}
  </Stack>
);

const CompanyHeader = ({ company }) => {
  const { translate } = useLocales();
  const { fDate } = useFormatDateTime();

  const theme = useTheme();

  const { hasDefaultedAt, isInDefault } = company;

  return (
    <Stack p={3} spacing={2}>
      <Stack alignItems="center" direction="row" justifyContent="space-between">
        <Stack direction="row">
          <CompanyAvatar
            company={company}
            isDrawerContext
            withBusinessArea
            withBusinessName
            withLink
            withMainProduct
          />
        </Stack>
        <CompanyStatusIcons company={company} fDate={fDate} theme={theme} translate={translate} />
      </Stack>
      {isInDefault && (
        <Alert severity="error">
          {translate('sections.dashboardAdministrationCompaniesInfosCompanyDetailsAlertLabel', {
            date: fDate(hasDefaultedAt),
          })}
        </Alert>
      )}
    </Stack>
  );
};

export default CompanyHeader;
