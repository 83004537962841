import { alpha, styled } from '@mui/material/styles';
import React from 'react';

const computeSize = ({ size, theme }) => {
  switch (size) {
    case 's':
      return {
        fontSize: theme.typography.pxToRem(10),
        height: 16,
        minWidth: 16,
      };
    default:
    case 'm':
      return {
        fontSize: theme.typography.pxToRem(12),
        height: 22,
        minWidth: 22,
      };
    case 'l':
      return {
        fontSize: theme.typography.pxToRem(14),
        height: 28,
        minWidth: 28,
      };
  }
};

const RootStyle = styled('span')(({ ownerState, size, theme }) => {
  const isLight = theme.palette.mode === 'light';
  const { color, variant } = ownerState;
  const computedSize = computeSize({ size, theme });

  const styleFilled = (colorFill) => ({
    backgroundColor: theme.palette[colorFill].main,
    color: theme.palette[colorFill].contrastText,
  });

  const styleOutlined = (colorOutline) => ({
    backgroundColor: 'transparent',
    border: `1px solid ${theme.palette[colorOutline].main}`,
    color: theme.palette[colorOutline].main,
  });

  const styleGhost = (colorGhost) => ({
    backgroundColor: alpha(theme.palette[colorGhost].main, 0.16),
    color: theme.palette[colorGhost][isLight ? 'dark' : 'light'],
  });

  return {
    alignItems: 'center',
    backgroundColor: theme.palette.grey[300],
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.grey[800],
    cursor: 'default',
    display: 'inline-flex',
    fontFamily: theme.typography.fontFamily,
    justifyContent: 'center',
    lineHeight: 0,
    padding: theme.spacing(0, 1),
    whiteSpace: 'nowrap',
    ...computedSize,

    ...(color !== 'default'
      ? {
          ...(variant === 'filled' && { ...styleFilled(color) }),
          ...(variant === 'outlined' && { ...styleOutlined(color) }),
          ...(variant === 'ghost' && { ...styleGhost(color) }),
        }
      : {
          ...(variant === 'outlined' && {
            backgroundColor: 'transparent',
            border: `1px solid ${theme.palette.grey[500_32]}`,
            color: theme.palette.text.primary,
          }),
          ...(variant === 'ghost' && {
            backgroundColor: theme.palette.grey[500_16],
            color: isLight ? theme.palette.text.secondary : theme.palette.common.white,
          }),
        }),
  };
});

const Label = ({ children, color = 'default', size, variant = 'ghost', ...other }) => (
  <RootStyle ownerState={{ color, variant }} size={size} {...other}>
    {children}
  </RootStyle>
);

Label.defaultProps = {
  size: 'm',
};

export default Label;
