import { Alert, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import Iconify from 'src/components/Iconify';
import GenericTableList from 'src/components/table/GenericTableList';
import USER_ROLES from 'src/constants/userRoles';
import { GET_HUBSPOT_DEALS } from 'src/graphql/financing/queries';
import FinancingFormDialog from 'src/sections/@dashboard/administration/financing/form/create/FinancingFormDialog.js';
import { useSafeQuery } from 'src/services/apollo-client/wrappers';

import HubspotDealsListTableRow from './HubspotDealsListTableRow';
import HubspotDealsTableHeadRow from './HubspotDealsTableHeadRow';

const HubspotDeals = ({ company, translate }) => {
  const [selectedDeal, setSelectedDeal] = useState(null);

  const { data, loading } = useSafeQuery(GET_HUBSPOT_DEALS, {
    variables: {
      companyId: company.id,
    },
  });

  if (!data) return null;

  const hasMandate = Boolean(company.metadata?.mandateUrl);

  const deals = data.getDealsByCompanyId || [];

  const hoverActions = [
    {
      action: (deal) => {
        setSelectedDeal(deal);
      },
      condition: hasMandate,
      icon: <Iconify icon="eva:edit-outline" size="s" />,
      roles: [USER_ROLES.SUPER_ADMIN, USER_ROLES.ADMIN],
      tooltipLabel: 'Intégrer ce financement',
    },
  ];

  const tableListProps = {
    hoverActions,
    isLoading: loading,
    isNestedTable: true,
    items: deals,
    renderHeadRow: () => <HubspotDealsTableHeadRow />,
    renderRow: (deal) => <HubspotDealsListTableRow key={deal.id} company={company} deal={deal} />,
    totalItems: deals.length || 0,
  };

  return (
    <>
      <Stack mb={2} pl={3}>
        <Typography sx={{ fontWeight: 'bold' }} variant="body1">
          {translate('sections.sharedFinancingFinancingCloseWonDealsCardHeaderLabel')}
        </Typography>
        <Typography
          color="text.secondary"
          sx={{ fontStyle: 'italic', fontWeight: 'bold' }}
          variant="body2"
        >
          {translate('sections.sharedFinancingFinancingCloseWonDealsCardHeaderSubtitle')}
        </Typography>
        {!hasMandate && (
          <Alert severity="warning" sx={{ marginRight: 3, marginTop: 2 }}>
            {translate('sections.sharedFinancingFinancingCloseWonDealsCardHeaderAlert')}
          </Alert>
        )}
      </Stack>
      <GenericTableList {...tableListProps} />
      {selectedDeal && (
        <FinancingFormDialog
          companyId={company.id}
          hubspotData={selectedDeal}
          onClose={() => setSelectedDeal(null)}
        />
      )}
    </>
  );
};

export default HubspotDeals;
