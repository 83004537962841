import { MenuItem, Stack } from '@mui/material';
import React, { useState } from 'react';
import { IconButtonAnimate } from 'src/components/animate';
import CountryFlag from 'src/components/CountryFlag';
import MenuPopover from 'src/components/MenuPopover';
import useLocales from 'src/hooks/useLocales';

const LanguagePopover = () => {
  const { allLang, currentLang, onChangeLang } = useLocales();

  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  return (
    <>
      <IconButtonAnimate
        onClick={handleOpen}
        sx={{
          height: 40,
          width: 40,
          ...(open && { bgcolor: 'action.selected' }),
        }}
      >
        <CountryFlag currentLang={currentLang} />
      </IconButtonAnimate>

      <MenuPopover
        anchorEl={open}
        onClose={handleClose}
        open={Boolean(open)}
        sx={{
          '& .MuiMenuItem-root': { borderRadius: 0.75, px: 1, typography: 'body2' },
          ml: 0.75,
          mt: 1.5,
          width: 180,
        }}
      >
        <Stack spacing={0.75}>
          {allLang
            .filter((lang) => lang.hasTranslations)
            .map((option) => (
              <MenuItem
                key={option.value}
                onClick={() => {
                  onChangeLang(option.value);
                  handleClose();
                }}
                selected={option.value === currentLang.value}
              >
                <Stack alignItems="center" direction="row" spacing={1}>
                  <CountryFlag currentLang={option} />
                  <span>{option.label}</span>
                </Stack>
              </MenuItem>
            ))}
        </Stack>
      </MenuPopover>
    </>
  );
};

export default LanguagePopover;
