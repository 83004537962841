import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { fileThumb } from 'src/utils/file';

const FileThumbnail = ({ format, sx }) => (
  <>
    <Box
      component="img"
      src={fileThumb(format)}
      sx={{
        flexShrink: 0,
        height: 32,
        width: 32,
        ...sx,
      }}
    />
  </>
);

FileThumbnail.propTypes = {
  format: PropTypes.string,
};

export default FileThumbnail;
