import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router';
import config from 'src/config/index';
import { setCookie } from 'src/utils/cookies';

const TRACKING_PARAMS = [
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_content',
  'utm_term',
  'hsa_acc',
  'hsa_cam',
  'hsa_grp',
  'hsa_ad',
  'hsa_net',
  'hsa_src',
  'hsa_ver',
  'hsa_la',
  'hsa_ol',
  'hsa_mt',
  'hsa_kw',
  'hsa_tgt',
  'gclid', // cspell-checker: disable-line
  '_gl',
  'gad',
];

const HubspotTracking = () => {
  const location = useLocation();

  useEffect(() => {
    // Do not load widget when the script loads
    window.hsConversationsSettings = { loadImmediately: false };
  }, []);

  useEffect(() => {
    // Run this effect every time location changes
    const urlParameters = new URLSearchParams(location.search);
    TRACKING_PARAMS.forEach((trackingParam) => {
      const param = urlParameters.get(trackingParam);
      if (param) {
        setCookie(trackingParam, param);
      }
    });
  }, [location]);

  return (
    <Helmet>
      <script
        async
        defer
        id="hs-script-loader"
        src={`//js-eu1.hs-scripts.com/${config.hubspot.id}.js`}
        type="text/javascript"
      />
    </Helmet>
  );
};

export default HubspotTracking;
