import React, { lazy } from 'react';
import Loadable from 'src/components/Loadable';
import AuthGuard from 'src/guards/AuthGuard';
import CompanyGuard from 'src/guards/CompanyGuard';
import GuestGuard from 'src/guards/GuestGuard';
import TokenGuard from 'src/guards/TokenGuard';
import LogoOnlyLayout from 'src/layouts/LogoOnlyLayout';
import { TOKEN_TYPES } from 'src/utils/jwt';

const WelcomePage = Loadable(lazy(() => import('../../pages/auth-v2/WelcomePage')));
const RegisterPage = Loadable(lazy(() => import('../../pages/auth-v2/RegisterPage')));
const ResetPasswordRequest = Loadable(lazy(() => import('../../pages/auth/ResetPasswordRequest')));
const ResetPassword = Loadable(lazy(() => import('../../pages/auth/ResetPassword')));
const VerifyEmailChallengePage = Loadable(
  lazy(() => import('../../pages/auth-v2/VerifyEmailChallengePage'))
);
const LoginPage = Loadable(lazy(() => import('../../pages/auth-v2/LoginPage')));
const VerifyAccountPage = Loadable(lazy(() => import('../../pages/auth-v2/VerifyAccountPage')));
const NotEligible = Loadable(lazy(() => import('../../pages/auth-v2/NotEligible')));
const RegisterOnboardingPage = Loadable(
  lazy(() => import('../../pages/auth-v2/RegisterOnboardingPage'))
);
const OnboardingDonePage = Loadable(lazy(() => import('../../pages/auth-v2/OnboardingDonePage')));

const authRoutes = [
  {
    element: (
      <GuestGuard>
        <WelcomePage />
      </GuestGuard>
    ),
    index: true,
  },
  {
    children: [
      {
        element: (
          <GuestGuard>
            <LoginPage />
          </GuestGuard>
        ),
        path: 'login',
      },
      {
        element: (
          <GuestGuard>
            <RegisterPage />
          </GuestGuard>
        ),
        path: 'register',
      },
      {
        children: [
          {
            element: (
              <AuthGuard>
                <RegisterOnboardingPage />
              </AuthGuard>
            ),
            index: true,
          },
        ],
        path: 'onboarding',
      },
      {
        element: (
          <CompanyGuard>
            <OnboardingDonePage />
          </CompanyGuard>
        ),
        path: 'onboarding-done',
      },
      {
        element: (
          <GuestGuard>
            <NotEligible />
          </GuestGuard>
        ),
        path: 'not-eligible',
      },
      {
        children: [
          {
            element: (
              <GuestGuard>
                <ResetPasswordRequest />
              </GuestGuard>
            ),
            path: 'request',
          },
          {
            element: (
              <TokenGuard requiredTokenType={TOKEN_TYPES.RESET_PASSWORD}>
                <ResetPassword />
              </TokenGuard>
            ),
            path: '',
          },
        ],
        element: <LogoOnlyLayout />,
        path: 'reset-password',
      },
      {
        children: [
          {
            element: (
              <TokenGuard requiredTokenType={TOKEN_TYPES.VERIFICATION_EMAIL}>
                <VerifyEmailChallengePage />
              </TokenGuard>
            ),
            path: '',
          },
          {
            children: [
              {
                element: <VerifyAccountPage />,
                path: 'pending',
              },
            ],
          },
        ],
        path: 'verify-email',
      },
    ],
    path: 'auth',
  },
];

export default authRoutes;
