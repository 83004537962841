import { Stack, Tab, Tabs } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import useLocales from 'src/hooks/useLocales';

import CompanyDrawerActionsTabPanel from './CompanyDrawerActionsTabPanel';
import CompanyDrawerOverViewTabPanel from './CompanyDrawerOverViewTabPanel';

const getCompanyDrawerTabs = ({ translate }) => [
  {
    Component: CompanyDrawerOverViewTabPanel,
    name: translate('sections.companyInformationDrawerOverviewLabel'),
    value: 'overview',
  },
  {
    Component: CompanyDrawerActionsTabPanel,
    name: translate('sections.companyInformationDrawerActionsLabel'),
    value: 'actions',
  },
];

const CompanyDrawerTab = ({ businessAreas, company }) => {
  const { translate } = useLocales();
  const theme = useTheme();
  const COMPANY_DRAWER_TABS = getCompanyDrawerTabs({ translate });

  const [activeTab, setActiveTab] = useState(null);

  useEffect(() => {
    if (!activeTab) {
      setActiveTab(COMPANY_DRAWER_TABS[0].value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  const handleTabChange = (e, newTab) => setActiveTab(newTab);

  const existsActiveTab = COMPANY_DRAWER_TABS.find(({ value }) => activeTab === value);

  if (!existsActiveTab) return null;

  const ActiveTabComponent = existsActiveTab.Component;
  return (
    <>
      <Stack
        sx={{
          backgroundColor: theme.palette.background.default,
          pl: 2,
        }}
      >
        <Tabs onChange={handleTabChange} value={activeTab}>
          {COMPANY_DRAWER_TABS.map(({ name, value }) => (
            <Tab key={value} disableRipple label={name} value={value} />
          ))}
        </Tabs>
      </Stack>

      <ActiveTabComponent
        businessAreas={businessAreas}
        company={company}
        theme={theme}
        translate={translate}
      />
    </>
  );
};

export default CompanyDrawerTab;
