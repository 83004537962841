import { Stack, Typography } from '@mui/material';
import React from 'react';
import Image from 'src/components/Image';

const OnboardingPageHeader = ({ headerSubtitle, headerTitle, icon }) => (
  <Stack mb={2} spacing={1}>
    {icon && (
      <Image
        src={icon}
        sx={{
          width: 100,
        }}
      />
    )}
    <Stack spacing={0}>
      {headerTitle && <Typography variant="h2">{headerTitle}</Typography>}
      {headerSubtitle && (
        <Typography color="text.secondary" variant="subtitle">
          {headerSubtitle}
        </Typography>
      )}
    </Stack>
  </Stack>
);

OnboardingPageHeader.defaultProps = {
  headerSubtitle: undefined,
  headerTitle: undefined,
  icon: null,
};

export default OnboardingPageHeader;
