import { Stack, Typography } from '@mui/material';
import React from 'react';

import Actions from './Actions';

const UserActions = ({ company, translate }) => {
  const [mainUser] = company.users;
  return (
    <Stack>
      <Typography sx={{ fontWeight: 'bold' }} variant="body1">
        {translate('sections.dashboardAdministrationCompaniesUserActionsTitle')}
      </Typography>
      <Stack direction="row">
        <Typography
          color="text.secondary"
          sx={{ fontStyle: 'italic', fontWeight: 'bold' }}
          variant="body2"
        >
          {translate('sections.dashboardAdministrationCompaniesUserActionsSubtitle')}
        </Typography>
        &nbsp;
        <Typography sx={{ fontStyle: 'italic', fontWeight: 'bold' }} variant="body2">
          {mainUser.displayName}
        </Typography>
      </Stack>
      <Stack mt={1}>
        <Actions actionContext="user" company={company} />
      </Stack>
    </Stack>
  );
};

export default UserActions;
