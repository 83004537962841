import { ListItemAvatar, ListItemButton, ListItemText, Stack, Typography } from '@mui/material';
import moment from 'moment';
import React from 'react';
import { useNavigate } from 'react-router';
import Iconify from 'src/components/Iconify';
import useFormatDateTime from 'src/hooks/useFormatDateTime';
import useLocales from 'src/hooks/useLocales';
import { PATH_DASHBOARD } from 'src/routes/paths';

const GenericNotificationItem = ({ onClose, withdrawalsCounter }) => {
  const navigate = useNavigate();
  const { fDate } = useFormatDateTime();
  const { translate } = useLocales();

  const getLabel = () =>
    withdrawalsCounter === 1
      ? 'layouts.dashboardHeaderNotificationsNotificationsWithdrawalTodoCounterLabel'
      : 'layouts.dashboardHeaderNotificationsNotificationsWithdrawalsTodoCounterLabel';

  const handleClick = () => {
    navigate(PATH_DASHBOARD.administration.withdrawals);
    onClose();
  };

  return (
    <ListItemButton onClick={handleClick} sx={{ mt: '1px', px: 2.5, py: 1.5 }}>
      <ListItemAvatar>
        <Iconify icon="eva:layers-outline" />
      </ListItemAvatar>
      <ListItemText
        primary={
          <Typography variant="subtitle2">
            {translate(getLabel(), { withdrawalsCounter })}
          </Typography>
        }
        secondary={
          <Stack spacing={1}>
            <Typography
              sx={{ alignItems: 'center', color: 'text.disabled', display: 'flex', mt: 0.5 }}
              variant="caption"
            >
              <Iconify icon="eva:clock-fill" size="xs" sx={{ mr: 0.5 }} />
              {fDate(moment())}
            </Typography>
          </Stack>
        }
      />
    </ListItemButton>
  );
};

export default GenericNotificationItem;
