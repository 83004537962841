import i18n from 'src/locales/i18n';

const expectedErrors = {
  // Sign in
  ACCOUNT_SUSPENDED: {
    code: 'ACCOUNT_SUSPENDED',
    message: i18n.t('commons.accountSuspended'),
    withLinkToSupportMail: true,
  },
  // Verify Email
  EMAIL_ALREADY_VERIFIED: {
    code: 'EMAIL_ALREADY_VERIFIED',
    message: i18n.t('commons.emailAlreadyVerified'),
  },

  EMAIL_DOMAIN_ALREADY_EXISTS: {
    code: 'EMAIL_DOMAIN_ALREADY_EXISTS',
    message: i18n.t('commons.emailDomainAlreadyExists'),
  },
  INVALID_CREDENTIALS: {
    code: 'INVALID_CREDENTIALS',
    message: i18n.t('commons.invalidCredentials'),
  },
  NON_BUSINESS_DOMAIN: {
    code: 'NON_BUSINESS_DOMAIN',
    message: i18n.t('commons.nonBusinessDomain'),
  },
  USER_ALREADY_EXISTS: {
    code: 'USER_ALREADY_EXISTS',
    message: i18n.t('commons.userAlreadyExists'),
  },
  USER_NOT_FOUND: {
    code: 'USER_NOT_FOUND',
    message: i18n.t('commons.userNotFound'),
  },
};

export default expectedErrors;
