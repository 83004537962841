import { Box, TableRow } from '@mui/material';
import React, { useState } from 'react';

import HoverActions from '../hover-actions-container/HoverActions';

const GenericTableRow = ({
  hoverActions,
  isComponent = false,
  item,
  subTableRow,
  onRowSelected,
  renderRow,
  style = {},
}) => {
  const [hovered, setHovered] = useState(false);
  const handleMouseEnter = () => setHovered(true);
  const handleMouseLeave = () => setHovered(false);

  return (
    <TableRow
      hover
      onClick={() => (onRowSelected ? onRowSelected(item) : null)}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      sx={{ '&:hover': { cursor: onRowSelected ? 'pointer' : 'auto' }, ...style }}
    >
      {subTableRow && subTableRow}
      {isComponent ? renderRow : renderRow(item)}

      {hovered && hoverActions && (
        <Box sx={{ position: 'sticky', right: 0 }}>
          <HoverActions actions={hoverActions} item={item} />
        </Box>
      )}
    </TableRow>
  );
};

export default GenericTableRow;
