import { Box, Drawer, Stack, Typography } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Logo from 'src/components/Logo';
import { NavSectionVertical } from 'src/components/nav-section';
import Scrollbar from 'src/components/Scrollbar';
import { NAVBAR } from 'src/config';
import useCollapseDrawer from 'src/hooks/useCollapseDrawer';
import useLocales from 'src/hooks/useLocales';
import useResponsive from 'src/hooks/useResponsive';
import SocialsButton from 'src/layouts/dashboard/footer/SocialsButton';
import cssStyles from 'src/utils/cssStyles';

import CollapseButton from './CollapseButton';
import NavbarAccount from './NavbarAccount';
import useNavConfig from './useNavConfig';

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    transition: theme.transitions.create('width', {
      duration: theme.transitions.duration.shorter,
    }),
  },
}));

const GradientLayout = styled(Box)(() => ({
  backgroundImage: 'linear-gradient(to bottom, rgba(225,226,241,0), rgba(225,226,241,1))',
  height: '100vh',
  position: 'absolute',
  top: 0,
  width: '100%',
}));

const NavbarVertical = ({ isOpenSidebar, onCloseSidebar }) => {
  const theme = useTheme();
  const navConfig = useNavConfig();
  const { translate } = useLocales();

  const { pathname } = useLocation();

  const isDesktop = useResponsive('up', 'lg');

  const { collapseClick, collapseHover, isCollapse, onHoverEnter, onHoverLeave, onToggleCollapse } =
    useCollapseDrawer();

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <>
      <GradientLayout />
      <Scrollbar
        sx={{
          '& .simplebar-content': {
            display: 'flex',
            flexDirection: 'column',
            height: 1,
          },
          // display: 'flex',
          // flexDirection: 'column',
          height: 1,
        }}
      >
        <Stack
          spacing={3}
          sx={{
            flexShrink: 0,
            pb: 2,
            pt: 3,
            px: 2.5,
            ...(isCollapse && { alignItems: 'center' }),
          }}
        >
          <Stack alignItems="center" direction="row" justifyContent="space-between">
            <Logo logoOnly={isCollapse} />

            {isDesktop && !isCollapse && (
              <CollapseButton collapseClick={collapseClick} onToggleCollapse={onToggleCollapse} />
            )}
          </Stack>

          <NavbarAccount isCollapse={isCollapse} />
        </Stack>

        <NavSectionVertical isCollapse={isCollapse} navConfig={navConfig} />

        <Box sx={{ flexGrow: 1 }} />

        {!isCollapse && (
          <Stack spacing={1} sx={{ mb: 2 }}>
            <SocialsButton />
            <Typography align="center" color="text.secondary" noWrap variant="caption">
              {translate('layouts.dashboardNavbarVerticalMadeWithLove')} <strong>Unlimitd</strong>
            </Typography>
          </Stack>
        )}
      </Scrollbar>
    </>
  );

  return (
    <RootStyle
      sx={{
        width: {
          lg: isCollapse ? NAVBAR.DASHBOARD_COLLAPSE_WIDTH : NAVBAR.DASHBOARD_WIDTH,
        },
        ...(collapseClick && {
          position: 'absolute',
        }),
      }}
    >
      {!isDesktop && (
        <Drawer
          onClose={onCloseSidebar}
          open={isOpenSidebar}
          PaperProps={{ sx: { width: NAVBAR.DASHBOARD_WIDTH } }}
        >
          {renderContent}
        </Drawer>
      )}

      {isDesktop && (
        <Drawer
          onMouseEnter={onHoverEnter}
          onMouseLeave={onHoverLeave}
          open
          PaperProps={{
            sx: {
              bgcolor: 'background.default',
              borderRight: 'none',
              transition: (th) =>
                theme.transitions.create('width', {
                  duration: th.transitions.duration.standard,
                }),
              width: NAVBAR.DASHBOARD_WIDTH,
              ...(isCollapse && {
                width: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
              }),
              ...(collapseHover && {
                ...cssStyles(theme).bgBlur(),
                boxShadow: (th) => th.customShadows.z24,
              }),
            },
          }}
          variant="persistent"
        >
          {renderContent}
        </Drawer>
      )}
    </RootStyle>
  );
};

export default NavbarVertical;
