import * as Yup from 'yup';

import ContractForm from './ContractForm';

const getContractFormConfiguration = ({ translate }) => {
  const schema = {
    contractFile: Yup.mixed().nullable().required(translate('commons.requiredField')).nullable(),
    contractNumber: Yup.number()
      .nullable()
      .required(translate('commons.requiredField'))
      .transform((value) => (Number.isNaN(value) ? null : value)),
    reference: Yup.string().nullable().required(translate('commons.requiredField')),
  };

  const defaultValues = {
    contractFile: null,
    contractNumber: null,
    reference: null,
  };

  return {
    Component: ContractForm,
    defaultValues,
    label: translate('sections.dashboardAdministrationFinancingCreateFinancingFormStepThree'),
    props: {},
    schema,
  };
};

export default getContractFormConfiguration;
