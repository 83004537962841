import { gql } from '@apollo/client';
import { userFragment } from 'src/graphql/fragments';

const CURRENT_USER = gql`
  {
    me {
      ...User
    }
  }
  ${userFragment}
`;

const GET_HUBSPOT_INFOS = gql`
  query getHubspotInfos($hubspotId: ID!) {
    getHubspotInfos(hubspotId: $hubspotId) {
      user {
        firstname
        email
        lastname
        phone
      }
      company {
        mainProduct
        websiteUrl
      }
    }
  }
`;

export { CURRENT_USER, GET_HUBSPOT_INFOS };
