import { gql } from '@apollo/client';

const companyFragment = gql`
  fragment Company on Company {
    id
    codatCompanyId
    domain
    activity
    businessName
    hubspotId
    countryCode
    creationDate
    mainProduct
    fullAddress
    faviconUrl
    legalForm
    businessArea {
      name
      id
      color
    }
    radiationStatus
    siren
    websiteUrl
    linkedinUrl
    isOnBoardingDone
    comment
    isInDefault
    hasDefaultedAt
    isUnderSurveillance
    startSurveillanceAt
    stopSurveillanceAt
    kybStatus
    createdAt
    updatedAt
    metadata {
      customerId
      externalIban
      setupIntentId
      financingSimulation {
        amount
        duration
      }
      paymentMethodId
      mandateUrl
      tools
      ecommerce
      marketing
      accounting
      subscriptions
    }
  }
`;

export default companyFragment;
