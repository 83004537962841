import { Stack, Typography } from '@mui/material';
import React from 'react';
import Image from 'src/components/Image';
import useLocales from 'src/hooks/useLocales';

import ScreenContainer from '../ScreenContainer';

const Why = ({ withSupportHelper }) => {
  const { translate } = useLocales();

  return (
    <ScreenContainer
      image="/static/onboarding/side-panels/background_2.png"
      withSupportHelper={withSupportHelper}
    >
      <Stack spacing={2}>
        <Image
          src="/icons/glass/hand.png"
          sx={{
            width: 100,
          }}
        />
        <Typography color="grey.600" variant="h4">
          {translate('sections.onboardingSidePanelWhyTitle')}
        </Typography>
        <Typography color="grey.600" variant="subtitle">
          {translate('sections.onboardingSidePanelWhySubtitle')}
        </Typography>
      </Stack>
    </ScreenContainer>
  );
};
export default Why;
