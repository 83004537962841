import { gql } from '@apollo/client';

import financingFragment from '../fragments/financing';

const CREATE_FINANCING = gql`
  mutation createFinancing($input: FinancingInput!) {
    createFinancing(input: $input) {
      ...Financing
    }
  }
  ${financingFragment}
`;

const REMOVE_FINANCING = gql`
  mutation removeFinancing($financingId: ID!) {
    removeFinancing(financingId: $financingId)
  }
`;

export { CREATE_FINANCING, REMOVE_FINANCING };
