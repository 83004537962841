import React, { createContext, useEffect } from 'react';
import { GET_NOTIFICATIONS } from 'src/graphql/notifications/queries';
import { useSafeLazyQuery } from 'src/services/apollo-client/wrappers';

const NotificationsContext = createContext();

const NotificationsProvider = ({ children }) => {
  const [getNotifications, { data, ...getNotificationsState }] =
    useSafeLazyQuery(GET_NOTIFICATIONS);

  useEffect(() => {
    getNotifications();
  }, [getNotifications]);

  return (
    <NotificationsContext.Provider
      value={{
        getNotificationsState,
        notifications: data?.getNotifications,
      }}
    >
      {children}
    </NotificationsContext.Provider>
  );
};

export { NotificationsContext, NotificationsProvider };
