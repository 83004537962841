const variants = (theme) => {
  const isLight = theme.palette.mode === 'light';
  const METABASE_BACKGROUND_COLOR = isLight ? '#FFF' : '#2F353B';

  return [
    {
      props: { variant: 'smallPadding' },
      style: {
        padding: theme.spacing(1),
      },
    },
    {
      props: { variant: 'largePadding' },
      style: {
        padding: theme.spacing(3),
      },
    },
    {
      props: { variant: 'metabase' },
      style: {
        backgroundColor: METABASE_BACKGROUND_COLOR,
        padding: theme.spacing(3),
      },
    },
  ];
};

export default variants;
