import { alpha } from '@mui/material/styles';

const Pagination = (theme) => ({
  MuiPaginationItem: {
    styleOverrides: {
      outlined: {
        border: `1px solid ${theme.palette.grey[500_32]}`,
      },
      outlinedPrimary: {
        '&.Mui-selected': {
          backgroundColor: alpha(theme.palette.primary.main, 0.08),
          border: `1px solid ${alpha(theme.palette.primary.main, 0.24)}`,
        },
      },
      root: {
        '&.Mui-selected': {
          fontWeight: theme.typography.fontWeightBold,
        },
      },
      textPrimary: {
        '&.Mui-selected': {
          '&:hover, &.Mui-focusVisible': {
            backgroundColor: `${alpha(theme.palette.primary.main, 0.24)} !important`,
          },
          backgroundColor: alpha(theme.palette.primary.main, 0.08),
          color: theme.palette.primary.main,
        },
      },
    },
  },
});

export default Pagination;
