import { InputAdornment } from '@mui/material';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import { RHFTextField } from 'src/components/hook-form';
import { CURRENCY_CODES, getCurrencyOptionByCode } from 'src/utils/currency';
import roundDecimals from 'src/utils/roundDecimals';

const RHFMoneyField = ({ allowNegative, currencyCode, name, ...other }) => {
  const { control, setValue } = useFormContext();
  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState: { error } }) => (
        <NumberFormat
          {...field}
          {...other}
          allowNegative={allowNegative}
          customInput={RHFTextField}
          error={!!error || other.error}
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                {getCurrencyOptionByCode(currencyCode)?.symbol}
              </InputAdornment>
            ),
          }}
          isNumericString
          name={name}
          onChange={(e) => {
            const cleanStr = roundDecimals(Number(e.target.value.split(' ').join('')));
            setValue(field.name, cleanStr || null);
          }}
          thousandSeparator={' '}
          type="text"
        />
      )}
    />
  );
};

export default RHFMoneyField;

RHFMoneyField.defaultProps = {
  allowNegative: false,
  currencyCode: CURRENCY_CODES.EUR,
};
