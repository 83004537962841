import { gql } from '@apollo/client';

const codatIntegrationFragment = gql`
  fragment CodatIntegration on CodatIntegration {
    enabled
    key
    logoUrl
    name
    sourceId
    integrationId
    codatSourceType
    isOfflineConnector
    isBeta
    linkedConnectionsCount
    totalConnectionsCount
  }
`;

export default codatIntegrationFragment;
