const Timeline = (theme) => ({
  MuiTimelineConnector: {
    styleOverrides: {
      root: {
        backgroundColor: theme.palette.divider,
      },
    },
  },

  MuiTimelineDot: {
    styleOverrides: {
      root: {
        boxShadow: 'none',
      },
    },
  },
});

export default Timeline;
