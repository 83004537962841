const Tabs = (theme) => ({
  MuiTab: {
    defaultProps: {
      disableRipple: true,
    },
    styleOverrides: {
      labelIcon: {
        '& > *:first-of-type': {
          marginBottom: 0,
          marginRight: theme.spacing(1),
        },
        flexDirection: 'row',
        minHeight: 48,
      },
      root: {
        '&.Mui-selected': {
          color: theme.palette.text.primary,
        },
        '&:not(:last-of-type)': {
          marginRight: theme.spacing(5),
        },
        '@media (min-width: 600px)': {
          minWidth: 48,
        },
        borderTopLeftRadius: theme.shape.borderRadius,
        borderTopRightRadius: theme.shape.borderRadius,
        fontWeight: theme.typography.fontWeightMedium,
        padding: 0,
      },
      textColorInherit: {
        color: theme.palette.text.secondary,
        opacity: 1,
      },
      wrapper: {
        flexDirection: 'row',
        whiteSpace: 'nowrap',
      },
    },
  },
  MuiTabPanel: {
    styleOverrides: {
      root: {
        padding: 0,
      },
    },
  },
  MuiTabScrollButton: {
    styleOverrides: {
      root: {
        borderRadius: '50%',
        width: 48,
      },
    },
  },
});

export default Tabs;
