import moment from 'moment';
import PAY_BACK_PERIOD_UNITS from 'src/constants/payBackPeriodUnits';
import REFUND_TYPES from 'src/constants/refundTypes';
import * as Yup from 'yup';

import RefundForm from './RefundForm';

const getRefundFormConfiguration = ({ hubspotData, translate }) => {
  const schema = {
    durationMonths: Yup.number()
      .nullable(true)
      .transform((value) => (Number.isNaN(value) ? null : value))
      .when(['payBackPeriodUnit', 'refundType'], {
        is: (payBackPeriodUnit, refundType) =>
          payBackPeriodUnit === PAY_BACK_PERIOD_UNITS.MONTHLY.code &&
          refundType === REFUND_TYPES.FIXED.code,
        then: Yup.number().nullable().required(translate('commons.requiredField')),
      }),
    durationWeeks: Yup.number()
      .nullable(true)
      .transform((value) => (Number.isNaN(value) ? null : value))
      .when(['payBackPeriodUnit', 'refundType'], {
        is: (payBackPeriodUnit, refundType) =>
          payBackPeriodUnit === PAY_BACK_PERIOD_UNITS.WEEKLY.code &&
          refundType === REFUND_TYPES.FIXED.code,
        then: Yup.number().nullable().required(translate('commons.requiredField')),
      }),
    firstDirectDebitDate: Yup.date()
      .nullable(true)
      .required(translate('commons.requiredField'))
      .typeError(translate('commons.requiredField'))
      .transform((curr, orig) => (orig === '' ? null : curr))
      .test('firstDirectDebitDate', (value) => moment(value).isValid())
      .when('payBackPeriodUnit', {
        is: (refundTypes) => refundTypes === REFUND_TYPES.VARIABLE.code,
        then: Yup.date().test(
          'firstDirectDebitDate',
          translate(
            'sections.dashboardAdministrationFinancingCreateRefundFormFirstDirectDebitDateError'
          ),
          (value) => moment(value).isoWeekday() === 1
        ),
      }),
    lateInterestRate: Yup.number()
      .nullable(true)
      .required(translate('commons.requiredField'))
      .transform((value) => (Number.isNaN(value) ? null : value)),
    payBackPeriodUnit: Yup.string().nullable().required(translate('commons.requiredField')),
    periodAmountPayback: Yup.number()
      .nullable(true)
      .transform((value) => (Number.isNaN(value) ? null : value))
      .when('refundType', {
        is: (refundType) => refundType === REFUND_TYPES.FIXED.code,
        then: Yup.number().nullable().required(translate('commons.requiredField')),
      }),
    periodVariablePayback: Yup.number()
      .nullable(true)
      .when('refundType', {
        is: (refundType) => refundType === REFUND_TYPES.VARIABLE.code,
        then: Yup.number()
          .nullable()
          .required(translate('commons.requiredField'))
          .transform((value) => (Number.isNaN(value) ? null : value)),
      }),
    refundType: Yup.string().nullable().required(translate('commons.requiredField')),
    teg: Yup.number()
      .nullable(true)
      .when('refundType', {
        is: (refundType) => refundType === REFUND_TYPES.VARIABLE.code,
        then: Yup.number()
          .nullable(true)
          .transform((value) => (Number.isNaN(value) ? null : value)),
      }),
    totalAmountDue: Yup.number()
      .nullable(true)
      .when('refundType', {
        is: (refundType) => refundType === REFUND_TYPES.VARIABLE.code,
        then: Yup.number()
          .nullable()
          .required(translate('commons.requiredField'))
          .transform((value) => (Number.isNaN(value) ? null : value)),
      }),
    turnover: Yup.number()
      .nullable(true)
      .when('refundType', {
        is: (refundType) => refundType === REFUND_TYPES.VARIABLE.code,
        then: Yup.number()
          .nullable()
          .required(translate('commons.requiredField'))
          .transform((value) => (Number.isNaN(value) ? null : value)),
      }),
  };

  const defaultValues = {
    durationMonths: null,
    durationWeeks: null,
    firstDirectDebitDate: null,
    payBackPeriodUnit: null,
    periodAmountPayback: null,
    periodVariablePayback: null,
    refundType: hubspotData.refundType,
    teg: null,
    totalAmountDue: null,
    turnover: null,
  };

  return {
    Component: RefundForm,
    defaultValues,
    label: translate('sections.dashboardAdministrationFinancingCreateFinancingFormStepTwo'),
    props: {},
    schema,
  };
};

export default getRefundFormConfiguration;
