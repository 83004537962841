import { useQuery } from '@apollo/client';

import useQueryErrors from './useQueryErrors';

const useSafeQuery = (query, queryOptions, errorsOptions) => {
  const { error, ...queryResult } = useQuery(query, queryOptions);
  useQueryErrors(error, errorsOptions);

  return { error, ...queryResult };
};

export default useSafeQuery;
