const Autocomplete = (theme) => ({
  MuiAutocomplete: {
    styleOverrides: {
      // cspell-checker: disable-next-line
      listbox: {
        '& .MuiAutocomplete-option': {
          borderRadius: theme.shape.borderRadius,
          margin: theme.spacing(1, 0),
          padding: theme.spacing(1),
        },
        padding: theme.spacing(0, 1),
      },
      paper: {
        boxShadow: theme.customShadows.dropdown,
      },
      root: {
        '& .MuiOutlinedInput-input': {
          padding: '6px 8px !important',
        },
        '& .MuiOutlinedInput-root': {
          padding: '6px 8px !important',
        },
      },
    },
  },
});

export default Autocomplete;
