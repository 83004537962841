import { Box, useTheme } from '@mui/material';
import React from 'react';
import ReactApexChart from 'react-apexcharts';

const ReimbursementPercentageChart = ({ reimbursementPercentage, size = 50 }) => {
  const theme = useTheme();
  const chartColors = [theme.palette.primary.light, theme.palette.primary.main];
  const chartOptions = {
    chart: {
      // cspell-checker: disable-next-line
      sparkline: {
        enabled: true,
      },
    },
    colors: [theme.palette.primary.main],
    fill: {
      gradient: {
        colorStops: [chartColors].map((color) => [
          { color: color[0], offset: 0 },
          { color: color[1], offset: 100 },
        ]),
      },
      type: 'gradient',
    },
    plotOptions: {
      radialBar: {
        dataLabels: {
          name: {
            show: false,
          },
        },
        endAngle: 90,
        hollow: {
          size: '20%',
        },
        startAngle: -90,
        track: {
          background: theme.palette.grey[400],
        },
      },
    },
    series: [reimbursementPercentage],
  };

  return (
    <Box style={{ display: 'inline-block', height: size, width: size }}>
      <ReactApexChart
        height={size}
        options={chartOptions}
        series={chartOptions.series}
        type="radialBar"
      />
    </Box>
  );
};

export default ReimbursementPercentageChart;
