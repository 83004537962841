import { gql } from '@apollo/client';

import fileFragment from './file';
import financingFragment from './financing';

const invoiceFragment = gql`
  fragment Invoice on Invoice {
    id
    financingId
    invoice {
      ...File
    }
    reference
    totalAmount
    financing {
      ...Financing
    }
    foundedAmount
    isCompletePayment
    status
    supplierIban
    hasRealEuroAmount
    supplierEmail
    swift
    dueDate
    createdAt
    updatedAt
    currency
    eurAmount
    ocrAmount
    isBban
  }
  ${fileFragment}
  ${financingFragment}
`;

export default invoiceFragment;
