import i18n from 'i18next';
// cspell-checker: disable-next-line
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import enLocales from './en.json';
import frLocales from './fr.json';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: false,
    defaultNS: 'translations',
    fallbackLng: 'fr',
    interpolation: {
      escapeValue: false,
    },
    lng: localStorage.getItem('i18nextLng') || 'fr',
    ns: ['translations'],
    resources: {
      en: { translations: enLocales },
      fr: { translations: frLocales },
    },
  });

export default i18n;
