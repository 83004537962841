import { gql } from '@apollo/client';

const cohortFragment = gql`
  fragment Cohort on Cohort {
    data
    field
    fieldValue
    colorVariant
  }
`;

export default cohortFragment;
