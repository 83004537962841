import { alpha, darken, lighten } from '@mui/material/styles';

const createGradient = (color1, color2) => `linear-gradient(to bottom, ${color1}, ${color2})`;

const PRIMARY_COLOR = '#6065F2';
const SECONDARY_COLOR = '#85ECEA';

// SETUP COLORS
const PRIMARY = {
  dark: darken(PRIMARY_COLOR, 0.2),
  darker: darken(PRIMARY_COLOR, 0.4),
  light: lighten(PRIMARY_COLOR, 0.2),
  lighter: lighten(PRIMARY_COLOR, 0.5),
  main: PRIMARY_COLOR,
};
const SECONDARY = {
  dark: darken(SECONDARY_COLOR, 0.2),
  darker: darken(SECONDARY_COLOR, 0.4),
  light: lighten(SECONDARY_COLOR, 0.2),
  lighter: lighten(SECONDARY_COLOR, 0.5),
  main: SECONDARY_COLOR,
};
const INFO = {
  dark: '#006C9C',
  darker: '#003768',
  light: '#61F3F3',
  lighter: '#CAFDF5',
  main: '#00B8D9',
};
const SUCCESS = {
  dark: '#118D57',
  darker: '#065E49',
  light: '#77ED8B',
  lighter: '#D3FCD2',
  main: '#22C55E',
};
const WARNING = {
  dark: '#B76E00',
  darker: '#7A4100',
  light: '#FFD666',
  lighter: '#FFF5CC',
  main: '#FFAB00',
};
const ERROR = {
  dark: '#B71D18',
  darker: '#7A0916',
  light: '#FFAC82',
  lighter: '#FFE9D5',
  main: '#FF5630',
};
const COHORT = {
  dark: darken('#3498DB', 0.2),
  darker: darken('#3498DB', 0.4),
  light: lighten('#3498DB', 0.2),
  lighter: lighten('#3498DB', 0.5),
  main: '#3498DB',
};

const GREY = {
  0: '#FFFFFF',
  100: '#F9FAFB',
  200: '#F9FAFD',
  300: '#DFE3E8',
  400: '#D1D6E3',
  500: '#919EAB',
  500_05: alpha('#919EAB', 0.05),
  500_12: alpha('#919EAB', 0.12),
  500_16: alpha('#919EAB', 0.16),
  500_24: alpha('#919EAB', 0.24),
  500_32: alpha('#919EAB', 0.32),
  500_48: alpha('#919EAB', 0.48),
  500_56: alpha('#919EAB', 0.56),
  500_8: alpha('#919EAB', 0.08),
  500_80: alpha('#919EAB', 0.8),
  600: '#858995',
  700: '#6D7078',
  800: '#383E4F',
  850: '#19212a',
  900: '#020C25',
};

const GRADIENTS = {
  error: createGradient(ERROR.light, ERROR.main),
  info: createGradient(INFO.light, INFO.main),
  main: `linear-gradient(to right, ${SECONDARY.main}, ${PRIMARY.main})`,
  mainDisabled: `linear-gradient(to right, ${lighten(SECONDARY_COLOR, 0.7)}, ${lighten(
    PRIMARY_COLOR,
    0.7
  )})`,
  primary: createGradient(PRIMARY.light, PRIMARY.main),
  success: createGradient(SUCCESS.light, SUCCESS.main),
  warning: createGradient(WARNING.light, WARNING.main),
};

const CHART_COLORS = {
  blue: ['#2D99FF', '#83CFFF', '#A5F3FF', '#CCFAFF'],
  green: ['#2CD9C5', '#60F1C8', '#A4F7CC', '#C0F2DC'],
  red: ['#FF6C40', '#FF8F6D', '#FFBD98', '#FFF2D4'],
  violet: ['#826AF9', '#9E86FF', '#D0AEFF', '#F7D2FF'],
  yellow: ['#FFE700', '#FFEF5A', '#FFF7AE', '#FFF3D6'],
};

const COMMON = {
  action: {
    disabled: GREY[500_80],
    disabledBackground: GREY[500_24],
    disabledOpacity: 0.48,
    focus: GREY[500_24],
    hover: GREY[500_8],
    hoverOpacity: 0.08,
    selected: GREY[500_16],
  },
  chart: CHART_COLORS,
  cohort: { ...COHORT, contrastText: '#fff' },
  common: { black: '#000', white: '#fff' },
  divider: GREY[300],
  error: { ...ERROR, contrastText: '#fff' },
  gradients: GRADIENTS,
  grey: GREY,
  info: { ...INFO, contrastText: '#fff' },
  primary: { ...PRIMARY, contrastText: '#fff' },
  secondary: { ...SECONDARY, contrastText: '#fff' },
  success: { ...SUCCESS, contrastText: GREY[800] },
  warning: { ...WARNING, contrastText: GREY[800] },
};

const palette = {
  dark: {
    ...COMMON,
    action: { active: GREY[500], ...COMMON.action },
    // background: { default: '#120729', neutral: GREY[500_16], paper: '#14072e' },
    // background: { default: '#0f0524', neutral: GREY[500_16], paper: '#0f0524' },
    background: { default: GREY[900], neutral: GREY[500_16], paper: GREY[850] },
    mode: 'dark',
    text: { disabled: GREY[600], primary: '#fff', secondary: GREY[500] },
  },
  light: {
    ...COMMON,
    action: { active: GREY[600], ...COMMON.action },
    background: { default: GREY[0], neutral: GREY[500_05], paper: GREY[200] },
    mode: 'light',
    text: { disabled: GREY[500], primary: GREY[800], secondary: GREY[600] },
  },
};

export default palette;
