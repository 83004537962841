import React from 'react';

import { ChartStyle } from './components/charts';
import ErrorBoundary from './components/ErrorBoundary';
import HubspotTracking from './components/hubspot-tracking/HubspotTracking';
import { ProgressBarStyle } from './components/ProgressBar';
import ScrollToTop from './components/ScrollToTop';
import Router from './routes';

const App = () => (
  <ErrorBoundary name="App">
    <HubspotTracking />
    <ProgressBarStyle />
    <ChartStyle />
    <ScrollToTop />
    <Router />
  </ErrorBoundary>
);

export default App;
