import { Box, styled } from '@mui/material';
import React from 'react';
import ScrollingFrieze from 'src/components/ScrollingFrieze';

const MainContainer = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
  minHeight: '100vh',
  overflowY: 'auto',
  position: 'absolute',
  top: 0,
  width: '100%',
});

const Wave = styled(Box)({
  backgroundImage: 'url(/static/onboarding/backgrounds/wave.png)',
  backgroundPosition: 'center top',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  bottom: 0,
  height: '60vh',
  position: 'absolute',
  width: '100%',
  zIndex: -1,
});

const FullScreenImageContainer = ({ children, friezeText }) => (
  <>
    <ScrollingFrieze text={friezeText} />
    <MainContainer>{children}</MainContainer>
    <Wave />
  </>
);

export default FullScreenImageContainer;
