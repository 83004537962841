import { ThemeProvider, createTheme, useTheme } from '@mui/material/styles';
import React from 'react';

import useLocales from '../hooks/useLocales';

const ThemeLocalization = ({ children }) => {
  const defaultTheme = useTheme();
  const { currentLang } = useLocales();

  const theme = createTheme(defaultTheme, currentLang.systemValue);

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default ThemeLocalization;
