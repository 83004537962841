import React from 'react';

import useAuth from '../hooks/useAuth';
import createAvatar from '../utils/createAvatar';
import Avatar from './Avatar';

const MyAvatar = ({ ...other }) => {
  const { company, user } = useAuth();
  const { color, name } = createAvatar(user?.displayName);
  return (
    <Avatar
      alt={user?.displayName}
      color={company?.faviconUrl ? 'default' : color}
      src={company?.faviconUrl}
      {...other}
    >
      {name}
    </Avatar>
  );
};

export default MyAvatar;
