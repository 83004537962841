import './styles.css';

import PropTypes from 'prop-types';
import React from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import config from 'src/config/index';

const RecaptchaProvider = ({ children }) => {
  const { siteKey, withRecaptcha } = config.recaptcha;
  if (!withRecaptcha) return children;

  return <GoogleReCaptchaProvider reCaptchaKey={siteKey}>{children}</GoogleReCaptchaProvider>;
};

RecaptchaProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default RecaptchaProvider;
