const List = (theme) => ({
  MuiListItemAvatar: {
    styleOverrides: {
      root: {
        marginRight: theme.spacing(2),
        minWidth: 'auto',
      },
    },
  },
  MuiListItemIcon: {
    styleOverrides: {
      root: {
        color: 'inherit',
        marginRight: theme.spacing(2),
        minWidth: 'auto',
      },
    },
  },
  MuiListItemText: {
    styleOverrides: {
      multiline: {
        marginBottom: 0,
        marginTop: 0,
      },
      root: {
        marginBottom: 0,
        marginTop: 0,
      },
    },
  },
});

export default List;
