import styled from '@emotion/styled';
import { Box, Skeleton, Stack, Typography } from '@mui/material';
import React from 'react';
import Image from 'src/components/Image';
import { GET_TOTAL_AMOUNT_FUNDED } from 'src/graphql/financing/queries';
import useLocales from 'src/hooks/useLocales';
import { useSafeQuery } from 'src/services/apollo-client/wrappers';
import { NUMBER_FORMATS, fEuro } from 'src/utils/formatNumber';

import ScreenContainer from '../ScreenContainer';

const BottomGradientLayout = styled(Box)(() => ({
  backgroundImage: 'linear-gradient(to bottom, rgba(225,226,241,0) 80%, rgba(225,226,241,1))',
  height: '100%',
  position: 'absolute',
  top: 0,
  width: '100%',
}));

const TotalAmountFunded = () => {
  const { data, loading } = useSafeQuery(GET_TOTAL_AMOUNT_FUNDED, {
    fetchPolicy: 'cache-and-network',
  });

  const { translate } = useLocales();

  return (
    <>
      <ScreenContainer image="/static/onboarding/side-panels/background_5.png">
        <Stack>
          <Image
            src="/icons/glass/message.png"
            sx={{
              width: 100,
            }}
          />
          {!loading ? (
            <>
              <Typography sx={{ color: 'primary.main' }} variant="h1">
                +
                {fEuro({
                  format: NUMBER_FORMATS.SHORTEN_INTEGER,
                  number: data.getTotalAmountFunded,
                  toUppercase: true,
                })}
              </Typography>
              <Typography color="grey.600" variant="h4">
                {translate('sections.onboardingSidePanelTotalAmountFunded')}
              </Typography>
            </>
          ) : (
            <Skeleton height={50} variant="text" width={100} />
          )}
        </Stack>
      </ScreenContainer>
      <BottomGradientLayout />
    </>
  );
};

export default TotalAmountFunded;
