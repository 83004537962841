import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Stack,
  TextField,
  Toolbar,
  Typography,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import React, { useRef } from 'react';
import StyledDialog from 'src/components/dialogs/StyledDialog.js';
import Iconify from 'src/components/Iconify';
// eslint-disable-next-line import/no-cycle
import { MODAL_TYPES } from 'src/contexts/DialogsContext';
import i18n from 'src/locales/i18n';

const GenericDialog = ({
  children,
  confirmLabel,
  dialogType,
  discardLabel,
  maxWidth = 'xs',
  message,
  onConfirm,
  onDiscard,
  open,
  title,
  withoutActions = false,
}) => {
  const promptRef = useRef();

  return (
    <StyledDialog fullWidth maxWidth={maxWidth} open={open}>
      <DialogTitle sx={{ p: 0 }}>
        <Toolbar sx={{ display: 'flex', justifyContent: title ? 'space-between' : 'flex-end' }}>
          {title && (
            <Typography component="p" variant="h6">
              {title}
            </Typography>
          )}
          <IconButton onClick={onDiscard}>
            <Iconify icon="eva:close-fill" />
          </IconButton>
        </Toolbar>
      </DialogTitle>
      <Divider />
      <DialogContent>
        {message && (
          <Stack spacing={2}>
            <Typography color="text.secondary">{message}</Typography>
          </Stack>
        )}
        {dialogType === MODAL_TYPES.PROMPT && (
          <TextField autoFocus fullWidth inputRef={promptRef} />
        )}
        {children}
      </DialogContent>
      <Divider />
      {!withoutActions && (
        <DialogActions>
          {dialogType !== MODAL_TYPES.ALERT && <Button onClick={onDiscard}>{discardLabel}</Button>}
          <Button onClick={() => onConfirm(promptRef.current?.value)} variant="contained">
            {confirmLabel}
          </Button>
        </DialogActions>
      )}
    </StyledDialog>
  );
};

GenericDialog.defaultProps = {
  confirmLabel: i18n.t('components.dialogsPromptDialogConfirmText'),
  discardLabel: i18n.t('components.dialogsPromptDialogDiscardText'),
};

export default GenericDialog;
