import React from 'react';
import { Navigate } from 'react-router';
import USER_ROLES from 'src/constants/userRoles';
import useAuth from 'src/hooks/useAuth';
import { PATH_DASHBOARD } from 'src/routes/paths';
import getSafeArray from 'src/utils/getSafeArray';

const useCurrentRole = () => {
  const { user } = useAuth();
  return user?.role;
};

const RoleBasedGuard = ({ accessibleRoles, children }) => {
  const currentRole = useCurrentRole();

  if (!currentRole) return null;
  if (!getSafeArray(accessibleRoles).includes(currentRole)) {
    if (currentRole === USER_ROLES.COMPANY) {
      return <Navigate replace to={PATH_DASHBOARD.routing} />;
    }
    return <Navigate replace to={PATH_DASHBOARD.administration.companies} />;
  }

  return <>{children}</>;
};

export default RoleBasedGuard;
