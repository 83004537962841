import { Grid, InputAdornment } from '@mui/material';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { RHFMoneyField, RHFSelect, RHFTextField } from 'src/components/hook-form';
import Iconify from 'src/components/Iconify';
import PAY_BACK_PERIOD_UNITS from 'src/constants/payBackPeriodUnits';
import REFUND_TYPES from 'src/constants/refundTypes';
import useLocales from 'src/hooks/useLocales';
import { excludeSelectedOptions, formatOptions } from 'src/utils/selectOptionsFormatter';

const RefundForm = () => {
  const { translate } = useLocales();
  const methods = useFormContext();

  const { watch } = methods;
  const values = watch();

  const { payBackPeriodUnit, refundType } = values;

  const getPayBackPeriodUnitOptions = () => {
    if (refundType === REFUND_TYPES.VARIABLE.code) {
      return formatOptions(
        excludeSelectedOptions(PAY_BACK_PERIOD_UNITS, ['MONTHLY']),
        'label',
        'code'
      );
    }
    return formatOptions(PAY_BACK_PERIOD_UNITS, 'label', 'code');
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <RHFSelect
          fullWidth
          InputLabelProps={{ shrink: true }}
          label={translate(
            'sections.dashboardAdministrationFinancingCreateRefundFormRefundTypeLabel'
          )}
          name="refundType"
          options={formatOptions(excludeSelectedOptions(REFUND_TYPES, ['ALL']), 'label', 'code')}
          required
          type="text"
        />
      </Grid>
      <Grid item xs={4}>
        <RHFSelect
          fullWidth
          InputLabelProps={{ shrink: true }}
          label={translate(
            'sections.dashboardAdministrationFinancingCreateRefundFormPayBackPeriodUnitLabel'
          )}
          name="payBackPeriodUnit"
          options={getPayBackPeriodUnitOptions()}
          required
          type="text"
        />
      </Grid>
      <Grid item xs={4}>
        <RHFTextField
          InputLabelProps={{ shrink: true }}
          label={translate(
            'sections.dashboardAdministrationFinancingCreateRefundFormFirstDirectDebitDateLabel'
          )}
          name="firstDirectDebitDate"
          required
          type="date"
        />
      </Grid>

      <Grid item xs={6}>
        {payBackPeriodUnit === PAY_BACK_PERIOD_UNITS.MONTHLY.code ? (
          <RHFTextField
            InputProps={{
              inputProps: { max: 24, min: 1 },
              startAdornment: (
                <InputAdornment position="start">
                  <Iconify icon="eva:clock-outline" />
                </InputAdornment>
              ),
            }}
            label={translate(
              'sections.dashboardAdministrationFinancingCreateLoanFormDurationLabel'
            )}
            name="durationMonths"
            type="number"
          />
        ) : (
          <RHFTextField
            InputProps={{
              inputProps: { max: 24, min: 1 },
              startAdornment: (
                <InputAdornment position="start">
                  <Iconify icon="eva:clock-outline" />
                </InputAdornment>
              ),
            }}
            label={translate('sections.dashboardAdministrationSimulatorDurationWeeks')}
            name="durationWeeks"
            type="number"
          />
        )}
      </Grid>

      {refundType === REFUND_TYPES.FIXED.code && (
        <Grid item xs={6}>
          <RHFMoneyField
            label={translate(
              'sections.dashboardAdministrationFinancingCreateRefundFormPeriodAmountPayBackLabel'
            )}
            name="periodAmountPayback"
          />
        </Grid>
      )}
      {refundType === REFUND_TYPES.VARIABLE.code && (
        <Grid item xs={6}>
          <RHFMoneyField
            label={translate(
              'sections.dashboardAdministrationFinancingCreateRefundFormTotalAmountDueLabel'
            )}
            name="totalAmountDue"
          />
        </Grid>
      )}
      {refundType === REFUND_TYPES.VARIABLE.code && (
        <>
          <Grid item xs={6}>
            <RHFMoneyField
              label={translate(
                'sections.dashboardAdministrationFinancingCreateRefundFormTurnoverLabel'
              )}
              name="turnover"
            />
          </Grid>
          <Grid item xs={6}>
            <RHFTextField
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">{translate('commons.percent')}</InputAdornment>
                ),
              }}
              label={translate(
                'sections.dashboardAdministrationFinancingCreateRefundFormPeriodVariablePayBackLabel'
              )}
              name="periodVariablePayback"
              type="number"
            />
          </Grid>
        </>
      )}
      <Grid item xs={6}>
        <RHFTextField
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">{translate('commons.percent')}</InputAdornment>
            ),
          }}
          label={translate(
            'sections.dashboardAdministrationFinancingCreateRefundFormLateInterestRateLabel'
          )}
          name="lateInterestRate"
          type="number"
        />
      </Grid>
      {refundType === REFUND_TYPES.FIXED.code && (
        <Grid item xs={6}>
          <RHFTextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">{translate('commons.percent')}</InputAdornment>
              ),
            }}
            label={translate('sections.dashboardAdministrationFinancingCreateRefundFormTEGLabel')}
            name="teg"
            type="number"
          />
        </Grid>
      )}
    </Grid>
  );
};

export default RefundForm;
