const KYB_STATUSES = {
  PENDING: 'PENDING',
  REVIEW: 'REVIEW',
  VALIDATED: 'VALIDATED',
};

const isKYBPending = (kybStatus) => kybStatus === KYB_STATUSES.PENDING;
const isKYBInReview = (kybStatus) => kybStatus === KYB_STATUSES.REVIEW;
const isKYBValidated = (kybStatus) => kybStatus === KYB_STATUSES.VALIDATED;

export { isKYBPending, isKYBInReview, isKYBValidated };
export default KYB_STATUSES;
