import { Box, Button } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router';
import useLocales from 'src/hooks/useLocales';

import Iconify from './Iconify';

const BackIcon = () => <Iconify icon="material-symbols:arrow-back-ios-rounded" size="xs" />;

const GoBackButton = () => {
  const { translate } = useLocales();
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };
  return (
    <Box>
      <Button onClick={handleGoBack} size="small" startIcon={<BackIcon />} variant="default">
        {translate('commons.goBack')}
      </Button>
    </Box>
  );
};

export default GoBackButton;
