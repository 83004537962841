const SECONDARY_NAME = ['A', 'N', 'H', 'L', 'Q', '9', '8'];
const INFO_NAME = ['F', 'G', 'T', 'I', 'J', '1', '2', '3'];
const SUCCESS_NAME = ['K', 'D', 'Y', 'B', 'O', '4', '5'];
const WARNING_NAME = ['P', 'E', 'R', 'S', 'C', 'U', '6', '7'];
const ERROR_NAME = ['V', 'W', 'X', 'M', 'Z'];

const getFirstCharacter = (name) => name && name.charAt(0).toUpperCase();

const getAvatarColor = (name) => {
  const firstCharacter = getFirstCharacter(name);
  if (SECONDARY_NAME.includes(firstCharacter)) return 'secondary';
  if (INFO_NAME.includes(firstCharacter)) return 'info';
  if (SUCCESS_NAME.includes(firstCharacter)) return 'success';
  if (WARNING_NAME.includes(firstCharacter)) return 'warning';
  if (ERROR_NAME.includes(firstCharacter)) return 'error';
  return 'default';
};

const createAvatar = (name) => ({
  color: getAvatarColor(name),
  name: getFirstCharacter(name),
});

export default createAvatar;
