import { FormControl, FormHelperText, InputLabel, MenuItem, TextField } from '@mui/material';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

const RHFSelect = ({ helperText, label, name, options, renderOption, ...other }) => {
  const { control } = useFormContext();

  const finalRenderOption = renderOption || (({ label: optionLabel }) => optionLabel);

  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState: { error } }) => (
        <FormControl variant="standard" {...other}>
          <InputLabel shrink>{label}</InputLabel>
          <TextField
            error={!!error}
            helperText={error?.message}
            select
            SelectProps={{ native: false }}
            {...field}
          >
            {options.map(({ disabled, label: optionLabel, value, ...others }) => (
              <MenuItem key={value} disabled={disabled} value={value}>
                {finalRenderOption({ label: optionLabel, value, ...others })}
              </MenuItem>
            ))}
          </TextField>
          {helperText && <FormHelperText>{helperText}</FormHelperText>}
        </FormControl>
      )}
    />
  );
};

RHFSelect.defaultProps = {
  native: true,
  options: [],
};

export default RHFSelect;
