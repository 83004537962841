import * as Yup from 'yup';

import ReportDefaultEventForm from './ReportDefaultEventForm';

const getReportDefaultEventFormConfiguration = ({ translate }) => {
  const schema = {
    hasDefaultedAt: Yup.date().required(translate(`commons.requiredField`)),
  };

  const defaultValues = {
    hasDefaultedAt: new Date().toLocaleDateString('en-CA'),
  };

  return {
    Component: ReportDefaultEventForm,
    defaultValues,
    props: {},
    schema,
  };
};

export default getReportDefaultEventFormConfiguration;
