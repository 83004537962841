import styled from '@emotion/styled';
import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

const FONT_SIZE = '120px';
const FONT_WEIGHT = 500;
const LETTER_SPACING = '-1.2px';
const LINE_HEIGHT = '128px';
const STROKE_WIDTH = 2;
const BACKGROUND_COLOR = 'white';

const commonTextStyles = `
  font-size: ${FONT_SIZE};
  font-style: normal;
  font-weight: ${FONT_WEIGHT};
  letter-spacing: ${LETTER_SPACING};
  line-height: ${LINE_HEIGHT};
`;

const StyledSVG = styled('svg')`
  left: 0;
  overflow: visible;
  position: absolute;
  top: 0;
  z-index: 0;
`;

const StyledText = styled('text')`
  ${commonTextStyles}
  fill: none;
  stroke: url(#gradient);
  stroke-width: ${STROKE_WIDTH};
`;

const FilledText = styled('text')`
  ${commonTextStyles}
  fill: ${BACKGROUND_COLOR};
`;

const GradientDefs = () => (
  <defs>
    <linearGradient id="gradient" x1="0%" x2="100%" y1="0%" y2="0%">
      <stop offset="0%" style={{ stopColor: '#3DE5E5', stopOpacity: 1 }} />
      <stop offset="100%" style={{ stopColor: '#8C94FF', stopOpacity: 1 }} />
    </linearGradient>
  </defs>
);

const GradientTypography = ({ children }) => (
  <Box display="inline-block" position="relative">
    <Typography
      sx={{
        WebkitBackgroundClip: 'text',
        color: 'transparent',
        fontSize: FONT_SIZE,
        fontStyle: 'normal',
        fontWeight: FONT_WEIGHT,
        letterSpacing: LETTER_SPACING,
        lineHeight: LINE_HEIGHT,
        userSelect: 'none',
        zIndex: 1,
      }}
    >
      {children}
    </Typography>
    <StyledSVG>
      <GradientDefs />
      <StyledText x="0" y="1em">
        {children}
      </StyledText>
      <FilledText x="0" y="1em">
        {children}
      </FilledText>
    </StyledSVG>
  </Box>
);

GradientTypography.propTypes = {
  children: PropTypes.node.isRequired,
};

export default GradientTypography;
