const OFFER_STEPS = {
  ANALYSIS: 'ANALYSIS',
  CONTRACT: 'CONTRACT',
  KYB: 'KYB',
  PRODUCTS: 'PRODUCTS',
  REFUSED: 'REFUSED',
  REQUEST: 'REQUEST',
};

export default OFFER_STEPS;
