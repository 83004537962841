import { gql } from '@apollo/client';

const OfferFragment = gql`
  fragment Offer on Offer {
    id
    companyId
    financingId
    type
    status
    draftAt
    publishedAt
    refusedAt
    acceptedAt
    declinedAt
    expiresAt
    requestedAmount
    requestedDurationMonths
    refundType
    paybackPeriodUnit
    maxAmount
    maxDuration
    requestedIrr
    weeklyTurnover
    amount
    duration
    deferredWeeks
    paybackPercentage
    commission
    amortizationAmount
    interestAmount
    lastAmortizationDate
    firstAmortizationDate
    irr
    theoreticalIrr
    nominalInterestRate
    lateInterestRate
    estimatedPaymentFrequencyInDays
    monthlyMarketplaceInterestRate
    paymentDate
    createdAt
    updatedAt
  }
`;

export default OfferFragment;
