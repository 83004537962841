import { gql } from '@apollo/client';
import {
  bankConnectionFragment,
  businessAreaFragment,
  companyFragment,
  fileFragment,
  integrationsFragment,
  kybReviewFragment,
  offerFragment,
  userFragment,
} from 'src/graphql/fragments';

import codatConnectionFragment from '../fragments/codatConnection';

const MY_COMPANY = gql`
  query myCompany {
    myCompany {
      ...Company
      marketplaceInformations {
        bankInformations {
          swanOnboardingId
          swanUserId
          swanAccountHolderId
          swanAccountId
          status
          isSwanUserVerified
          hasSwanMandatesSigned
          swanAuthUrl
        }
        marketplaceConnectors {
          id
          name
          lastSyncAt
          codatConnectorId
          createdAt
          updatedAt
        }
      }
      codatConnections {
        ...CodatConnection
      }
      integrations {
        ...Integration
      }
      files {
        ...File
      }
      bankConnections {
        ...BankConnection
      }
      financingData {
        minimalistFinancingInformations {
          id
          reference
        }
        financingInProgressNumber
        financingInvoicesModuleNumber
        financingNumber
        hasFinancing
        hasFinancingInProgress
        hasFinancingInvoicesModule
        totalAmountFinanced
        generatedTurnover
      }
      activeOffers {
        ...Offer
      }
      kybReviews {
        ...KYBReview
      }
    }
  }
  ${bankConnectionFragment}
  ${companyFragment}
  ${codatConnectionFragment}
  ${integrationsFragment}
  ${fileFragment}
  ${offerFragment}
  ${kybReviewFragment}
`;

const LIST_COMPANIES = gql`
  query listCompanies(
    $rowsPerPage: Int!
    $page: Int!
    $filters: CompanyFiltersInput
    $orderBy: OrderByInput
  ) {
    listCompanies(rowsPerPage: $rowsPerPage, page: $page, filters: $filters, orderBy: $orderBy) {
      items {
        ...Company
        status
        users {
          ...User
        }
      }
      total
      hasMore
    }
  }
  ${companyFragment}
  ${userFragment}
`;

const GET_COMPANY_BY_ID = gql`
  query getCompany($companyId: ID!) {
    getCompany(companyId: $companyId) {
      ...Company
      marketplaceInformations {
        bankInformations {
          swanOnboardingId
          swanUserId
          swanAccountHolderId
          swanAccountId
          status
          isSwanUserVerified
          hasSwanMandatesSigned
          swanAuthUrl
        }
        marketplaceConnectors {
          id
          name
          lastSyncAt
          codatConnectorId
          createdAt
          updatedAt
        }
      }
      businessArea {
        ...BusinessArea
      }
      users {
        ...User
      }
      codatConnections {
        ...CodatConnection
      }
      integrations {
        ...Integration
      }
      bankConnections {
        ...BankConnection
      }
      files {
        ...File
      }
      financingData {
        minimalistFinancingInformations {
          id
          reference
        }
        financingInProgressNumber
        financingInvoicesModuleNumber
        financingNumber
        hasFinancing
        hasFinancingInProgress
        hasFinancingInvoicesModule
        hasMarketplaceFinancing
        totalAmountFinanced
        generatedTurnover
      }
    }
  }
  ${companyFragment}
  ${userFragment}
  ${fileFragment}
  ${businessAreaFragment}
  ${codatConnectionFragment}
  ${integrationsFragment}
  ${bankConnectionFragment}
`;

const GET_COMPANY_AND_USER_INFORMATIONS_FROM_HUBSPOT = gql`
  query getCompanyAndUserInformationsFromHubspot($hubspotId: ID!) {
    getCompanyAndUserInformationsFromHubspot(hubspotId: $hubspotId) {
      user {
        email
        position
        firstname
        lastname
        phone
      }
      company {
        mainProduct
        businessName
        websiteUrl
        creationDate
      }
    }
  }
`;

const GET_COMPANY_ANALYTICS = gql`
  query getCompanyAnalytics($filters: CompanyFiltersInput) {
    getCompanyAnalytics(filters: $filters) {
      emailStep {
        total
        percentage
      }
      onboardingStep {
        total
        percentage
      }
      analysisStep {
        total
        percentage
      }
      customerStep {
        total
        percentage
      }
    }
  }
`;

const GET_LEGAL_REPRESENTATIVES = gql`
  query getLegalRepresentatives($siren: String!, $recaptchaToken: String) {
    getLegalRepresentatives(siren: $siren, recaptchaToken: $recaptchaToken) {
      firstname
      lastname
      address
      position
      gender
      birthDate
    }
  }
`;

const GET_TOTAL_CUSTOMERS = gql`
  query getTotalCustomers {
    getTotalCustomers
  }
`;

const GET_TOTAL_DEALS = gql`
  query getTotalDeals {
    getTotalDeals
  }
`;

export {
  MY_COMPANY,
  LIST_COMPANIES,
  GET_LEGAL_REPRESENTATIVES,
  GET_COMPANY_BY_ID,
  GET_COMPANY_AND_USER_INFORMATIONS_FROM_HUBSPOT,
  GET_COMPANY_ANALYTICS,
  GET_TOTAL_CUSTOMERS,
  GET_TOTAL_DEALS,
};
