const Avatar = (theme) => ({
  MuiAvatar: {
    styleOverrides: {
      colorDefault: {
        backgroundColor: theme.palette.grey[400],
        color: theme.palette.text.secondary,
      },
    },
  },
  MuiAvatarGroup: {
    styleOverrides: {
      avatar: {
        '&:first-of-type': {
          backgroundColor: theme.palette.primary.lighter,
          color: theme.palette.primary.main,
          fontSize: 14,
        },
        fontSize: 16,
        fontWeight: theme.typography.fontWeightMedium,
      },
    },
  },
});

export default Avatar;
