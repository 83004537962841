import { matchPath } from 'react-router-dom';

const isExternalLink = (path) => path.includes('http');

const getActive = (path, pathname) =>
  path ? !!matchPath({ path: path.split('?')[0] }, pathname) : false;

export { isExternalLink, getActive };

// eslint-disable-next-line import/no-cycle
export { default as NavSectionVertical } from './vertical';
