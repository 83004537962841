// scroll bar
import 'simplebar/src/simplebar.css';
// lightbox
import 'react-image-lightbox/style.css';
// editor
import 'react-quill/dist/quill.snow.css';
// slick-carousel
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
// lazy image
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import 'react-lazy-load-image-component/src/effects/black-and-white.css';

// i18n
import './locales/i18n';
// highlight
import './utils/highlight';

import { ApolloProvider } from '@apollo/client';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import React from 'react';
import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import apolloClient from 'src/services/apollo-client';
import { onUpdate } from 'src/utils/sw';

import App from './App';
import MotionLazyContainer from './components/animate/MotionLazyContainer';
import NotistackProvider from './components/NotistackProvider';
import RecaptchaProvider from './components/recaptcha/RecaptchaProvider';
import RtlLayout from './components/RtlLayout';
import ThemeColorPresets from './components/ThemeColorPresets';
import ThemeLocalization from './components/ThemeLocalization';
import { CollapseDrawerProvider } from './contexts/CollapseDrawerContext';
import { DialogsProvider } from './contexts/DialogsContext';
import { AuthProvider } from './contexts/JWTContext';
import { SettingsProvider } from './contexts/SettingsContext';
import reportWebVitals from './reportWebVitals';
import serviceWorkerRegistration from './serviceWorkerRegistration';
import ThemeProvider from './theme';

ReactDOM.render(
  <HelmetProvider>
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <SettingsProvider>
        <CollapseDrawerProvider>
          <ApolloProvider client={apolloClient}>
            <BrowserRouter>
              <ThemeProvider>
                <ThemeColorPresets>
                  <ThemeLocalization>
                    <RtlLayout>
                      <NotistackProvider>
                        <MotionLazyContainer>
                          <DialogsProvider>
                            <AuthProvider>
                              <RecaptchaProvider>
                                <App />
                              </RecaptchaProvider>
                            </AuthProvider>
                          </DialogsProvider>
                        </MotionLazyContainer>
                      </NotistackProvider>
                    </RtlLayout>
                  </ThemeLocalization>
                </ThemeColorPresets>
              </ThemeProvider>
            </BrowserRouter>
          </ApolloProvider>
        </CollapseDrawerProvider>
      </SettingsProvider>
    </LocalizationProvider>
  </HelmetProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register({
  onUpdate,
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
