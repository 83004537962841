const GTM_EVENTS = {
  COMPANY_FORM_SUBMITTED: 'COMPANY_FORM_SUBMITTED',
  CONTACT_FORM_SUBMITTED: 'CONTACT_FORM_SUBMITTED',
  ELIGIBILITY_KNOWN: 'ELIGIBILITY_KNOWN',
  OFFER_FORM_SUBMITTED: 'OFFER_FORM_SUBMITTED',
  SIMULATION_FORM_SUBMITTED: 'SIMULATION_FORM_SUBMITTED',
  TOOLS_FORM_SUBMITTED: 'TOOLS_FORM_SUBMITTED',
  // Onboarding part II
  // eslint-disable-next-line sort-keys-fix/sort-keys-fix
  EMAIL_VERIFIED: 'EMAIL_VERIFIED',
  // eslint-disable-next-line sort-keys-fix/sort-keys-fix
  ONBOARDING_COMPLETED: 'ONBOARDING_COMPLETED',
  // eslint-disable-next-line sort-keys-fix/sort-keys-fix
  UNLIMITD_MAKES_PROPOSAL: 'UNLIMITD_MAKES_PROPOSAL',
  // eslint-disable-next-line sort-keys-fix/sort-keys-fix
  DEAL_CLOSED_WON: 'DEAL_CLOSED_WON',
};

const GTM_EVENTS_INFORMATION = {
  [GTM_EVENTS.COMPANY_FORM_SUBMITTED]: {
    description: 'User has submitted the company form',
    event: 'company-form-submitted',
  },
  [GTM_EVENTS.CONTACT_FORM_SUBMITTED]: {
    description: 'User has submitted the contact form',
    event: 'contact-form-submitted',
  },
  [GTM_EVENTS.TOOLS_FORM_SUBMITTED]: {
    description: 'User has submitted the tools form',
    event: 'tools-form-submitted',
  },
  [GTM_EVENTS.OFFER_FORM_SUBMITTED]: {
    description: 'User has submitted the offer form',
    event: 'offer-form-submitted',
  },
  [GTM_EVENTS.SIMULATION_FORM_SUBMITTED]: {
    description: 'User has submitted the simulation form',
    event: 'simulation-form-submitted',
  },
  [GTM_EVENTS.ELIGIBILITY_KNOWN]: {
    description: "User's eligibility is known",
    event: 'eligibility-known',
  },
  // Onboarding part II
  [GTM_EVENTS.EMAIL_VERIFIED]: {
    description: 'User has verified its email, its lifecycle changes from MQL to SQL',
    event: 'email-verified',
  },
  [GTM_EVENTS.ONBOARDING_COMPLETED]: {
    description: 'User has completed its onboarding',
    event: 'onboarding-completed',
  },
  // This event is not propagated yet
  [GTM_EVENTS.UNLIMITD_MAKES_PROPOSAL]: {
    description: 'Company score enables Unlimitd to make a proposal',
    event: 'unlimitd-makes-proposal',
  },
  [GTM_EVENTS.DEAL_CLOSED_WON]: {
    description: 'The deal was signed by the sales team',
    event: 'deal-closed-won',
  },
};

export { GTM_EVENTS, GTM_EVENTS_INFORMATION };
