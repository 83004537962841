import { gql } from '@apollo/client';

const integrationsFragment = gql`
  fragment Integration on Integration {
    id
    companyId
    sourceType
    source
    csvFile {
      id
      fileName
      displayName
    }
    isValid
    metadata {
      fileId
      connectorName
    }
    createdAt
    updatedAt
  }
`;

export default integrationsFragment;
