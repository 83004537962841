const OFFER_STATUSES = {
  ACCEPTED: 'ACCEPTED',
  ALL: 'ALL',
  CONVERTED: 'CONVERTED',
  DECLINED: 'DECLINED',
  DRAFT: 'DRAFT',
  EXPIRED: 'EXPIRED',
  PUBLISHED: 'PUBLISHED',
  REFUSED: 'REFUSED',
  REQUEST: 'REQUEST',
};

export default OFFER_STATUSES;
