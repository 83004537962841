import variants from './variants';

const Button = (theme) => ({
  MuiButton: {
    defaultProps: {
      disableElevation: true,
      disableRipple: true,
    },
    styleOverrides: {
      containedError: {
        boxShadow: 'none',
      },

      containedInfo: {
        boxShadow: 'none',
      },

      // contained
      containedInherit: {
        '&:hover': {
          backgroundColor: theme.palette.grey[400],
        },
        boxShadow: 'none',
        color: theme.palette.grey[800],
      },
      containedPrimary: {
        boxShadow: 'none',
      },
      containedSecondary: {
        boxShadow: 'none',
      },
      containedSuccess: {
        boxShadow: 'none',
      },
      containedWarning: {
        boxShadow: 'none',
      },
      // outlined
      outlinedInherit: {
        '&:hover': {
          backgroundColor: theme.palette.action.hover,
        },
        border: `1px solid ${theme.palette.grey[500_32]}`,
      },

      root: {
        '&:hover': {
          boxShadow: 'none',
        },
        borderRadius: theme.shape.borderRadius,
      },

      sizeLarge: {
        height: 48,
      },
      textInherit: {
        '&:hover': {
          backgroundColor: theme.palette.action.hover,
        },
      },
    },
    variants: variants(theme),
  },
});

export default Button;
