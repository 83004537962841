import { Skeleton, TableCell, TableRow } from '@mui/material';
import React from 'react';

const TableSkeleton = ({ height, ...other }) => (
  <TableRow {...other}>
    <TableCell colSpan="100%" sx={{ p: `0 !important` }}>
      <Skeleton
        height={height}
        sx={{
          borderRadius: 0,
          transform: 'scale(1, 0.7)',
        }}
      />
    </TableCell>
  </TableRow>
);

TableSkeleton.defaultProps = {
  height: 60,
};

export default TableSkeleton;
