import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import WithRoles from 'src/components/WithRoles';
import { HEADER, NAVBAR } from 'src/config';
import USER_ROLES from 'src/constants/userRoles';
import { NotificationsProvider } from 'src/contexts/notifications/NotificationsContext';
import useCollapseDrawer from 'src/hooks/useCollapseDrawer';
import Footer from 'src/layouts/dashboard/footer/Footer';
import HubspotChat from 'src/services/hubspot/HubspotChat';

import DashboardHeader from './header';
import NavbarVertical from './navbar/NavbarVertical';

const MainStyle = styled('main', {
  shouldForwardProp: (prop) => prop !== 'collapseClick',
})(({ collapseClick, theme }) => ({
  flexGrow: 1,
  paddingBottom: HEADER.MOBILE_HEIGHT,
  paddingLeft: 16,
  paddingRight: 16,
  paddingTop: HEADER.MOBILE_HEIGHT + 24,
  [theme.breakpoints.up('lg')]: {
    paddingBottom: HEADER.DASHBOARD_DESKTOP_HEIGHT,
    paddingLeft: 32,
    paddingRight: 32,
    paddingTop: HEADER.DASHBOARD_DESKTOP_HEIGHT + 16,
    transition: theme.transitions.create('margin-left', {
      duration: theme.transitions.duration.shorter,
    }),
    width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH}px)`,
    ...(collapseClick && {
      marginLeft: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
    }),
  },
}));

const Wave = styled(Box)({
  backgroundImage: 'url(/static/onboarding/backgrounds/wave.png)',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  bottom: 0,
  height: '60vh',
  position: 'fixed',
  width: '100%',
  zIndex: -1,
});

const DashboardLayout = ({ hideSidebarAndFooter }) => {
  const { collapseClick, isCollapse } = useCollapseDrawer();
  const [open, setOpen] = useState(false);

  return (
    <NotificationsProvider>
      <Box
        sx={{
          display: { lg: 'flex' },
          minHeight: { lg: 1, md: '100%' },
        }}
      >
        <DashboardHeader
          hideSidebarAndFooter={hideSidebarAndFooter}
          isCollapse={isCollapse}
          onOpenSidebar={() => setOpen(true)}
        />
        {!hideSidebarAndFooter && (
          <NavbarVertical isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
        )}
        <MainStyle collapseClick={collapseClick}>
          <Outlet />
        </MainStyle>
        <WithRoles excludeRoleHierarchy roles={USER_ROLES.COMPANY}>
          <HubspotChat />
        </WithRoles>
      </Box>
      <Wave />

      {!hideSidebarAndFooter && <Footer isCollapse={isCollapse} />}
    </NotificationsProvider>
  );
};

DashboardLayout.defaultProps = {
  hideSidebarAndFooter: false,
};

export default DashboardLayout;
