import { useEffect, useState } from 'react';

const SafeQueryResults = ({ LoaderComponent, children, hasResults, page, showLoaderTimeout }) => {
  const [showLoader, setShowLoader] = useState(true);

  useEffect(() => {
    setShowLoader(true);
    const timer = setTimeout(() => {
      setShowLoader(false);
    }, showLoaderTimeout);
    return () => clearTimeout(timer);
  }, [showLoaderTimeout, page, hasResults]);
  return showLoader || !hasResults ? LoaderComponent : children();
};

export default SafeQueryResults;

SafeQueryResults.defaultProps = {
  LoaderComponent: null,
  showLoaderTimeout: 500,
};
