import React from 'react';
import { Navigate } from 'react-router-dom';

import useAuth from '../hooks/useAuth';
import { PATH_DASHBOARD } from '../routes/paths';

const GuestGuard = ({ children }) => {
  const { isAuthenticated } = useAuth();

  if (isAuthenticated) {
    return <Navigate replace to={PATH_DASHBOARD.routing} />;
  }

  return <>{children}</>;
};

export default GuestGuard;
