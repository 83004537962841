import {
  Box,
  Container,
  FormControlLabel,
  FormHelperText,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import { alpha } from '@mui/material/styles';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

const Item = ({ field, option }) => {
  const { disabled, icon, label, value } = option;
  return (
    <Paper
      sx={{
        alignItems: 'center',
        boxShadow: (theme) => theme.customShadows.z8,
        display: 'flex',
        flexDirection: 'column',
        height: 195,
        justifyContent: 'center',
        px: 2,
        textAlign: 'center',
        width: '100%',
        ...(disabled && { background: (theme) => alpha(theme.palette.action.disabled, 0.25) }),
        ...(field?.value === value && {
          border: (theme) => `solid 3px ${theme.palette.primary.main}`,
        }),
      }}
    >
      <Box component="img" src={icon} sx={{ height: 80, mb: 2, width: 80 }} />
      <Typography variant="subtitle2">{label}</Typography>
    </Paper>
  );
};

const CustomRadio = ({ field, option, ...rest }) => (
  <Radio
    checkedIcon={<Item field={field} option={option} />}
    color="default"
    disableRipple
    icon={<Item field={field} option={option} />}
    sx={{
      '&:hover': {
        bgcolor: 'transparent',
      },
      width: '100%',
    }}
    {...rest}
  />
);

const RHFImageRadioGroup = ({ name, options, ...other }) => {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState: { error } }) => (
        <Container>
          <Grid container justifyContent="center">
            <RadioGroup {...field} row {...other}>
              {options.map((option) => (
                <Grid key={option.value} item xs={6}>
                  <FormControlLabel
                    control={<CustomRadio field={field} option={option} />}
                    disabled={option.disabled}
                    label=""
                    sx={{ m: 0, width: '100%' }}
                    value={option.value}
                  />
                </Grid>
              ))}
            </RadioGroup>
          </Grid>
          {!!error && (
            <FormHelperText error sx={{ px: 2 }}>
              {error.message}
            </FormHelperText>
          )}
        </Container>
      )}
    />
  );
};

export default RHFImageRadioGroup;
