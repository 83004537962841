import { Typography } from '@mui/material';
import numeral from 'numeral';
import React from 'react';

import { CURRENCY_CODES, getCurrencyOptionByCode } from './currency';

const NUMBER_FORMATS = {
  AVERAGE: '0.0%',
  DATA: '0.0 b',
  DEFAULT: '0.00',
  INTEGER: '0',
  PERCENT: '0.00%',
  PERCENT_SHORTEN: '0%',
  SHORTEN: '0.00a',
  SHORTEN_INTEGER: '0aM',
};

const getSeparatedNumber = (number, separator = ' ') =>
  number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, separator);

const fEuro = ({
  number,
  format = NUMBER_FORMATS.DEFAULT,
  currencyCode = CURRENCY_CODES.EUR,
  withoutTypography = false,
  toUppercase = false,
}) => {
  const currencySymbol = getCurrencyOptionByCode(currencyCode)?.symbol;
  const isBefore = getCurrencyOptionByCode(currencyCode)?.before;
  if (!number) return isBefore ? `${currencySymbol} -.--` : `-.-- ${currencySymbol}`;
  const formattedNumber = numeral(number).format(format);
  if (toUppercase)
    return isBefore
      ? `${currencySymbol}${getSeparatedNumber(formattedNumber)}`.toUpperCase()
      : `${getSeparatedNumber(formattedNumber)}${currencySymbol}`.toUpperCase();
  if (withoutTypography)
    return isBefore
      ? `${currencySymbol}${getSeparatedNumber(formattedNumber)}`
      : `${getSeparatedNumber(formattedNumber)}${currencySymbol}`;

  return (
    <Typography variant="money">
      {isBefore
        ? `${currencySymbol}${getSeparatedNumber(formattedNumber)}`
        : `${getSeparatedNumber(formattedNumber)}${currencySymbol}`}
    </Typography>
  );
};

const fPercent = (number) => numeral(number / 100).format(NUMBER_FORMATS.PERCENT);

const fShortenPercent = (number) => numeral(number / 100).format(NUMBER_FORMATS.AVERAGE);

const fNumber = (number) => numeral(number).format();

const fShortenNumber = (number) =>
  numeral(number).format(NUMBER_FORMATS.SHORTEN).replace('.00', '');

const fData = (number) => numeral(number).format(NUMBER_FORMATS.DATA);

export {
  fEuro,
  fPercent,
  fNumber,
  fShortenNumber,
  fData,
  fShortenPercent,
  NUMBER_FORMATS,
  getSeparatedNumber,
};
