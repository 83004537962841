const useHubSpotChat = () => {
  const getSafeWidget = () => window.HubSpotConversations?.widget || {};

  const open = () => getSafeWidget().open();
  const close = () => getSafeWidget().close();

  return {
    close,
    open,
  };
};

export default useHubSpotChat;
