import { useLocation } from 'react-router-dom';

const usePath = (path) => {
  const { pathname } = useLocation();
  const isRequestedPath = pathname === path.split('?')[0];

  return { isRequestedPath };
};

export default usePath;
