import { Stack, Typography } from '@mui/material';
import React from 'react';

import Actions from './Actions';

const CompanyActions = ({ company, translate }) => (
  <Stack>
    <Typography sx={{ fontWeight: 'bold' }} variant="body1">
      {translate('sections.dashboardAdministrationCompaniesCompaniesActionsTitle')}
    </Typography>
    <Typography
      color="text.secondary"
      sx={{ fontStyle: 'italic', fontWeight: 'bold' }}
      variant="body2"
    >
      {translate('sections.dashboardAdministrationCompaniesCompaniesActionsSubtitle')}
    </Typography>
    <Stack mt={1}>
      <Actions actionContext="company" company={company} />
    </Stack>
  </Stack>
);

export default CompanyActions;
