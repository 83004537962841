import { Stack } from '@mui/material';
import React from 'react';
import Image from 'src/components/Image';

import ScreenContainer from '../ScreenContainer';

const Campaigns = () => (
  <ScreenContainer image="/static/onboarding/side-panels/background_3.png">
    <Stack spacing={2}>
      <Image
        src="/icons/glass/campaign.png"
        sx={{
          width: 100,
        }}
      />
    </Stack>
  </ScreenContainer>
);

export default Campaigns;
