import config from 'src/config/index';

const { baseUrl, id } = config.hubspot;

const getHubspotExternalLink = ({ fieldId, fieldType }) => {
  if (!fieldId || !fieldType) return null;
  return `${baseUrl}/contacts/${id}/${fieldType}/${fieldId}`;
};

// eslint-disable-next-line import/prefer-default-export
export { getHubspotExternalLink };
