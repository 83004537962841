import { useEffect } from 'react';
import useAuth from 'src/hooks/useAuth';
import useSafeLazyQuery from 'src/services/apollo-client/wrappers/useSafeLazyQuery';

import { GET_HUBSPOT_TOKEN } from './query';

const loadWidget = () => {
  window.HubSpotConversations.widget.load();
};

const loadWidgetAsync = () => {
  if (window.HubSpotConversations) {
    loadWidget();
  } else {
    window.hsConversationsOnReady = [loadWidget];
  }
};

const removeWidget = () => {
  window.HubSpotConversations.widget.remove();
};

const closeWidget = () => {
  if (window.HubSpotConversations?.widget) removeWidget();
};

const HubspotChat = () => {
  const { accessToken, user } = useAuth();
  const [getHubspotToken, { data, error }] = useSafeLazyQuery(GET_HUBSPOT_TOKEN, null, {
    silentError: true,
  });
  const hubspotToken = data?.getHubspotToken;
  const email = user?.email;

  // load script at mount
  useEffect(() => {
    if (accessToken) getHubspotToken();
    return () => {
      closeWidget();
    };
  }, [accessToken, getHubspotToken]);

  useEffect(() => {
    if (accessToken) {
      if (hubspotToken && email) {
        window.hsConversationsSettings = {
          identificationEmail: email,
          identificationToken: hubspotToken,
          loadImmediately: false,
        };
        loadWidgetAsync();
      }
    } else {
      loadWidgetAsync();
    }
    // else wait for hubspotToken or email that are still loading
  }, [accessToken, error, hubspotToken, email]);

  return null;
};

export default HubspotChat;
