const Badge = () => ({
  MuiBadge: {
    styleOverrides: {
      dot: {
        borderRadius: '50%',
        height: 10,
        width: 10,
      },
    },
  },
});

export default Badge;
