import React from 'react';
import { RHFTextField } from 'src/components/hook-form';
import useLocales from 'src/hooks/useLocales';

const ReportDefaultEventForm = () => {
  const { translate } = useLocales();

  return (
    <RHFTextField
      InputLabelProps={{ shrink: true }}
      label={translate(
        'sections.dashboardAdministrationCompaniesInfosFormsReportDefaultEventFormHasDefaultedAtFieldLabel'
      )}
      name="hasDefaultedAt"
      required
      type="date"
    />
  );
};

export default ReportDefaultEventForm;
