import cssStyles from 'src/utils/cssStyles';

import variants from './variants';

const Card = (theme) => ({
  MuiCard: {
    styleOverrides: {
      root: {
        border: `1px solid ${theme.palette.grey[300]}`,
        borderRadius: Number(theme.shape.borderRadius),
        boxShadow: 'none',
        position: 'relative',
        zIndex: 0,
        ...cssStyles(theme).bgBlur({
          blur: 10,
          opacity: 0.5,
        }),
      },
    },
    variants: variants(theme),
  },
  MuiCardActions: {
    styleOverrides: {
      root: {
        padding: theme.spacing(3),
      },
    },
  },
  MuiCardContent: {
    styleOverrides: {
      root: {
        padding: theme.spacing(3),
      },
    },
  },
  MuiCardHeader: {
    defaultProps: {
      subheaderTypographyProps: { marginTop: theme.spacing(0.5), variant: 'body2' },
      titleTypographyProps: { variant: 'h6' },
    },
    styleOverrides: {
      root: {
        padding: theme.spacing(3, 3, 0),
      },
    },
  },
});

export default Card;
