import { Skeleton, Stack, Typography } from '@mui/material';
import React from 'react';
import Image from 'src/components/Image';
import { GET_TOTAL_DEALS } from 'src/graphql/companies/queries';
import useLocales from 'src/hooks/useLocales';
import { useSafeQuery } from 'src/services/apollo-client/wrappers';

import ScreenContainer from '../ScreenContainer';

const Deals = ({ withSupportHelper }) => {
  const { translate } = useLocales();

  const { data, loading } = useSafeQuery(GET_TOTAL_DEALS, {
    fetchPolicy: 'cache-and-network',
  });

  return (
    <ScreenContainer
      image="/static/onboarding/side-panels/background_4.png"
      withSupportHelper={withSupportHelper}
    >
      <Stack spacing={2}>
        <Image
          src="/icons/glass/folder.png"
          sx={{
            width: 100,
          }}
        />
        {!loading ? (
          <>
            <Typography sx={{ color: 'primary.main' }} variant="h1">
              {data.getTotalDeals}
            </Typography>
            <Typography variant="h4">
              {translate('sections.onboardingSidePanelTotalQualifiedDeals')}
            </Typography>
          </>
        ) : (
          <Skeleton height={50} variant="text" width={100} />
        )}
      </Stack>
    </ScreenContainer>
  );
};
export default Deals;
