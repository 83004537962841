import { gql } from '@apollo/client';

const financingFragment = gql`
  fragment Financing on Financing {
    id
    hubspotDealId
    reference
    commissionRate
    totalAmountDue
    totalAmountInterests
    totalAmountPaidOut
    periodAmountPayback
    periodVariablePayback
    durationMonths
    payBackPeriodUnit
    isRefunded
    refundType
    paidOutAt
    contractSignedAt
    firstDirectDebitDate
    lastDirectDebitDate
    taeg
    module
    metadata {
      reserveRate
      requestedCashOutRate
    }
    financingDetails {
      refundedPercentage
      nextDirectDebits {
        debitDate
      }
      hasStartedPayback
    }
    useWithdrawals
    lastKnownIrr
    initialEstimatedIrr
    contractNumber
    createdAt
    updatedAt
    lateInterestRate
    nominalInterestRate
  }
`;

export default financingFragment;
