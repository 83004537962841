import { Collapse, IconButton, Stack, Tooltip, styled } from '@mui/material';
import React, { useState } from 'react';
import { checkRole } from 'src/constants/userRoles';
import useAuth from 'src/hooks/useAuth';
import useDialogs from 'src/hooks/useDialogs';
import stopPropagation from 'src/utils/stopPropagation';

import Iconify from '../Iconify';

const RootStyle = styled(Stack)(({ theme }) => ({
  background: theme.palette.grey[300],
  borderRadius: theme.shape.borderRadius,
  flexDirection: 'row',
  position: 'absolute',
  right: theme.spacing(1.5),
  top: theme.spacing(1.5),
  zIndex: 99,
}));

const evaluateCondition = (condition, item) => {
  if (item) {
    if (typeof condition === 'function') {
      return condition(item);
    }
  }
  return condition;
};

const executeAction = (action, item) => {
  if (item) {
    action.action(item);
  } else {
    action.action();
  }
};

const ConditionalActionsRendering = ({ children, isCollapsable, open }) => {
  if (isCollapsable) {
    return (
      <Collapse in={open} orientation="horizontal" timeout={300}>
        {children}
      </Collapse>
    );
  }
  return children;
};

const HoverActions = ({ actions, item }) => {
  const { user } = useAuth();
  const { confirm } = useDialogs();
  const [hovered, setHovered] = useState(false);

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const filteredActions = actions.filter(
    ({ condition, roles }) =>
      evaluateCondition(condition, item) &&
      checkRole({ currentRole: user.role, rolesToCheck: roles })
  );

  const shouldCollapse = filteredActions.length >= 3;

  const handleClickAction = async (action) => {
    if (action.confirmMessage) {
      if (await confirm({ message: action.confirmMessage })) {
        executeAction(action, item);
      }
    } else {
      executeAction(action, item);
    }
  };

  if (!filteredActions.length) return null;

  return (
    <RootStyle>
      <ConditionalActionsRendering isCollapsable={shouldCollapse} open={hovered}>
        <Stack direction="row" spacing={1}>
          {filteredActions.map((action, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Tooltip key={index} title={action.tooltipLabel || ''}>
              <span>
                <IconButton
                  disabled={action.disableClick}
                  onClick={stopPropagation(() => handleClickAction(action))}
                  size="small"
                >
                  {action.icon}
                </IconButton>
              </span>
            </Tooltip>
          ))}
        </Stack>
      </ConditionalActionsRendering>
      {shouldCollapse && (
        <span onMouseEnter={handleMouseEnter}>
          <IconButton onClick={() => setHovered(!hovered)} size="small">
            {!hovered && <Iconify icon="eva:arrow-ios-back-outline" size="s" />}
            {hovered && <Iconify icon="eva:arrow-ios-forward-outline" size="s" />}
          </IconButton>
        </span>
      )}
    </RootStyle>
  );
};

export default HoverActions;
