import i18n from 'src/locales/i18n';

const PAY_BACK_PERIOD_UNITS = {
  MONTHLY: {
    code: 'MONTHLY',
    label: i18n.t('commons.monthly'),
  },
  WEEKLY: {
    code: 'WEEKLY',
    label: i18n.t('commons.weekly'),
  },
};

export default PAY_BACK_PERIOD_UNITS;
