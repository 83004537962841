import React from 'react';

const handleDefault = ({ company, manageDefault }) => manageDefault && company?.isInDefault;
const handleOnboardingDone = ({ company, manageOnBoarding }) =>
  manageOnBoarding && !company?.isOnBoardingDone;

// This component handle logic displaying element to companies
const CompanyGuard = ({ children, company, manageDefault, manageOnBoarding }) => {
  const hiddenConditions = [
    handleDefault({ company, manageDefault }),
    handleOnboardingDone({ company, manageOnBoarding }),
  ];
  const hidden = hiddenConditions.some((condition) => condition);

  if (hidden) return null;
  return <>{children}</>;
};

CompanyGuard.defaultProps = {
  manageDefault: false,
  manageOnBoarding: false,
};

export default CompanyGuard;
