const faqContentPart1 = [
  {
    content:
      "Nos offres de financement sont calculées en fonction d'une évaluation exhaustive des diverses métriques relatives à votre entreprise. Cette évaluation englobe notamment le chiffre d'affaires, la solvabilité, la rentabilité, et le comportement de remboursement sur des engagements financiers précédents. Nous utilisons également des indicateurs clés de performance (KPIs) spécifiques à votre secteur, comme le coût d'acquisition client ou le taux de conversion, pour personnaliser au mieux notre offre. Notre objectif est de vous fournir un financement qui s'aligne à la fois sur les besoins et les capacités de votre entreprise.",
    title: 'Comment sont calculées vos offres de financement ?',
  },
  {
    content:
      "Le remboursement est conçu pour être aussi flexible que possible. Dans la plupart des cas, nous prélevons un pourcentage de votre chiffre d'affaires après une période de différé, avec un horizon de remboursement pouvant aller jusqu'à 1 an. Cela vous permet de rembourser plus lorsque vos affaires sont florissantes et moins lorsqu'elles sont plus calmes. En somme, notre modèle de remboursement est intrinsèquement lié à la santé et à la performance de votre entreprise.",
    title: 'Quelles sont les modalités de remboursement du financement ?',
  },
  {
    content:
      'Avec Unlimitd, le temps entre la demande de financement et le déboursement des fonds est minimal. En général, une fois que vous avez téléversé toutes les informations et documents requis, vous pouvez recevoir votre financement en moins de 48 heures. Notre processus est conçu pour être rapide, efficient, et sans tracas.',
    title: 'En combien de temps recevez-vous votre financement ?',
  },
  {
    content:
      "L'affacturage est une méthode de financement qui vous permet de recevoir des paiements immédiats en vendant vos factures à une tierce partie. Bien que l'affacturage offre également une liquidité rapide, il ne fournit généralement pas la même flexibilité ni les options de remboursement que nos services. Unlimitd offre une gamme plus large de solutions, y compris le financement de stocks, de publicités et de ventes sur les marketplaces, tout en intégrant une plus grande flexibilité dans les modalités de remboursement. Contrairement à l’affacturage, notre financement est davantage aligné sur votre chiffre d'affaires et votre croissance, plutôt que simplement sur vos factures clients.",
    title: 'Quelle différence avec l’affacturage ?',
  },
];

const faqContentPart2 = [
  {
    content:
      "Le processus d'onboarding vise à établir un lien entre vos comptes bancaires, vos outils de gestion d'entreprise et notre plateforme. Ceci permet une évaluation plus précise de vos besoins financiers et une meilleure personnalisation des services que nous vous offrons.",
    title: "Quel est l'objectif de l'onboarding ?",
  },
  {
    content:
      "L'éligibilité pour nos offres de financement est déterminée en analysant une série de paramètres financiers et opérationnels de votre entreprise. Cette évaluation globale nous permet de comprendre la solvabilité, la stabilité et le potentiel de croissance de votre entreprise, afin de vous proposer des solutions de financement adéquates.",
    title: 'Comment est évaluée mon éligibilité pour les offres de financement ?',
  },
  {
    content:
      "L'établissement de connexions bancaires est au cœur de notre plateforme. Ce lien sécurisé nous permet d'accéder à vos transactions et à d'autres métriques financières, indispensables pour vous proposer des solutions de financement adaptées. Toutes les données sont en lecture seule, garantissant qu'aucune transaction ne peut être effectuée de notre côté.",
    title: 'Comment sont gérées les connexions bancaires ?',
  },
  {
    content:
      "Nous utilisons des protocoles de sécurité de pointe pour garantir que vos données sont stockées de manière sécurisée. Seules les personnes autorisées ont accès à ces informations. Si vous souhaitez supprimer vos données, vous pouvez en faire la demande à l'adresse email suivante: business@unlimitd.com.",
    title: "Quelle est l'accessibilité de mes données ?",
  },
];

export { faqContentPart1, faqContentPart2 };
