import React from 'react';
import { Quill } from 'react-quill';

import Iconify from '../Iconify';
import EditorToolbarStyle from './EditorToolbarStyle';

// cspell-checker: disable-next-line
const FONT_FAMILY = ['Arial', 'Tahoma', 'Georgia', 'Impact', 'Verdana'];

const FONT_SIZE = [
  '8px',
  '9px',
  '10px',
  '12px',
  '14px',
  '16px',
  '20px',
  '24px',
  '32px',
  '42px',
  '54px',
  '68px',
  '84px',
  '98px',
];
const HEADINGS = ['Heading 1', 'Heading 2', 'Heading 3', 'Heading 4', 'Heading 5', 'Heading 6'];

const undoChange = () => {
  this.quill.history.undo();
};
const redoChange = () => {
  this.quill.history.redo();
};

// cspell-checker: disable-next-line
const Size = Quill.import('attributors/style/size');
Size.whitelist = FONT_SIZE;
Quill.register(Size, true);

// cspell-checker: disable-next-line
const Font = Quill.import('attributors/style/font');
Font.whitelist = FONT_FAMILY;
Quill.register(Font, true);

const formats = [
  'align',
  'background',
  'blockquote',
  'bold',
  'bullet',
  'code',
  'code-block',
  'color',
  'direction',
  'font',
  'formula',
  'header',
  'image',
  'indent',
  'italic',
  'link',
  'list',
  'script',
  'size',
  'strike',
  'table',
  'underline',
  'video',
];

const EditorToolbar = ({ id, isSimple, ...other }) => (
  <EditorToolbarStyle {...other}>
    <div id={id}>
      <div className="ql-formats">
        {!isSimple && (
          <select className="ql-font" defaultValue="">
            <option value="">Font</option>
            {FONT_FAMILY.map((font) => (
              <option key={font} value={font}>
                {font}
              </option>
            ))}
          </select>
        )}

        {!isSimple && (
          <select className="ql-size" defaultValue="16px">
            {FONT_SIZE.map((size) => (
              <option key={size} value={size}>
                {size}
              </option>
            ))}
          </select>
        )}

        <select className="ql-header" defaultValue="">
          {HEADINGS.map((heading, index) => (
            <option key={heading} value={index + 1}>
              {heading}
            </option>
          ))}
          <option value="">Normal</option>
        </select>
      </div>

      <div className="ql-formats">
        <button className="ql-bold" type="button" />
        <button className="ql-italic" type="button" />
        <button className="ql-underline" type="button" />
        <button className="ql-strike" type="button" />
      </div>

      {!isSimple && (
        <div className="ql-formats">
          <select className="ql-color" />
          <select className="ql-background" />
        </div>
      )}

      <div className="ql-formats">
        <button className="ql-list" type="button" value="ordered" />
        <button className="ql-list" type="button" value="bullet" />
        {!isSimple && <button className="ql-indent" type="button" value="-1" />}
        {!isSimple && <button className="ql-indent" type="button" value="+1" />}
      </div>

      {!isSimple && (
        <div className="ql-formats">
          <button className="ql-script" type="button" value="super" />
          <button className="ql-script" type="button" value="sub" />
        </div>
      )}

      {!isSimple && (
        <div className="ql-formats">
          <button className="ql-code-block" type="button" />
          <button className="ql-blockquote" type="button" />
        </div>
      )}

      <div className="ql-formats">
        <button className="ql-direction" type="button" value="rtl" />
        <select className="ql-align" />
      </div>

      <div className="ql-formats">
        <button className="ql-link" type="button" />
        <button className="ql-image" type="button" />
        <button className="ql-video" type="button" />
      </div>

      <div className="ql-formats">
        {!isSimple && <button className="ql-formula" type="button" />}
        <button className="ql-clean" type="button" />
      </div>

      {!isSimple && (
        <div className="ql-formats">
          <button className="ql-undo" type="button">
            <Iconify icon="ic:round-undo" size="s" />
          </button>
          <button className="ql-redo" type="button">
            <Iconify icon="ic:round-redo" size="s" />
          </button>
        </div>
      )}
    </div>
  </EditorToolbarStyle>
);

export { undoChange, redoChange, formats };
export default EditorToolbar;
