import React, { useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import USER_ROLES from 'src/constants/userRoles';
import VerifyAccountPage from 'src/pages/auth-v2/VerifyAccountPage';
import { PATH_AUTH } from 'src/routes/paths';

import LoadingScreen from '../components/LoadingScreen';
import useAuth from '../hooks/useAuth';
import Login from '../pages/auth-v2/LoginPage';

const AuthGuard = ({ children, needsOnboardingDone }) => {
  const { company, isAuthenticated, isInitialized, user } = useAuth();
  const { pathname } = useLocation();
  const [requestedLocation, setRequestedLocation] = useState(null);

  if (!isInitialized) {
    return <LoadingScreen />;
  }

  if (!isAuthenticated) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    return <Login />;
  }

  if (!user.emailVerified) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    return <VerifyAccountPage />;
  }

  if (
    user.emailVerified &&
    user.role === USER_ROLES.COMPANY &&
    needsOnboardingDone &&
    !company?.isOnBoardingDone &&
    pathname !== PATH_AUTH.onboarding
  ) {
    return <Navigate replace to={PATH_AUTH.onboarding} />;
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <>{children}</>;
};

AuthGuard.defaultProps = {
  needsOnboardingDone: true,
};

export default AuthGuard;
