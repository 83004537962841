import config from 'src/config/index';

import { GTM_EVENTS_INFORMATION } from './events';

const { gtm } = config;

class GTMService {
  static emit(GTMEvent, data = {}) {
    // Check if we want to push events
    if (!gtm.pushEvents) return;

    // Check if dataLayer is initialized
    if (!window.dataLayer) return;

    const eventInformation = GTM_EVENTS_INFORMATION[GTMEvent];
    // Check if events is listed
    if (!eventInformation) return;

    window.dataLayer.push({
      // Push the event and the event's description
      ...eventInformation,
      // add associated data
      ...data,
    });
  }

  static withEvent(callback, GTMEvent, data) {
    return (...args) => {
      GTMService.emit(GTMEvent, data);
      callback(...args);
    };
  }
}

export default GTMService;
