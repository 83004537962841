import { Box, Divider, Grid, Skeleton, Stack } from '@mui/material';
import React from 'react';

const CompanyDetailsDrawerSkeleton = () => (
  <Stack>
    <Box p={2}>
      <Stack alignItems="center" direction="row" spacing={1}>
        <Skeleton height={28} variant="circular" width={28} />
        <Skeleton variant="text" width={75} />
      </Stack>
      <Skeleton variant="text" width={60} />
    </Box>
    <Divider />
    <Box p={2}>
      <Grid container>
        <Grid item xs={4}>
          <Skeleton variant="text" width={60} />
        </Grid>
        <Grid item xs={8}>
          <Skeleton variant="text" width={120} />
        </Grid>
        <Grid item xs={4}>
          <Skeleton variant="text" width={45} />
        </Grid>
        <Grid item xs={8}>
          <Skeleton variant="text" width={105} />
        </Grid>
      </Grid>
    </Box>
  </Stack>
);

export default CompanyDetailsDrawerSkeleton;
