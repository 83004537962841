import { gql } from '@apollo/client';

import userFragment from '../fragments/user';

const VERIFIED_USER_EMITTED = gql`
  subscription verifiedUserEmitted {
    verifiedUserEmitted {
      ...User
    }
  }
  ${userFragment}
`;

// eslint-disable-next-line import/prefer-default-export
export { VERIFIED_USER_EMITTED };
