import {
  Divider,
  Pagination,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import React, { useEffect } from 'react';
import EmptyContent from 'src/components/EmptyContent';
import SafeQueryResults from 'src/components/SafeQueryResults.js';
import Scrollbar from 'src/components/Scrollbar';
import TableSkeletons from 'src/components/table/TableSkeletons.js';
import { ROWS_PER_PAGE } from 'src/config';
import useQueryParams from 'src/hooks/useQueryParams';

import GenericTableRow from './GenericTableRow';

const GenericTableList = ({
  currentPage,
  hoverActions,
  isLoading,
  isNestedTable,
  items,
  onChangePage,
  onRowSelected,
  renderAdditionalContent,
  renderHeadRow,
  renderRow,
  totalItems,
}) => {
  const hasResults = !isLoading || items.length > 0;
  const pageCount = Math.ceil(totalItems / ROWS_PER_PAGE);
  const hasPagination = onChangePage && currentPage;
  const { changeParameterValue } = useQueryParams();

  const resetPagination = () => {
    changeParameterValue('page', 1);
  };

  useEffect(() => {
    if (items.length === 0 && !isLoading && !isNestedTable) {
      resetPagination();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasResults, isLoading]);

  return (
    <TableContainer>
      <Scrollbar>
        <Table stickyHeader>
          <TableHead>
            <TableRow sx={{ display: 'contents' }}>{renderHeadRow()}</TableRow>
          </TableHead>
          <TableBody>
            <SafeQueryResults
              hasResults={hasResults}
              LoaderComponent={<TableSkeletons itemsLength={items.length} />}
              page={currentPage}
            >
              {() => (
                <>
                  {!isLoading && (
                    <EmptyContent count={totalItems}>
                      {({ renderContent }) => (
                        <TableRow>
                          <TableCell colSpan="100%">{renderContent()}</TableCell>
                        </TableRow>
                      )}
                    </EmptyContent>
                  )}
                  {items.map((item) => (
                    <>
                      <GenericTableRow
                        key={item.id}
                        hoverActions={hoverActions}
                        item={item}
                        onRowSelected={onRowSelected}
                        renderRow={renderRow}
                      />

                      {renderAdditionalContent && (
                        <TableRow sx={{ display: 'contents' }}>
                          {renderAdditionalContent(item)}
                        </TableRow>
                      )}
                    </>
                  ))}
                </>
              )}
            </SafeQueryResults>
          </TableBody>
        </Table>
      </Scrollbar>

      {hasPagination && (
        <>
          <Divider />
          <Stack direction="row" justifyContent="center" py={2}>
            <Pagination
              count={pageCount}
              onChange={onChangePage}
              page={currentPage}
              shape="rounded"
            />
          </Stack>
        </>
      )}
    </TableContainer>
  );
};

export default GenericTableList;
