import { ListItem, ListItemAvatar, ListItemText, Stack, Tooltip, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router';
import Iconify from 'src/components/Iconify';
import Image from 'src/components/Image';
import Label from 'src/components/Label';
import { PATH_DASHBOARD } from 'src/routes/paths';

const InvalidBankConnectionNotificationItem = ({ invalidBankConnection, onClose, title }) => {
  const navigate = useNavigate();

  return (
    <Tooltip placement="left" title={invalidBankConnection.error.message}>
      <ListItem
        sx={{
          mt: '1px',
          px: 2.5,
          py: 1.5,
        }}
      >
        <ListItemAvatar>
          <Image
            alt="bank logo"
            src={invalidBankConnection.bankLogo}
            sx={{
              borderRadius: 1,
              height: 50,
              width: 50,
            }}
          />
        </ListItemAvatar>
        <ListItemText
          primary={<Typography variant="caption">{title}</Typography>}
          secondary={
            <Stack spacing={2}>
              <Stack alignItems="center" direction="row" spacing={2}>
                <Label color="error" size="m" type="error">
                  <Stack
                    alignItems="center"
                    direction="row"
                    onClick={() => {
                      navigate(PATH_DASHBOARD.users.profile('dataroom'));
                      onClose();
                    }}
                    spacing={1}
                    sx={{ cursor: 'pointer' }}
                  >
                    <Iconify icon="eva:flip-2-outline" />
                    <Typography variant="caption">cliquez pour corriger</Typography>
                  </Stack>
                </Label>
              </Stack>
            </Stack>
          }
        />
      </ListItem>
    </Tooltip>
  );
};

export default InvalidBankConnectionNotificationItem;
