import { Alert, Box, LinearProgress } from '@mui/material';
import { styled } from '@mui/material/styles';
import isString from 'lodash/isString';
import React from 'react';
import { useDropzone } from 'react-dropzone';

import Image from '../Image';
import BlockContent from './BlockContent';
import RejectionFiles from './RejectionFiles';

const DropZoneStyle = styled('div')(({ theme }) => ({
  '&:hover': { cursor: 'pointer', opacity: 0.72 },
  backgroundColor: theme.palette.background.neutral,
  border: `1px dashed ${theme.palette.grey[500_32]}`,
  borderRadius: theme.shape.borderRadius,
  outline: 'none',
  overflow: 'hidden',
  padding: theme.spacing(5, 1),
  position: 'relative',
  transition: theme.transitions.create('padding'),
}));

const UploadSingleFile = ({
  accept,
  dropZoneSx,
  error = false,
  file,
  helperText,
  loading,
  sx,
  withoutIllustration,
  ...other
}) => {
  const { fileRejections, getInputProps, getRootProps, isDragActive, isDragReject } = useDropzone({
    accept,
    maxSize: 15_000_000,
    multiple: false,
    ...other,
  });

  return (
    <Box sx={{ width: '100%', ...sx }}>
      <DropZoneStyle
        {...getRootProps()}
        sx={{
          ...(isDragActive && { opacity: 0.72 }),
          ...((isDragReject || error) && {
            bgcolor: 'error.lighter',
            borderColor: 'error.light',
            color: 'error.main',
          }),
          ...(file && {
            padding: '12% 0',
          }),
          ...dropZoneSx,
        }}
      >
        <input {...getInputProps()} />

        <BlockContent accept={accept} withoutIllustration={withoutIllustration} />

        {file && (
          <Image
            alt="file preview"
            src={isString(file) ? file : file.preview}
            sx={{
              borderRadius: 1,
              height: 'calc(100% - 16px)',
              left: 8,
              position: 'absolute',
              top: 8,
              width: 'calc(100% - 16px)',
            }}
          />
        )}
        {loading && (
          <LinearProgress sx={{ bottom: 0, left: 0, position: 'absolute', width: '100%' }} />
        )}
      </DropZoneStyle>

      {file && (
        <Box mt={2}>
          <Alert icon={false} severity="info">
            {file.name}
          </Alert>
        </Box>
      )}

      {fileRejections.length > 0 && <RejectionFiles fileRejections={fileRejections} />}

      {helperText && helperText}
    </Box>
  );
};

export default UploadSingleFile;
