import moment from 'moment';
import { useCallback, useEffect } from 'react';

import useLocalStorage from './useLocalStorage';

const defaultOptions = {
  period: 5,
  periodUnit: 'minutes',
};

const CHECK_THRESHOLD = 10 * 1000;

const useAntiSpammingAction = (actionName, action, options = defaultOptions) => {
  const [nextAllowedActionDateTime, setNextAllowedActionDateTime] = useLocalStorage(actionName);
  const { period, periodUnit } = options;

  const canPerformActionCheck = useCallback(
    () => !nextAllowedActionDateTime || moment().isAfter(moment(nextAllowedActionDateTime)),
    [nextAllowedActionDateTime]
  );

  useEffect(() => {
    // There is a next allowed date time on mount, but it is outdated
    if (Boolean(nextAllowedActionDateTime) && canPerformActionCheck())
      setNextAllowedActionDateTime(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let timer = null;

    // There is a next allowed date time but cannot perform action yet
    // So, start to check regularly if the action is now allowed
    if (Boolean(nextAllowedActionDateTime) && !canPerformActionCheck()) {
      timer = setInterval(() => {
        if (canPerformActionCheck()) {
          setNextAllowedActionDateTime(null);
          if (timer) clearInterval(timer);
        }
      }, CHECK_THRESHOLD);
    }

    // Clear check on unmount
    return () => {
      if (timer) clearInterval(timer);
    };
  }, [canPerformActionCheck, nextAllowedActionDateTime, setNextAllowedActionDateTime]);

  const doActionWithAntiSpamming = (...args) => {
    try {
      // Set the next allowed action date time in the future
      setNextAllowedActionDateTime(moment().add(period, periodUnit));
      return action(...args);
    } catch {
      // Nothing to do
      return null;
    }
  };

  return [doActionWithAntiSpamming, { canPerformAction: canPerformActionCheck() }];
};

export default useAntiSpammingAction;
