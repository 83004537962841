import { Container, Stack } from '@mui/material';
import React from 'react';
import Scrollbar from 'src/components/Scrollbar';

import NeedSupportHelper from './NeedSupportHelper';

const ScreenContainer = ({ children, image, withSupportHelper = false }) => (
  <Stack
    sx={{
      backgroundImage: `url('${image}')`,
      backgroundPosition: 'center top',
      backgroundSize: 'cover',
      height: '100%',
    }}
  >
    {withSupportHelper && <NeedSupportHelper />}
    <Scrollbar>
      <Container maxWidth={false} sx={{ paddingBottom: 2, paddingTop: withSupportHelper ? 0 : 20 }}>
        {children}
      </Container>
    </Scrollbar>
  </Stack>
);
export default ScreenContainer;
