const Radio = (theme) => ({
  MuiRadio: {
    styleOverrides: {
      root: {
        padding: theme.spacing(1),
        svg: {
          '&[font-size=small]': {
            fontSize: 20,
          },
          fontSize: 24,
        },
      },
    },
  },
});

export default Radio;
