import React, { lazy } from 'react';
import { Navigate } from 'react-router';
import Loadable from 'src/components/Loadable';
import TokenGuard from 'src/guards/TokenGuard';
import { TOKEN_TYPES } from 'src/utils/jwt';

// General
const CompanyMandate = Loadable(lazy(() => import('../../pages/dashboard/companies/Mandate')));

// Main
const NotFound = Loadable(lazy(() => import('../../pages/Page404')));
const BankSyncedCallback = Loadable(lazy(() => import('../../pages/BankSyncedCallback')));
const BankSyncedCallbackSuccess = Loadable(
  lazy(() => import('../../pages/BankSyncedCallbackSuccess'))
);
const BankSyncedCallbackError = Loadable(lazy(() => import('../../pages/BankSyncedCallbackError')));
const CodatCallbackPage = Loadable(lazy(() => import('../../pages/CodatCallbackPage')));
const PassChallengePage = Loadable(lazy(() => import('../../pages/PassChallenge')));
const ExternalCallbackPage = Loadable(lazy(() => import('../../pages/ExternalDialogEndPage.js')));
const ClaimOverpaymentPage = Loadable(lazy(() => import('../../pages/ClaimOverpaymentPage')));

const mainRoutes = [
  {
    element: <NotFound />,
    path: '404',
  },
  {
    element: <BankSyncedCallback />,
    path: 'bank-synced/callback',
  },
  {
    element: <BankSyncedCallbackSuccess />,
    path: 'bank-synced/callback/success',
  },
  {
    element: <BankSyncedCallbackError />,
    path: 'bank-synced/callback/error',
  },
  {
    element: <CodatCallbackPage />,
    path: 'codat/callback',
  },
  {
    element: <ExternalCallbackPage />,
    path: 'external/callback',
  },
  {
    element: (
      <TokenGuard requiredTokenType={TOKEN_TYPES.MANDATE_CREATION}>
        <CompanyMandate />
      </TokenGuard>
    ),
    path: 'mandate-creation',
  },
  {
    element: <PassChallengePage />,
    path: 'challenges',
  },
  {
    element: (
      <TokenGuard requiredTokenType={TOKEN_TYPES.OVERPAYMENT_CLAIM}>
        <ClaimOverpaymentPage />
      </TokenGuard>
    ),
    path: 'overpayment/claim',
  },
  {
    element: <Navigate to="/404" />,
    path: '*',
  },
];

export default mainRoutes;
