import { gql } from '@apollo/client';

const amortizationFragment = gql`
  fragment Amortization on Amortization {
    id
    reference
    scheduledAt
    amount
    interestShare
    capitalShare
    commissionShare
    cumulatedInterestShare
    knownWeeklyTurnover
    status
    amortizedAmount
    amortizedCapital
    amortizedInterest
    amortizedCommission
    isAuto
    amortizedLateInterest
    createdAt
    updatedAt
  }
`;

export default amortizationFragment;
