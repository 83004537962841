import { Box, Link, ListItemText } from '@mui/material';
import React from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import Label from 'src/components/Label';

import Iconify from '../../Iconify';
import { ListItemIconStyle, ListItemStyle, ListItemTextStyle } from './style';
import { isExternalLink } from '..';

const NavItemRoot = ({ active, isCollapse, item, onOpen, open = false }) => {
  const { badgeCount, children, icon, info, path, title } = item;

  const renderContent = (
    <>
      {icon && <ListItemIconStyle>{icon}</ListItemIconStyle>}
      <ListItemTextStyle disableTypography isCollapse={isCollapse} primary={title} />
      {!isCollapse && (
        <>
          {info && info}
          {Boolean(badgeCount) && <Label color="warning">{badgeCount}</Label>}
          {children && <ArrowIcon open={open} />}
        </>
      )}
    </>
  );

  if (children) {
    return (
      <ListItemStyle activeRoot={active} onClick={onOpen}>
        {renderContent}
      </ListItemStyle>
    );
  }

  return isExternalLink(path) ? (
    // cspell-checker: disable-next-line
    <ListItemStyle component={Link} href={path} rel="noopener" target="_blank">
      {renderContent}
    </ListItemStyle>
  ) : (
    <ListItemStyle activeRoot={active} component={RouterLink} to={path}>
      {renderContent}
    </ListItemStyle>
  );
};

const NavItemSub = ({ active = false, item, onOpen, open = false }) => {
  const { badgeCount, children, info, path, title } = item;

  const renderContent = (
    <>
      <DotIcon active={active} />
      <ListItemText disableTypography primary={title} />
      {info && info}
      {Boolean(badgeCount) && <Label color="warning">{badgeCount}</Label>}
      {children && <ArrowIcon open={open} />}
    </>
  );

  if (children) {
    return (
      <ListItemStyle activeSub={active} onClick={onOpen} subItem>
        {renderContent}
      </ListItemStyle>
    );
  }

  return isExternalLink(path) ? (
    // cspell-checker: disable-next-line
    <ListItemStyle component={Link} href={path} rel="noopener" subItem target="_blank">
      {renderContent}
    </ListItemStyle>
  ) : (
    <ListItemStyle activeSub={active} component={RouterLink} subItem to={path}>
      {renderContent}
    </ListItemStyle>
  );
};

const DotIcon = ({ active }) => (
  <ListItemIconStyle>
    <Box
      component="span"
      sx={{
        bgcolor: 'text.disabled',
        borderRadius: '50%',
        height: 4,
        transition: (theme) =>
          theme.transitions.create('transform', {
            duration: theme.transitions.duration.shorter,
          }),
        width: 4,
        ...(active && {
          bgcolor: 'primary.main',
          transform: 'scale(2)',
        }),
      }}
    />
  </ListItemIconStyle>
);

const ArrowIcon = ({ open }) => (
  <Iconify
    icon={open ? 'eva:arrow-ios-downward-fill' : 'eva:arrow-ios-forward-fill'}
    size="xs"
    sx={{ ml: 1 }}
  />
);

export { ArrowIcon, DotIcon, NavItemSub, NavItemRoot };
